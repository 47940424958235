import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { isLoggedIn } from "../utils/helperFunctions";
import ResumeBody from "../components/Resume/ResumeBody";
import AlToolPage from "./AlToolPage";
import ResumeBuilderIntro from "./ResumeBuilderIntro";

const Resume = () => {
  const userIsLoggedIn = isLoggedIn();
  const containerRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);
  // if (!userIsLoggedIn) {
  //   toast.error("Please log in to access the Resume Builder.", {
  //       position: toast.POSITION.BOTTOM_CENTER,
  //     });
  //   return null;
  // }
  const stepWiseComponent = {
    1: <AlToolPage setCurrentStep={setCurrentStep} />,
    2: <ResumeBuilderIntro setCurrentStep={setCurrentStep} />,
    3: <ResumeBody />,
  };

  return (
    <div className="flex flex-col" ref={containerRef}>
      {stepWiseComponent[currentStep]}
    </div>
  );
};

export default Resume;
