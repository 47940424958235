import React from "react";
import data from "../../static/Icons/data.png";
import teacher from "../../static/Icons/teacher.png";
import community from "../../static/Icons/community.png";
import clown from "../../static/Icons/clown.png";
//import rightVerticalLines from "../../static/Icons/rightVerticalLines.png";
import rightVerticalLines from "../../static/BlueVerticalLines.png";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import plane from "../../static/Images/plane.png";
import { Link } from "react-router-dom";
import { InView, useInView } from "react-intersection-observer";
import { Grow } from "@mui/material";

const WhyHireSection = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  return (
    <ResponsiveWrapper className={"relative"}>
      <div className="mt-20">
        <p className="text-center text-4xl font-semibold mb-16 z-10 xl:text-3xl">
          Why hire from Maangler?
        </p>
        <div
          className="flex justify-center z-10 md:grid md:grid-rows-2 md:grid-cols-2 md:mx-auto md:gap-4 sm:grid-cols-1 space-x-8 lg:space-x-2 md:space-x-0 relative"
          style={{ zIndex: 30 }}
          ref={ref}
        >
          {Data.map((item, index) => (
            <Grow in={inView} timeout={1500}>
              <div
                key={index}
                className="md:mx-auto"
                style={{ margin: "auto" }}
              >
                <Box
                  icon={item.icon}
                  heading={item.heading}
                  infoText={item.infoText}
                />
              </div>
            </Grow>
          ))}
        </div>
        <div className="flex justify-center items-center mt-8 space-x-4">
          {/* <img
            alt=""
            src={clown}
            className="w-[120px] mb:w-[80px] opacity-40"
          /> */}
          <div className="flex items-center">
            <a
              href="https://calendly.com/ritik-gupta/30min"
              target="_blank"
              rel="noreferrer"
            >
              <button className="border-2 px-16 py-2 border-white rounded-3xl  mb:px-10 transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white">
                Hire Now
              </button>
            </a>
            <img src={rightVerticalLines} alt="" className="w-[50px] mb-14" />
          </div>
        </div>
      </div>
      <img
        src={plane}
        alt=""
        className="absolute top-0 left-[10%] sm:hidden"
        style={{ zIndex: 10 }}
      />
    </ResponsiveWrapper>
  );
};

const Box = ({ icon, heading, infoText }) => (
  <div className="w-[350px] border border-[#9207FF] bg-[#2c3053]  px-6 py-8 h-[270px] rounded-2xl shadow-xl space-y-3 xl:w-[310px] lg:w-[270px] lg:h-[300px] transition ease-in-out duration-500 hover:scale-110 cursor-pointer">
    <div className="h-[100px]">
      <img alt="" src={icon} />
    </div>
    <div>
      <p className="text-lg font-semibold">{heading}</p>
      <p className="">{infoText}</p>
    </div>
  </div>
);

const Data = [
  {
    icon: data,
    heading: "Access Curated Candidates",
    infoText:
      "A high quality, ready-to-interview candidate pool, handpicked by us.",
  },
  {
    icon: teacher,
    heading: "Hire within 2 weeks",
    infoText:
      "Candidates respond in under 72 hours. Set up interviews in days, hire within 2 weeks.",
  },
  {
    icon: community,
    heading: "Pay only if you Hire",
    infoText:
      "We charge a fair placement fee, with a 2 month candidate-replacement guarantee!",
  },
];

export default WhyHireSection;
