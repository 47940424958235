import React from "react";
import { Slide } from "@mui/material";
import Hero from "../../static/CollegeReachOut/Hero2.png";
import { Link } from "react-router-dom";
import heronamebg from "../../static/Images/heronamebg.png";
import heroCardBg from "../../static/Images/heroCardBg.png";

const HeroSection = () => {
  return (
    <div className="bg-[#081b21] flex justify-between py-20 items-center relative sm:flex-col sm:space-y-4 overflow-hidden text-white px-[8%] xl:px-[3%] lg:px-[2%] md:px-[1%]">
      <div className="space-y-10 mb-6 sm:flex sm:flex-col sm:items-center">
        <p className="text-4xl font-bold lg:text-3xl mb:text-2xl leading-[50px]">
          Equip your
          <span
            className="px-2"
            style={{
              backgroundImage: `url(${heronamebg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          >
            Engineering
          </span>
          <br /> students with industry- <br /> relevant skills
        </p>
        <p className=" mb:text-center">
          The first of its kind, Maangler is world's leading e-learning <br />
          platform revolutionizing engineering education
        </p>
        <Link to={""} className="">
          <button className="bg-primary text-white font-semibold px-14 py-3 rounded-3xl hover:bg-[#0288b0] mt-10 hover:shadow-primary hover:shadow">
            Get Started
          </button>
        </Link>
      </div>

      <div className="flex">
        <div className="z-10 relative">
          <img src={Hero} alt="" />
        </div>

        <div className=" right-[2%] top-20 sm:hidden xl:right-[-2%] md:hidden">
          <img src={heroCardBg} alt="" />
        </div>
      </div>
      {/* <div className="absolute left-[40%]">
          <img src={heroBg} alt="" />
        </div> */}
    </div>
  );
};

export default HeroSection;
