import React from "react";
import heronamebg from "../../static/Images/heronamebg.png";
import people from "../../static/Icons/people.png";
import threeLines from "../../static/Icons/threeLines.png";
import heroCardBg from "../../static/Images/heroCardBg.png";
import { Slide } from "@mui/material";
import { Link } from "react-router-dom";
import AddAmbassadorCard from "./AddAmbassadorCard";

const HeroSection = () => {
  return (
    <div className="flex justify-between py-20 items-center relative bg-[#081b21] sm:flex-col sm:space-y-4 overflow-hidden text-white px-[8%] xl:px-[3%] lg:px-[2%] md:px-[1%]">
      <div className="space-y-10 mb-6 sm:flex sm:flex-col sm:items-center">
        <p className="text-4xl font-bold lg:text-3xl mb:text-2xl">
          <span
            className="px-2 py-2 "
            style={{
              backgroundImage: `url(${heronamebg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            Campus Ambassador
          </span>
          <br className="" /> <span className="ml-3"> Program</span>
        </p>
        <p className="ml-2 mb:text-center">
          Enable your friends to achieve their professional{" "}
          <br className="mb:hidden" /> goals and find your true potential by
          learning new <br className="mb:hidden" /> skills and networking.
        </p>
        {/* <Link to={"/super-30"} className=""> */}
          <button className="ml-2 border-2 border-primary text-primary font-semibold px-14 py-3 rounded-3xl hover:bg-primary hover:text-white mt-10 hover:shadow-primary hover:shadow">
            Scroll to Learn More
          </button>
        {/* </Link> */}
      </div>

      <div className="z-10 relative">
        <AddAmbassadorCard
          people={people}
          threeLines={threeLines}
          type="noCross"
        />
      </div>

      <div className="absolute right-[2%] top-20 sm:hidden xl:right-[-2%] md:hidden">
        <img src={heroCardBg} alt="" />
      </div>
      {/* <div className="absolute left-[40%]">
          <img src={heroBg} alt="" />
        </div> */}
    </div>
  );
};

export default HeroSection;
