import React from "react";
import HeroSection from "../components/HomePage/HeroSection";
import AllProgramsStrip from "../components/HomePage/AllProgramsStrip";
import MentorsStrip from "../components/HomePage/MentorsStrip";
import SkillsInfoSection from "../components/HomePage/SkillsInfoSection";
import Super30 from "../components/HomePage/Super30";
import Testimonials from "../components/HomePage/Testimonials";
import MentorsSections from "../components/HomePage/MentorsSections";
import ProgramsWeOffer from "../components/Super30/ProgramsWeOffer";
import Recognition from "../components/HomePage/Recognised";

export const HomePage = () => {
  return (
    <div className="bg-[#fcfcfc]">
      <HeroSection />
      <AllProgramsStrip />
      <MentorsStrip />
      <Recognition/> 
      <SkillsInfoSection />
      <Super30 />
      <ProgramsWeOffer />
      <MentorsSections />
      <Testimonials />
    </div>
  );
};
