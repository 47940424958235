import React, { useState } from "react";
import {
  CloseOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MenuOpen,
} from "@mui/icons-material";
import { Button, Drawer, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/profile/profileSlice";
import { isLoggedIn } from "../utils/helperFunctions";
import Profile from "./Profile";

const MobileSideBar = ({
  openDrawer,
  setOpenDrawer,
  navLinks,
  setOpenProfileDrawer,
}) => {
  const data = useSelector(selectUserData);
  const profileImage = data?.account?.profileImage;
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div
      className="hidden md:block"
      onClick={() => setOpenDrawer((prev) => !prev)}
    >
      {/* <MenuIcon /> */}
      <div>
        <div className="m-[5px] w-[25px] h-[2px] rounded-[2px] bg-black"></div>
        <div className="m-[5px] w-[25px] h-[2px] rounded-[2px] bg-black"></div>
        <div className="m-[5px] w-[25px] h-[2px] rounded-[2px] bg-black"></div>
      </div>

      <div className="hidden md:block">
        <Drawer
          open={openDrawer}
          anchor="right"
          onClose={() => setOpenDrawer(false)}
          sx={{ zIndex: 99999 }}
        >
          <div className="flex flex-col items-start w-[280px] px-2 space-y-0 justify-center min-h-[50vh]">
            <div className="flex justify-between w-full items-center">
              {/* <p className="px-2 text-xl font-semibold border-b  w-full pb-1 pt-1">
                Maangler
              </p> */}
              <div className="cursor-pointer absolute top-2 right-3">
                <CloseOutlined
                  sx={{ width: "40px", height: "40px", color: "#32A3C5" }}
                />
              </div>
            </div>
            {isLoggedIn() && (
              <div
                className="ml-2 flex items-center space-x-2"
                onClick={() => setOpenProfileDrawer((prev) => !prev)}
              >
                <Profile />
                <p>{data?.account?.name}</p>
              </div>
            )}
            {/* {navLinks.map((item, index) => (
              <Link to={item.link} key={index}>
                <button className="text-gray-600 hover:bg-gray-100 px-3 py-1 rounded-2xl">
                  {item.title}
                </button>
              </Link>
            ))} */}
            {/* <a href="/#testimonials">
              <button className="text-gray-600 hover:bg-gray-100 px-3 py-1 rounded-2xl">
                Testimonials
              </button>
            </a> */}
            <div className="flex flex-col text-lg font-semibold space-y-4 ml-4 pt-[130px]">
              <button
                className="text-gray-600 hover:bg-gray-100 px-3 py-1 rounded-2xl block text-left"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropdown((prev) => !prev);
                }}
              >
                Join Super 30 Batch
                {showDropdown ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </button>
              {showDropdown && (
                <div className="ml-3">
                  {programs.map((item, index) => (
                    <Link
                      className="my-[10px] block bg-gray-100 py-[3px] px-2 rounded-lg"
                      to={item.link}
                      key={index}
                    >
                      <p className="text-sm">{item.label}</p>
                    </Link>
                  ))}
                </div>
              )}

              {/* <a href="/campus-ambassador">
                <button className="text-gray-600 hover:bg-gray-100 px-3 py-1 rounded-2xl">
                  Campus Ambassador
                </button>
              </a> */}
              <a href="/become-mentor">
                <button className="text-gray-600 hover:bg-gray-100 px-3 py-1 rounded-2xl">
                  Be a mentor
                </button>
              </a>
              {/* <a href="/hiring">
                <button className="text-gray-600 hover:bg-gray-100 px-3 py-1 rounded-2xl">
                  Hire From Us
                </button>
              </a> */}
              <a href="https://lms.maangler.in/">
                <button className=" hover:bg-gray-100 px-3 py-1 rounded-xl border-2 border-primary text-primary ml-2">
                  Login
                </button>
              </a>
              {/* <a href="/account/sign-up">
              <button className="text-gray-600 hover:bg-gray-100 px-3 py-1 rounded-2xl">
                Sign Up
              </button>
            </a> */}
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

const DropdownMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        aria-controls="program-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
      >
        Programs
      </button>
      <Menu
        id="program-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {programs.map((program, index) => (
          <MenuItem key={index} onClick={handleClose}>
            <Link
              to={program.link}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {program.label}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const MenuIcon = () => (
  <div className="w-5 mr-2">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"></path>
    </svg>
  </div>
);

const CloseIcon = () => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path>
    </svg>
  </div>
);

const programs = [
  { label: "Join Python Batch", link: "/python-internship-program" },
  { label: "Join Java Batch", link: "/java-internship-program" },
  {
    label: "Join Full Stack Web Development Batch",
    link: "/full-stack-program",
  },
  // { label: "Artificial Intelligence Program", link: "/coming-soon" },
  // { label: "Machine Learning Program", link: "/coming-soon" },
  // { label: "C++ Program", link: "/coming-soon" },
  // { label: "Data Science Program", link: "/coming-soon" },
  // { label: "App Development Program", link: "/coming-soon" },
  // { label: "Cloud Computing Program", link: "/coming-soon" },
  // { label: "Cyber Security Program", link: "/coming-soon" },
  // { label: "DSA & System Design Program", link: "/coming-soon" },
  // { label: "More programs coming soon", link: "/coming-soon" },
];

export default MobileSideBar;
