import React from "react";
import { Link, useParams } from "react-router-dom";
import SignIn from "../components/SignInSignUp/SignIn";
import SignUp from "../components/SignInSignUp/SignUp";
import clsx from "clsx";

const SignInSignUp = () => {
  const { type } = useParams();
  return (
    <>
      <div className="mt-20 flex flex-col items-center justify-center mb-24">
        <div className="w-[470px] rounded-[30px] bg-white border h-[470px] mb:w-[360px] mb:h-auto mb:pb-4 shadow-2xl">
          <div className="flex w-full border-b ">
            <Link to={"/account/sign-up"} className="w-full">
              <button
                className={clsx(
                  "w-full px-3 py-4 rounded-tl-[30px]",
                  type === "sign-in" && "bg-[#E2E2E2]"
                )}
              >
                Sign Up
              </button>
            </Link>
            <Link to={"/account/sign-in"} className="w-full">
              <button
                className={clsx(
                  "w-full px-3 py-4 rounded-tr-[30px]",
                  type === "sign-up" && "bg-[#E2E2E2]"
                )}
              >
                Sign In
              </button>
            </Link>
          </div>
          {type === "sign-in" && <SignIn />}
          {type === "sign-up" && <SignUp />}
        </div>
      </div>
    </>
  );
};

export default SignInSignUp;
