import React from "react";
import icon1 from "../../static/CampusAmbassador/ambassadorIcon1.png";
import icon2 from "../../static/CampusAmbassador/ambassadorIcon2.png";
import icon3 from "../../static/CampusAmbassador/ambassadorIcon3.png";
import icon4 from "../../static/CampusAmbassador/ambassadorIcon4.png";
import icon5 from "../../static/CampusAmbassador/ambassadorIcon5.png";
import icon6 from "../../static/CampusAmbassador/ambassadorIcon6.png";
import rightVerticalLines from "../../static/Icons/rightVerticalLines.png";
import clsx from "clsx";
import leftSpring from "../../static/leftSpring.png";
import rightSpring from "../../static/rightSpring.png";

const PerksAmbassadorSection = () => {
  return (
    <div className="mt-20 relative">
      <p className="font-semibold text-4xl text-center mb:text-3xl px-6">
        Perks of Being a Campus Ambassador
      </p>
      <div className="grid grid-cols-3 gap-6 mt-10 md:grid-cols-2 md:gap-2 justify-items-center">
        {Data.map((item, index) => (
          <div
            key={index}
            className={clsx(
              "w-[260px] mb:w-[170px] mb:my-3",
              index % 2 === 0 ? "md:justify-self-end" : "md:justify-self-start"
            )}
          >
            <img src={item.icon} alt="" className="mx-auto mb:w-[100px]" />
            <p className="text-center font-semibold mb:text-sm mt-2">
              {item.des}
            </p>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center mx-auto mt-8">
        <button className="border-2 px-16 py-2 border-primary text-primary rounded-3xl  mb:px-10 transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white">
          Become an Ambassador
        </button>
        <img src={rightVerticalLines} alt="" className="w-[50px] mb-10" />
      </div>
      <img
        src={leftSpring}
        alt=""
        className="absolute left-0  md:hidden h-[300px] bottom-0"
      />
      <img
        src={rightSpring}
        alt=""
        className="absolute right-0 md:hidden h-[300px] top-0"
      />
    </div>
  );
};

const Data = [
  {
    icon: icon1,
    des: "Early Access in the all paid internships/ fresher Jobs at Maangler",
  },
  {
    icon: icon2,
    des: "Monthly Monetary Benefits ( Vouchers from your favourite brands)",
  },
  {
    icon: icon3,
    des: "Lots of goodies for extraordinary performance in our Events",
  },
  { icon: icon4, des: "Linkedin recommendation to all eligible candidates!" },
  {
    icon: icon5,
    des: "Interaction with expert professionals and get guided personally",
  },
  {
    icon: icon6,
    des: "Start your learning and become corporate ready professional",
  },
];

export default PerksAmbassadorSection;
