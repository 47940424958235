import React from "react";
import meta from "../../static/Icons/meta.png";
import apple from "../../static/Icons/apple.png";
import amazon from "../../static/Icons/amazon.png";
import google from "../../static/Icons/google.png";
import netflix from "../../static/Icons/netflix.png";
import boy from "../../static/Icons/boy.png";
//import verticalArrows from "../../static/Icons/verticalArrows.png";
import verticalArrows from '../../static/PurpleVerticalLines.png'
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Grow } from "@mui/material";
import { useInView } from "react-intersection-observer";
import leftCoin from '../../static/Icons/leftCoin.png'
import rightCoin from '../../static/Icons/rightCoin.png'

const MentorsStrip = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  return (
    <ResponsiveWrapper>
       <img src={leftCoin} alt="" className="h-[250px] absolute left-0 md:-z-10 mb:hidden" />
      <img src={rightCoin} alt="" className="h-[250px] absolute right-0 md:-z-10 mb:hidden" />
      <div className="mt-16 flex flex-col items-center justify-center  mb:px-2">
        <div className=" flex items-center" ref={ref}>
          <img alt="" className="w-[130px]" src={boy} />
          <p className="text-4xl font-semibold md:text-3xl">
            Our Mentors are from
          </p>
        </div>
        <Grow in={inView} timeout={1500}>
          <div className="flex justify-between space-x-10  bg-[#5f6176] py-9 px-10 w-full rounded-xl shadow-lg md:grid md:grid-cols-2 md:gap-3 mb:hidden">
            {imgData.map((item, index) => (
              <Image key={index} src={item} />
            ))}
          </div>
        </Grow>
        <div className="hidden mb:block bg-white py-9 px-10 mx-10 w-full rounded-xl">
          <Splide
            options={{
              perPage: 1,
              gap: "5rem",
              width: "100%",
              rewind: true,
              arrows: false,
              autoplay: true,
              interval: 2600,
              padding: 0,
              mediaQuery: "max",
            }}
          >
            {imgData.map((item, index) => (
              <SplideSlide>
                <Image key={index} src={item} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
        <div className="flex space-x-6 mb:flex-col mb:mt-8 mt-16">
          <div className="flex items-center">
            <img alt="" src={verticalArrows} />
            <a href="/#mentors">
              <button className="border-2  px-16 py-3 rounded-3xl  md:px-10 sm:px-8 mb:px-4 mb:text-sm transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white">
                Meet My Mentors
              </button> 
            </a>
          </div>
          
        </div>
      </div>
    </ResponsiveWrapper>
  );
};

const Image = ({ src }) => (
  <img
    className="w-auto h-[35px] transition ease-in-out duration-500 hover:scale-110 cursor-pointer"
    src={src}
    alt=""
    style={{ margin: "auto" }}
  />
);

const imgData = [meta, amazon, apple, netflix, google];

export default MentorsStrip;
