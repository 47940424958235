import React from "react";
import CustomInput from "../CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../../redux/profile/profileSlice";
import { useFormik } from "formik";
import { putUserProfile } from "../../redux/auth/apiSlice";

const SocialPreferences = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectUserData);
  const socialData = data?.social;

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      hackerrank: socialData?.hackerrank,
      github: socialData?.github,
      instagram: socialData?.instagram,
      twitter: socialData?.twitter,
      linkedin: socialData?.linkedin,
      portfolio: socialData?.portfolio,
      other: socialData?.other,
    },
    onSubmit: (values) => {
      dispatch(putUserProfile({ ...data, social: values }));
    },
  });

  return (
    <div className="flex flex-col h-full">
      <div className="space-y-3 grow">
        {feilds.map((item) => (
          <div key={item.name}>
            <p className="mb-1 font-semibold ml-1">{item.label}</p>
            <CustomInput
              name={item.name}
              error={errors[item.name]}
              onChange={handleChange}
              touched={touched[item.name]}
              value={values[item.name]}
            />
          </div>
        ))}
      </div>
      <button
        className="bg-primary w-full text-white py-3 rounded-3xl mt-10"
        onClick={handleSubmit}
        type="submit"
      >
        Update Social Profile
      </button>
    </div>
  );
};

const feilds = [
  {
    type: "text",
    name: "hackerrank",
    label: "Hackerrank Profile",
    icon: "",
  },
  {
    type: "text",
    name: "github",
    label: "Github Profile",
    icon: "",
  },
  {
    type: "text",
    name: "instagram",
    label: "Instagram Profile",
    icon: "",
  },
  {
    type: "text",
    name: "twitter",
    label: "Twitter Profile",
    icon: "",
  },
  {
    type: "text",
    name: "linkedin",
    label: "Linkedin Profile",
    icon: "",
  },
  {
    type: "text",
    name: "portfolio",
    label: "Portfolio Profile",
    icon: "",
  },
  {
    type: "text",
    name: "other",
    label: "Highlight other contributions",
    icon: "",
  },
];

export default SocialPreferences;
