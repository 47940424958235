import React from "react";
import HeroSection from "../components/Super30/HeroSection";
import ProgramsWeOffer from "../components/Super30/ProgramsWeOffer";
import MentorsSections from "../components/HomePage/MentorsSections";

const Super30 = () => {
  return (
    <>
      <div className="">
        <HeroSection />
        <ProgramsWeOffer />
        <div className="mb-10">
          <MentorsSections />
        </div>
      </div>
    </>
  );
};

export default Super30;
