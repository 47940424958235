import React from "react";
//import HeroSection from "../components/PythonInternshipProgram/HeroSection";
//import CourseInfo from "../components/PythonInternshipProgram/CourseInfo";
import Background from "../static/Python/Background.png";
import MentorsStrip from "../components/PythonInternshipProgram/MentorsStrip";
import ProgramRatings from "../components/PythonInternshipProgram/ProgramRatings";
import MentorsSections from "../components/PythonInternshipProgram/MentorsSection";
import HeroSection from "../components/Courses/HeroSection";
import CourseInfo from "../components/Courses/CourseInfo";

const PythonInternship = () => {
  return (
    <div
      style={{
        // backgroundImage: `url(${Background})`,
        backgroundColor: "#000",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="text-white pb-20 overflow-hidden"
    >
      {/* <HeroSection /> */}
      <HeroSection
        ButtonColor={"linear-gradient(100deg, #F0D399 0%, #E2A75E 100%)"}
        InstructorBgColor={
          "linear-gradient(138.1deg, rgba(147, 125, 81, 0.8) 7.23%, rgba(98, 55, 15, 0.8) 56.17%)"
        }
        courseDescription={pythonCourseDes}
        title={"Python Internship Program"}
        courseName={pythonCoursename}
        CourseDuration={pythonCourseDuration}
        instrctorNameBgColor={
          "linear-gradient(180deg, #3B2C10 50%, #000000 100%)"
        }
        applicationDeadLine={"linear-gradient(90deg, #F9F2D0 0%, #ECA877 100%)"}
        broachrLink={broachrLink}
      />
      <MentorsStrip
        CourseDuration={pythonCourseDuration}
        courseName={pythonCoursename}
        borderImageColor={"linear-gradient(90deg, #F9F2D0 0%, #ECA877 100%) 1"}
        instrctorNameBgColor={
          "linear-gradient(180deg, #3B2C10 50%, #000000 100%)"
        }
        ButtonColor={"linear-gradient(100deg, #F0D399 0%, #E2A75E 100%)"}
        SuccessStoryBgColor={"#191612"}
        SuccessStoryBoxShadoColor={
          "0px 3.13979px 11.7742px rgba(87, 76, 76, 0.15)"
        }
        SuccessStoryTextColor={"#655536"}
      />
      <CourseInfo
        data={data}
        border={"#1BFF07"}
        courseName="Java"
        CourseDuration={pythonCourseDuration}
        CourseInfo1={modules}
        CourseInfo2={modules2}
        ButtonColor={"linear-gradient(100deg, #F0D399 0%, #E2A75E 100%)"}
      />
      <ProgramRatings
        border={"#07D2FF"}
        courseName={pythonCoursename}
        GPTBoxColor={"linear-gradient(180deg, #3B2C10 50%, #000000 100%)"}
        GPTImgColor={"#614927"}
        borderImageColor={"linear-gradient(90deg, #F9F2D0 0%, #ECA877 100%) 1"}
        ButtonColor={"linear-gradient(100deg, #F0D399 0%, #E2A75E 100%)"}
      />
      <MentorsSections
        courseName={pythonCoursename}
        CourseDuration={pythonCourseDuration}
        ButtonColor={"linear-gradient(100deg, #F0D399 0%, #E2A75E 100%)"}
        GPTBoxColor={"linear-gradient(180deg, #3B2C10 50%, #000000 100%)"}
        BonusBgColor={"linear-gradient(360deg, #312411 70%, #000000 100%)"}
        BonusCardBgColor={"linear-gradient(100deg, #493309  0%, #1E1E1E 100%)"}
        accordionBgColor={"linear-gradient(90deg, #6F4F32 100%, #573115 100%)"}
      />
    </div>
  );
};
const broachrLink =
  "https://drive.google.com/file/d/10Dmy03j2Ug2dr9Faf7WzTzY7xDKNdnb5/view?usp=sharing";
const pythonCourseDes = (
  <p className="text-center mb-10 mt-8 relative z-50 mb:text-sm">
    Maangler Python is a thoughtfully curated 2-month program that kickstarts
    your Python coding journey under the guidance of top mentors from leading
    MAANG companies.
    <br className="lg:hidden" /> Guided by an extraordinary community of fellow
    students and invaluable industry connections,
    <br className="lg:hidden" /> we're here to provide unwavering support on
    your journey.
  </p>
);

const pythonCoursename = "Python";
const pythonCourseDuration = "8";

const data = {
  "Month 1": [
    {
      title: "Week 1",
      des: "Introduction to Python",
      info: [
        "Introduction to Python, its applications, and setup",
        "Variables, data types, and basic operations",
        "Conditional statements",
      ],
    },
    {
      title: "Week 2",
      des: "Loops & Functions",
      info: [
        "While loops, for loops, and range()",
        "Functions, parameters, and return values",
        "Function scope",
      ],
    },
    {
      title: "Week 3",
      des: "Lists, Dictionaries, and File Handling",
      info: ["Lists, tuples, and dictionaries", "Reading and writing files"],
    },
    {
      title: "Week 4",
      des: "Object-Oriented Programming",
      info: [
        "Introduction to OOP, classes, and objects",
        "Creating classes and inheritance",
      ],
    },
  ],
  "Month 2": [
    {
      title: "Week 1",
      des: "Modules and Libraries",
      info: [
        "Modules and packages",
        "Using popular libraries (e.g., NumPy, Pandas)",
      ],
    },
    {
      title: "Week 2",
      des: "Working with Data + Matplotlib",
      info: [
        "Reading and writing CSV and JSON files",
        "Data visualization",
        "Basics of web scraping",
      ],
    },
    {
      title: "Week 3",
      des: "Project 1 (instructor-led guided project) + File Handling in Python",
      info: [
        "The skills that you have learned till now, why not try them and make a guided project??",
      ],
    },
    {
      title: "Week 4",
      des: "Unguided Project and Conclusion",
      info: [
        "Basic EDA with Dataset provided. Take a dataset and analyze it (e.g., CSV file) as input and provide insights into the data. You can implement features like data summarization, basic statistics (mean, median, mode), and data visualization (using matplotlib or seaborn).",
      ],
    },
  ],
};
const modules = [
  {
    id: 1,
    title: "Introduction",
    description:
      "Gain a foundational understanding of Python programming. You'll learn about Python's applications, setting up the environment, variables, data types, basic operations, and conditional statements.",
  },
  {
    id: 3,
    title: "Lists, Dictionaries, and File Handling",
    description:
      "Learn essential data structures and file handling techniques. You’ll explore lists, tuples, dictionaries, and how to read and write files in Python.",
  },
  {
    id: 5,
    title: "Modules and Libraries",
    description:
      "Explore the power of Python’s modules and libraries. You’ll learn how to use modules and packages, and get hands-on experience with popular libraries like NumPy and Pandas.",
  },
  {
    id: 7,
    title: "Project 1 (Instructor-led Guided Project) + Advanced File Handling",
    description:
      "Apply your skills in a guided project and advanced file handling. You'll implement a real-world project using the skills you've learned and dive deeper into advanced file handling techniques.",
  },
];
const modules2 = [
  {
    id: 2,
    title: "Loops & Functions",
    description:
      "Master control flow and functions in Python. This module covers while loops, for loops, the range() function, and creating functions with parameters and return values.",
  },
  {
    id: 4,
    title: "Object-Oriented Programming",
    description:
      "Understand the principles of Object-Oriented Programming (OOP) in Python. This module introduces classes, objects, and inheritance to help you structure your code effectively.",
  },
  {
    id: 6,
    title: "Working with Data + Matplotlib",
    description:
      "Work with data and visualize it effectively. This module covers reading and writing CSV and JSON files, data visualization techniques, and basics of web scraping.",
  },
  {
    id: 8,
    title: "Unguided Project and Conclusion",
    description:
      "Solidify your learning with an unguided project. You'll conduct a basic Exploratory Data Analysis (EDA) with a provided dataset, analyze and provide insights, and implement data summarization, basic statistics, and data visualization using Matplotlib or Seaborn.",
  },
];

export default PythonInternship;
