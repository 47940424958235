import React from "react";
import HeroSection from "../components/CampusAmbassador/HeroSection";
import StoreStrip from "../components/CampusAmbassador/StoreStrip";
import WhatAmbassadorSection from "../components/CampusAmbassador/WhatAmbassadorSection";
import PerksAmbassadorSection from "../components/CampusAmbassador/PerksAmbassadorSection";
import ApplicationAmabassadorSection from "../components/CampusAmbassador/ApplicationAmabassadorSection";

const CampusAmbassador = () => {
  return (
    <div>
      <HeroSection />
      <StoreStrip />
      <WhatAmbassadorSection />
      <PerksAmbassadorSection />
      <ApplicationAmabassadorSection />
    </div>
  );
};

export default CampusAmbassador;
