import clsx from "clsx";
import React from "react";

const ResponsiveWrapper = ({ children, backgroundColor ,className }) => {
  return (
    <div className={clsx("px-[8%] xl:px-[3%] lg:px-[2%] md:px-[1%]", backgroundColor,className)}>
      {children}
    </div>
  );
};

export default ResponsiveWrapper;
