import React from "react";
import HeroSection from "../components/Courses/HeroSection";
import ProgramRatings from "../components/PythonInternshipProgram/ProgramRatings";
import CourseInfo from "../components/Courses/CourseInfo";
//import MentorsStrip from "../components/PythonInternshipProgram/MentorsStrip";
// import MentorsSections from "../components/HomePage/MentorsSections";
import Background from "../static/Java/Background.png";
import MentorsStrip from "../components/PythonInternshipProgram/MentorsStrip";
import MentorsSections from "../components/PythonInternshipProgram/MentorsSection";

const JavaInternship = () => {
  return (
    <div
      style={{
        // backgroundImage: `url(${Background})`, 181c03
        backgroundColor: "#000",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="text-white pb-20 overflow-hidden"
    >
      {/* <HeroSection /> */}
      <HeroSection
        ButtonColor={"linear-gradient(90.92deg, #FFE883 0%, #66BAFF 98.89%)"}
        InstructorBgColor={
          " linear-gradient(91.74deg, rgba(142, 139, 77, 0.8) 16.87%, rgba(35, 107, 122, 0.8) 93.38%)"
        }
        courseDescription={JavaCourseDes}
        title={"Java Internship Program"}
        courseName={javaCoursename}
        CourseDuration={javaCourseDuration}
        instrctorNameBgColor={
          "linear-gradient(180deg, #829b20 -94.9%, #000000 119.41%)"
        }
        applicationDeadLine={
          "linear-gradient(91.74deg, rgba(142, 139, 77, 0.8) 16.87%, rgba(35, 107, 122, 0.8) 93.38%)"
        }
        broachrLink={broachrLink}
      />
      <MentorsStrip
        CourseDuration={javaCourseDuration}
        courseName={javaCoursename}
        borderImageColor={
          "linear-gradient(91.74deg, rgba(142, 139, 77, 0.8) 16.87%, rgba(35, 107, 122, 0.8) 93.38%) 1"
        }
        instrctorNameBgColor={
          "linear-gradient(180deg, #5c6b1f -100.9%, #000000 119.41%)"
        }
        ButtonColor={"linear-gradient(90.92deg, #FFE883 0%, #66BAFF 98.89%)"}
        SuccessStoryBgColor={"#14181D"}
        SuccessStoryBoxShadoColor={
          "0px 3.13979px 11.7742px rgba(87, 76, 76, 0.15)"
        }
        SuccessStoryTextColor={"#535415"}
      />
      {/* //need to check applicationDeadLine */}
      <CourseInfo
        data={data}
        border={"#1BFF07"}
        courseName="Java"
        CourseDuration={javaCourseDuration}
        CourseInfo1={modules}
        CourseInfo2={modules2}
        ButtonColor={"linear-gradient(90.92deg, #FFE883 0%, #66BAFF 98.89%)"}
      />
      <ProgramRatings
        border={"#1BFF07"}
        courseName={javaCoursename}
        GPTBoxColor={
          "linear-gradient(180deg, #596721 -94.9%, rgba(0, 0, 0, 0.7) 119.41%)"
        }
        GPTImgColor={"rgba(219, 221, 154, 0.4)"}
        borderImageColor={
          "linear-gradient(91.74deg, rgba(142, 139, 77, 0.8) 16.87%, rgba(35, 107, 122, 0.8) 93.38%) 1"
        }
        ButtonColor={"linear-gradient(90.92deg, #FFE883 0%, #66BAFF 98.89%)"}
      />
      <MentorsSections
        courseName={javaCoursename}
        CourseDuration={javaCourseDuration}
        ButtonColor={"linear-gradient(90.92deg, #FFE883 0%, #66BAFF 98.89%)"}
        GPTBoxColor={
          "linear-gradient(180deg, #596721 -94.9%, rgba(0, 0, 0, 0.7) 119.41%)"
        }
        BonusBgColor={"linear-gradient(360deg, #181c03 31%, #000000 100%)"}
        BonusCardBgColor={"rgba(206, 207, 210, 0.05)"}
        accordionBgColor={
          "linear-gradient(91.74deg, rgba(95, 111, 50, 0.8) 16.87%, rgba(21, 83, 87, 0.8) 93.38%)"
        }
      />
      {/* //need to check */}
    </div>
  );
};

const JavaCourseDes = (
  <p className="text-center mb-10 mt-8 relative z-50 mb:text-sm">
    Maangler JAVA is a meticulously crafted program to kick-start your journey
    into the world of JAVA & Data Science. Our rigorous curriculum, led by
    industry veterans,
    <br className="lg:hidden" /> mentors you as you progress towards growth,
    ultimately making you an adept #maangler. With our unwavering support,
    <br className="lg:hidden" /> you can drive change not only in your career
    but also in the realm of Data Science.
  </p>
);

const javaCoursename = "Java";
const javaCourseDuration = "12";
const broachrLink =
  "https://drive.google.com/file/d/1sncirUKWkR7IbvM1cIw1oCZ6esDO9dfC/view?usp=sharing";

const data = {
  "Month 1": [
    {
      title: "Week 1",
      des: "Introduction to Java",
      info: [
        "What is Java?",
        "Setting up Java Development Environment",
        "Why do we need to learn Python?",
        "Variables and data types",
        "Input and output in Java",
      ],
    },
    {
      title: "Week 2",
      des: "Control Statements",
      info: ["Conditional statements", "Loops", "Switch statements"],
    },
    {
      title: "Week 3",
      des: "Arrays & Methods",
      info: [
        "Array basics",
        "Array manipulation",
        "Writing methods",
        "Passing parameters",
      ],
    },
    {
      title: "Week 4",
      des: "Object-Oriented Programming",
      info: [
        "Introduction to OOP",
        "Classes and objects",
        "Constructors and methods",
        "Inheritance",
      ],
    },
  ],
  "Month 2": [
    {
      title: "Week 1",
      des: "Interfaces & Polymorphism",
      info: ["Interfaces", "Implementing interfaces", "Polymorphism"],
    },
    {
      title: "Week 2",
      des: "Exception Handling",
      info: [
        "Handling exceptions",
        "Try-catch block",
        "Creating and writing to files",
        "Reading from files",
      ],
    },
    {
      title: "Week 3",
      des: "Collections Framework",
      info: [
        "ArrayList, LinkedList, and other collection classes",
        "Iterating over collections",
        "Sorting and searching",
      ],
    },
    {
      title: "Week 4",
      des: "Multithreading & Networking",
      info: [
        "Creating threads",
        "Synchronizing threads",
        "Network programming basics",
      ],
    },
  ],
  "Month 3": [
    {
      title: "Week 1",
      des: "Capstone Projects",
      info: [
        "First 10 Days Minor Projects.",
        "Next 20 Days Major Projects.",
        "Weekly Webinars and insights from MAANG Mentors.",
        "Community Events , group discussions and many more.",
      ],
    },
  ],
};

const modules = [
  {
    id: 1,
    title: "Introduction to Java",
    description:
      "Begin your Java journey by setting up the development environment, mastering variables, data types, and learning input/output operations in Java.",
  },
  {
    id: 3,
    title: "Arrays & Methods",
    description:
      "Dive into arrays for structured data storage and manipulation, and learn to create methods with parameters and return values for modular code development.",
  },
  {
    id: 5,
    title: "Exception Handling",
    description:
      "Master exception handling techniques in Java to manage runtime errors gracefully and ensure robust application performance.",
  },
  {
    id: 7,
    title: "Multithreading & Networking",
    description:
      "Delve into multithreading concepts to execute multiple tasks concurrently and basics of networking to enable communication between applications.",
  },
];
const modules2 = [
  {
    id: 2,
    title: "Control Statements",
    description:
      "Explore control flow mechanisms in Java, including conditional statements, loops, and switch statements, to manage program execution paths effectively.",
  },
  {
    id: 4,
    title: "Object-Oriented Programming",
    description:
      "Understand the principles of OOP with Java, covering classes, objects, constructors, inheritance, and polymorphism to build robust and scalable applications.",
  },
  {
    id: 6,
    title: "Collections Framework",
    description:
      "Explore Java's Collections Framework with ArrayList, LinkedList, and other data structures for efficient data handling and manipulation.",
  },
  {
    id: 8,
    title: "Advanced Topics and Projects",
    description:
      "Apply your Java skills in real-world scenarios through hands-on projects, covering advanced topics like synchronization, file I/O operations, and practical applications of networking principles.",
  },
];

export default JavaInternship;
