import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginAsync } from "../../redux/auth/apiSlice";
import { selectCurrentStep } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { REGISTRATION_STEP } from "../../utils/Constants";
import ContinueWithGoogle from "./ContinueWithGoogle";

const SignIn = () => {
  const currentStep = useSelector(selectCurrentStep);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleChange, errors, values, handleSubmit, touched, resetForm } =
    useFormik({
      initialValues: { email: "", password: "" },
      validationSchema: Yup.object().shape({
        email:
          Yup.string()
          .required("Email is required"),
        password: Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one special character, and one digit"
          )
          .required("Password is required"),
      }),
      onSubmit: (values) => {
        resetForm();
        dispatch(
          loginAsync({ email: values.email, password: values.password })
        );
      },
    });

  useEffect(() => {
    if (currentStep === REGISTRATION_STEP.STEP_REGISTERED_SUCCESSFULLY) {
      navigate("/");
    }
  }, [currentStep]);


  return (
    <div className="px-3 mt-5 flex flex-col items-center space-y-6">
      <p className="text-center text-3xl font-semibold">Sign In to continue</p>
      <ContinueWithGoogle />
      <div className="flex items-center w-full space-x-2 mt-3">
        <div className=" h-[2px] bg-gray-200 flex-grow rounded"></div>
        <p>Or</p>
        <div className=" h-[2px] bg-gray-200 flex-grow rounded"></div>
      </div>
      <div className="space-y-6 w-full">
        <Input
          onChange={handleChange}
          value={values?.email}
          error={errors?.email}
          placeholder={"Enter Mail ID"}
          name={"email"}
          touched={touched?.email}
        />
        <Input
          onChange={handleChange}
          value={values?.password}
          error={errors?.password}
          placeholder={"Enter Password"}
          name={"password"}
          touched={touched?.password}
          type="password"
        />
      </div>
      <button
        onClick={handleSubmit}
        className="bg-primary text-white font-semibold px-14 py-3 rounded-3xl hover:bg-[#0288b0] mt-12"
      >
        Sign In
      </button>
    </div>
  );
};

const Input = ({
  onChange,
  value,
  error,
  placeholder,
  name,
  touched,
  type = "text",
}) => (
  <div className="flex flex-col">
    <input
      className="bg-gray-100 focus:outline-none py-3 px-3 text-sm w-full rounded-3xl mt-2 "
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      name={name}
      type={type}
    />
    {error && touched && <p className="text-xs ml-4 text-red-500">* {error}</p>}
  </div>
);

// const logOut = () => {
//     googleLogout();
//     setProfile(null);
//   };

export default SignIn;
