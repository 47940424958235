import React, { useState } from "react";
import ExperiencesSection from "./ExperiencesSection";
import { Delete, Edit } from "@mui/icons-material";
import Select from "react-select";
import AddSkillSection from "./AddSkillSection";
import { useDispatch, useSelector } from "react-redux";
import { putUserProfile } from "../../redux/auth/apiSlice";
import { selectUserData } from "../../redux/profile/profileSlice";

const JobProfile = () => {
  const data = useSelector(selectUserData);
  const jobData = data?.job;
  const dispatch = useDispatch();
  const [jobProfileData, setJobProfileData] = useState({
    profileHeadline: jobData?.profileHeadline,
    experience: jobData?.experience,
    noticePeriod: jobData?.noticePeriod,
    skills: jobData?.skills,
  });

  const [initialValuesForExperience, setInitialValuesForExperience] =
    useState(null);
  const [showExperienceSection, setShowExperienceSection] = useState(false);
  const [showAddSkills, setShowAddSkills] = useState(false);
  const [jobExperienceCardType,setJobExperienceCardType] = useState('add')

  const addExperienceHandler = () => {
    setShowExperienceSection(true);
    setInitialValuesForExperience({
      jobExperienceId: Date.now(),
      company: "",
      jobTitle: "",
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      workingHere: false,
      annualSalary: "",
    });
  };

  const handleSubmit = () => {
    dispatch(putUserProfile({ ...data, job: jobProfileData }));
  };


  return (
    <div className="flex flex-col h-full">
      <div className="space-y-3 grow">
        <div>
          <p className="text-lg font-semibold mb-1">Profile Headline</p>
          <textarea
            className="w-full focus:outline-primary bg-gray-200 rounded-2xl h-[70px] text-sm py-3 px-4 resize-none"
            onChange={(e) =>
              setJobProfileData((prev) => ({
                ...prev,
                profileHeadline: e.target.value,
              }))
            }
            value={jobProfileData?.profileHeadline}
          />
        </div>

        {/* Experience */}

        <div>
          <div className="flex justify-between">
            <p className="text-lg font-semibold">Experiences</p>
            <button
              className="text-red-500 text-sm"
              onClick={addExperienceHandler}
            >
              + Add Experiences
            </button>
          </div>
          <div className="mt-2">
            {jobProfileData.experience.length !== 0 &&
              jobProfileData.experience.map((item) => (
                <div className="flex justify-between mb-3">
                  <div className="text-sm font-semibold">
                    <p>{item.company}</p>
                    <p>{item.jobTitle}</p>
                    <p className="font-normal text-xs">
                      {item.startMonth} {item.startYear} - {" "}
                      {item.workingHere
                        ? "Current"
                        : item.endMonth + " " + item.endYear}
                    </p>
                  </div>
                  <div className="flex">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setInitialValuesForExperience(item);
                        setShowExperienceSection(true);
                      }}
                    >
                      <Edit fontSize={"small"} />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setJobProfileData((prev) => ({
                          ...prev,
                          experience: prev.experience.filter(
                            (el) => el.jobExperienceId !== item.jobExperienceId
                          ),
                        }));
                      }}
                    >
                      <Delete fontSize={"small"} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {showExperienceSection && initialValuesForExperience !== null && (
            <ExperiencesSection
              initialValuesForExperience={initialValuesForExperience}
              setShowExperienceSection={setShowExperienceSection}
              setInitialValuesForExperience={setInitialValuesForExperience}
              setJobProfileData={setJobProfileData}
              jobProfileData={jobProfileData}
              jobExperienceCardType={jobExperienceCardType}
            />
          )}
        </div>

        {/* Notice Period Section  */}

        <div className="flex justify-between">
          <p className="text-lg font-semibold">Notice Period</p>
          <Select
            defaultValue={{
              label: labelForNoticePeriod[jobProfileData?.noticePeriod],
              value: jobProfileData.noticePeriod,
            }}
            options={noticePeriodOptions}
            styles={customStyles}
            menuPlacement="auto"
            onChange={(e) => {
              setJobProfileData((prev) => ({
                ...prev,
                noticePeriod: e.value,
              }));
            }}
            placeholder={"Select notice period"}
          />
        </div>

        {/* Skills Section */}

        <div>
          <div className="flex justify-between">
            <p className="text-lg font-semibold">Skills</p>
            <button
              className="text-red-500 text-sm"
              onClick={() => setShowAddSkills(true)}
            >
              + Add Skills
            </button>
          </div>
          {jobProfileData.skills.length !== 0 &&
            jobProfileData.skills.map((item) => (
              <div className="flex justify-between">
                <p>{item.title}</p>
                <div className="text-gray-600 text-sm flex space-x-2">
                  <p>{item?.years} year</p>
                  <p>{item?.months} month</p>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setJobProfileData((prev) => ({
                        ...prev,
                        skills: prev.skills.filter(
                          (el) => el.title !== item.title
                        ),
                      }));
                    }}
                  >
                    <Delete fontSize="small" />
                  </div>
                </div>
              </div>
            ))}
          {showAddSkills && (
            <AddSkillSection
              data={jobProfileData.skills}
              setJobProfileData={setJobProfileData}
              setShowAddSkills={setShowAddSkills}
            />
          )}
        </div>
        {/* end */}
      </div>
      <button
        className="bg-primary w-full text-white py-3 rounded-3xl mt-10"
        onClick={handleSubmit}
      >
        Update Account Details
      </button>
    </div>
  );
};

const jobProfileData = {
  profileHeadLine: "",
  experience: [],
  noticePeriod: "",
  skills: [],
};

const noticePeriodOptions = [
  { label: "Immediate", value: "0" },
  { label: "15 days", value: "15" },
  { label: "30 days", value: "30" },
  { label: "45 days", value: "45" },
  { label: "60 days", value: "60" },
  { label: "90 days", value: "90" },
];

const labelForNoticePeriod = {
  0: "Immediate",
  15: "15 days",
  30: "30 days",
  45: "45 days",
  60: "60 days",
  90: "90 days",
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width:'150px',
    borderRadius: "24px",
    backgroundColor: "rgb(229, 231, 235)",
    border: state.isFocused ? `1px solid #32A3C5` : "none",
    fontSize: "14px",
    "&:hover": {
      border: "none",
    },
  }),
};

export default JobProfile;
