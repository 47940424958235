import React from "react";
import data from "../../static/Icons/data.png";
import teacher from "../../static/Icons/teacher.png";
import community from "../../static/Icons/community.png";
import clown from "../../static/Icons/clown.png";
import rightVerticalLines from "../../static/Icons/rightVerticalLines.png";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import plane from "../../static/Images/plane.png";
import { Link } from "react-router-dom";
import {  useInView } from "react-intersection-observer";
import { Grow } from "@mui/material";
import clsx from "clsx";

const SolutionSection = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  return (
    <ResponsiveWrapper className={"relative"}>
      <div className="mt-16">
        <p className="text-center text-4xl font-semibold mb-16 z-10 xl:text-3xl">
          Tailormade solutions for academic <br />
          partners
        </p>
        <div
          className=" grid grid-cols-3 grid-rows-2 gap-4 z-10 md:grid lg:grid-cols-2 md:grid-cols-2 md:mx-auto md:gap-4 sm:grid-cols-1 space-x-8 lg:space-x-2 md:space-x-0 relative justify-items-center"
          style={{ zIndex: 30 }}
          ref={ref}
        >
          {Data.map((item, index) => (
            <Grow in={inView} timeout={1500}>
              <div
                key={index}
                className={clsx(
                  "md:mx-auto",
                  arr.includes(index)
                    ? "justify-self-center"
                    : "  justify-self-center"
                )}
                style={{ margin: "auto" }}
              >
                <Box
                  icon={item.icon}
                  heading={item.heading}
                  infoText={item.infoText}
                  hasList={item.hasList}
                  list={item?.list}
                />
              </div>
            </Grow>
          ))}
        </div>
        <div className="flex justify-center items-center mt-8 space-x-4">
          <img
            alt=""
            src={clown}
            className="w-[120px] mb:w-[80px]"
          />
          <div className="flex items-center">
            <Link to={"/super-30"}>
              <button className="border-2 px-16 py-2 border-primary text-primary rounded-3xl  mb:px-10 transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white">
                Join Now
              </button>
            </Link>
            <img src={rightVerticalLines} alt="" className="w-[50px] mb-10" />
          </div>
        </div>
      </div>
      <img
        src={plane}
        alt=""
        className="absolute top-0 left-[10%] sm:hidden"
        style={{ zIndex: 10 }}
      />
    </ResponsiveWrapper>
  );
};

const arr = [0, 1, 2];

const Box = ({ icon, heading, infoText, hasList, list }) => (
  <div className="w-[350px] border bg-white px-6 py-8 h-[380px] rounded-2xl shadow-xl space-y-3 xl:w-[310px] xl:h-[430px]   transition ease-in-out duration-500 hover:scale-110 cursor-pointer">
    <div className="h-[100px]">
      <img alt="" src={icon} />
    </div>
    <div>
      <p className="text-lg font-semibold">{heading}</p>
      {!hasList && <p className="text-gray-500">{infoText}</p>}
      {hasList && (
        <ul className="list-disc text-gray-500" style={{listStyle:'inside'}}>
          {list.map((item) => (
            <li className="list-disc">{item}</li>
          ))}
        </ul>
      )}
    </div>
  </div>
);

const Data = [
  {
    icon: data,
    heading: "Curriculum - integrated programs",
    hasList: false,
    infoText:
      "We work with your institute to introduce a wide range of programs across domains which will be integrated with the credit system to offer your students a unique, training experience coupled with industry-relevant projects, accelerating your engineering admissions.",
  },
  {
    icon: teacher,
    heading: "Booster programs with placement",
    hasList: false,
    infoText:
      "Our specialised booster programs are aimed at your final year students and graduates, equipping them with leading skills that will help them get a jumpstart on their careers.",
  },
  {
    icon: community,
    heading: "10+ specialized programs for your students",
    hasList: false,
    infoText:
      "Maangler’s extensive digital library carries 10+ courses and PG programs with over 6000 hours of recorded content. Choose from our wide repository of courses across core and IT domains",
  },
  {
    icon: teacher,
    heading: "Mangler Center of Excellence",
    hasList: false,
    infoText:
      "Mangler introduces “Labs'' – a dedicated space to carry out virtual simulations and give your students hands-on training in electric vehicle design. Based on your preference, you can set up a virtual EV Lab or a physical lab with the latest hardware and infrastructure",
  },
  {
    icon: community,
    heading: "Personalized placement support",
    list: [
      "5 guaranteed interviews",
      "Creative resume and LinkedIn profile creation",
      " Mock interviews",
      "Communication & aptitude training",
      "Technical assessments",
    ],
    hasList: true,
    infoText: "",
  },
];

export default SolutionSection;
