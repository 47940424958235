import React from "react";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";

const SigningUpSection = () => {
  return (
    <ResponsiveWrapper>
      <div className="mt-20 space-y-6 flex justify-center flex-col items-center">
        <p className="text-4xl font-semibold text-center">
          Signing up takes less than a minute ⏳
        </p>
        <p className="text-center">
          Focus your efforts on a pool of high-quality and <br />
          actively looking out candidates.
        </p>
        <a
          href="https://calendly.com/ritik-gupta/30min"
          target="_blank"
          rel="noreferrer"
        >
          <button className="bg-primary text-white font-semibold px-14 py-3 rounded-3xl hover:bg-[#0288b0] mt-10  transition ease-in-out duration-500 hover:scale-105">
            Start Hiring
          </button>
        </a>
      </div>
    </ResponsiveWrapper>
  );
};

export default SigningUpSection;
