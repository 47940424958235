import React, { useState } from "react";
import ContinueWithGoogle from "./ContinueWithGoogle";

const SignUpForm = ({
  handleChange,
  values,
  errors,
  touched,
  handleSubmit,
}) => {  

  return (
    <div className="px-3 mt-5 flex flex-col items-center space-y-6">
      <p className="text-center text-3xl font-semibold">Create Your Account</p>
      <div>
        <ContinueWithGoogle />
        <div className="flex items-center w-full space-x-2 mt-3">
          <div className=" h-[2px] bg-gray-200 flex-grow rounded"></div>
          <p>Or</p>
          <div className=" h-[2px] bg-gray-200 flex-grow rounded"></div>
        </div>
        <div className="grid grid-cols-2 gap-5 mt-4 mb:grid-cols-1">
          <Input
            onChange={handleChange}
            value={values?.name}
            error={errors?.name}
            placeholder={"Enter Name"}
            name={"name"}
            touched={touched?.name}
          />
          <Input
            onChange={handleChange}
            value={values?.email}
            error={errors?.email}
            placeholder={"Enter Mail ID"}
            name={"email"}
            touched={touched?.email}
          />
          <Input
            onChange={handleChange}
            value={values?.password}
            error={errors?.password}
            placeholder={"Enter Password"}
            name={"password"}
            touched={touched?.password}
            type="password"
          />
          <Input
            onChange={handleChange}
            value={values?.rePassword}
            error={errors?.rePassword}
            placeholder={"Re-Enter Password"}
            name={"rePassword"}
            touched={touched?.rePassword}
            type="password"
          />
        </div>
      </div>
      <button
        className="bg-primary text-white font-semibold px-14 py-3 rounded-3xl hover:bg-[#0288b0] mt-12"
        onClick={handleSubmit}
      >
        Join Now
      </button>
    </div>
  );
};

const Input = ({
  onChange,
  value,
  error,
  placeholder,
  name,
  touched,
  type = "text",
}) => (
  <div className="flex flex-col">
    <input
      className="bg-gray-100 focus:outline-none py-3 px-3 text-sm w-[200px] rounded-3xl mt-2 mb:w-[300px]"
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      name={name}
      type={type}
    />
    {error && touched && <p className="text-xs ml-4 text-red-500">* {error}</p>}
  </div>
);

export default SignUpForm;
