import React from "react";

const CustomInput = ({
  name,
  type = "text",
  value,
  onChange,
  required,
  placeholder,
  error,
  touched,
  disabled = false,
  className='rounded-3xl py-[10px]'
}) => {
  return (
    <>
      <input
        className={`w-full px-4  text-sm   ${
          disabled
            ? "bg-gray-300 cursor-not-allowed focus:outline-none"
            : "bg-gray-200 focus:outline-primary"
        } ${className}`}
        type={type}
        value={value}
        onChange={disabled ? () => null : onChange}
        name={name}
        placeholder={placeholder}
      />
      {error && touched && (
        <p className="text-xs ml-4 text-red-500">* {error}</p>
      )}
    </>
  );
};

export default CustomInput;
