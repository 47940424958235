import { createSlice } from "@reduxjs/toolkit";
import {
  OtpSent,
  confirmOtp,
  loginAsync,
  registerNewUser,
  socialLogin,
} from "./apiSlice";
import { REGISTRATION_STEP, STATUS_CODES } from "../../utils/Constants";
import { Cookie } from "@mui/icons-material";
import Cookies from "js-cookie";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    status: "",
    isLoggedIn: false,
    currentStep: "",
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload;
      state.user = user;
      state.token = accessToken;
    },
    logOut: (state, action) => {
      state.user = null;
      state.token = null;
    },
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload.loggedInState;
      state.token = action.payload.token
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
  },
  extraReducers: {
    // ^ Pending
    [registerNewUser.pending]: (state, action) => {
      state.status = STATUS_CODES.PENDING;
    },
    [loginAsync.pending]: (state, action) => {
      state.status = STATUS_CODES.PENDING;
    },
    [socialLogin.pending]: (state, action) => {
      state.status = STATUS_CODES.PENDING;
    },
    [OtpSent.pending]: (state, action) => {
      state.status = STATUS_CODES.PENDING;
    },
    [confirmOtp.pending]: (state, action) => {
      state.status = STATUS_CODES.PENDING;
    },

    // ^ Fulfilled
    [registerNewUser.fulfilled]: (state, action) => {
      // if (action.payload?.token) {
      //   const expirationDate = new Date();
      //   expirationDate.setDate(expirationDate.getDate() + 1);
      //   Cookies.set("access_token", action.payload.token, {
      //     expires: expirationDate,
      //   });
      //   state.status = STATUS_CODES.FULFILLED;
      //   state.currentStep = "otp";
      // }
    },
    [loginAsync.fulfilled]: (state, action) => {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      Cookies.set("access_token", action.payload.token, {
        domain:'.maangler.in',
        path:'/',
        expires: expirationDate,
      });
      state.status = STATUS_CODES.FULFILLED;
      state.isLoggedIn = true;
      state.currentStep = REGISTRATION_STEP.STEP_REGISTERED_SUCCESSFULLY;
      state.token = action.payload.token
    },
    [socialLogin.fulfilled]: (state, action) => {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      Cookies.set("access_token", action.payload.token, {
        domain:'.maangler.in',
        path:'/',
        expires: expirationDate,
      });
      state.status = STATUS_CODES.FULFILLED;
      state.isLoggedIn = true;
      state.token = action.payload.token
    },
    [confirmOtp.fulfilled]: (state, action) => {
      if (action.payload?.token) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        Cookies.set("access_token", action.payload?.token, {
          domain:'.maangler.in',
          path:'/',
          expires: expirationDate,
        });
        state.status = STATUS_CODES.FULFILLED;
        state.isLoggedIn = true;
        state.user = action.payload?.userProfile;
        state.currentStep = REGISTRATION_STEP.STEP_REGISTERED_SUCCESSFULLY;
        state.token = action.payload.token
      }

    },
    [OtpSent.fulfilled]: (state, action) => {
      state.currentStep = REGISTRATION_STEP.STEP_OTP;
      state.status = STATUS_CODES.FULFILLED;
    },

    // ^ Rejected
    [registerNewUser.rejected]: (state, action) => {
      state.status = STATUS_CODES.REJECTED;
    },
    [loginAsync.rejected]: (state, action) => {
      state.status = STATUS_CODES.REJECTED;
    },
    [socialLogin.rejected]: (state, action) => {
      state.status = STATUS_CODES.REJECTED;
    },
    [OtpSent.pending]: (state, action) => {
      state.status = STATUS_CODES.REJECTED;
    },
    [confirmOtp.pending]: (state, action) => {
      state.status = STATUS_CODES.REJECTED;
    },
  },
});

export const { setCredentials, logOut, setLoggedIn, setCurrentStep } =
  authSlice.actions;
export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectCurrentStep = (state) => state.auth.currentStep;
export const selectStatus = (state) => state.auth.status;
export default authSlice.reducer;
