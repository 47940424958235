import React from "react";
import { isLoggedIn } from "./helperFunctions";
import { Navigate } from "react-router-dom";

const LoggedInRoute = ({ children }) => {
  const authorized = isLoggedIn();
  if (authorized) {
    return <Navigate to={"/"} />;
  }else{
    return children
  }
};

export default LoggedInRoute;
