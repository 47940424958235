import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { confirmOtp } from "../../redux/auth/apiSlice";
import { setCurrentStep } from "../../redux/auth/authSlice";

const OtpStep = ({ formData }) => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();

  const handleOtpConfirm = () => {
    dispatch(
      confirmOtp({
        email: formData.email,
        name: formData.email,
        password: formData.password,
        otp: otp,
      })
    );
  };

  return (
    <div className="h-full flex flex-col justify-center items-center mt-20">
      <button
        className="grow mb-4 border py-1 px-4 text-sm rounded-xl bg-gray-100 text-primary font-semibold"
        onClick={() => dispatch(setCurrentStep(""))}
      >
        Back
      </button>
      <div className="">
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          renderSeparator={<span className="mx-2 text-gray-600">-</span>}
          inputStyle={{
            border: "2px solid gray",
            padding: "2px",
            width: "2rem",
            height: "2rem",
            borderRadius: "10px",
          }}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <button
        className="bg-primary text-white font-semibold px-14 py-3 rounded-3xl hover:bg-[#0288b0] mt-12"
        onClick={handleOtpConfirm}
      >
        Confirm Otp
      </button>
    </div>
  );
};

export default OtpStep;
