import {
  BoltOutlined,
  DocumentScannerOutlined,
  HistoryToggleOffOutlined,
  InsightsOutlined,
  ReceiptLongOutlined,
  Savings,
  SavingsOutlined,
} from "@mui/icons-material";
import React, { useEffect } from "react";
import Testimonials from "../components/HomePage/Testimonials";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

const ResumeBuilderIntro = ({ setCurrentStep }) => {
  return (
    <div>
      <Section1 setCurrentStep={setCurrentStep} />
      <Section2 setCurrentStep={setCurrentStep} />
      <Section3 setCurrentStep={setCurrentStep} />
      <Testimonials />
      <Section4 />
    </div>
  );
};

const Section1 = ({ setCurrentStep }) => (
  <div className="layout py-20 bg-[#ecf1f4]">
    <p className="text-4xl font-semibold text-center leading-[45px] text-gray-600 lg:text-3xl mb:text-[21px]">
      Stand out from the crowd
      <br /> with a professional resume
    </p>
    <p className="text-center mt-8 text-gray-600 mb:text-sm">
      Don't let your resume hold you back. Seekho's online resume builder makes
      it easy
      <br className="mb:hidden" /> to create a standout resume that will help
      you land your dream job.
    </p>
    <button
      className="text-white mb:text-sm bg-primary rounded-3xl mx-auto py-2 px-6 w-fit flex mt-10 mb:px-4"
      onClick={() => setCurrentStep(3)}
    >
      Create Resume Now
    </button>
  </div>
);

const Section2 = ({ setCurrentStep }) => (
  <div className="layout py-20 flex flex-col items-center">
    <div className="flex justify-between mb-6">
      <div>
        <p className="text-4xl text-gray-600 font-semibold text-center leading-[55px] lg:text-3xl mb:text-[21px]">
          Why You Should <br /> Use This
        </p>
        <button
          className="text-white bg-primary rounded-3xl py-2 px-6 w-fit flex mt-10 mx-auto mb-10 mb:text-sm mb:px-4"
          onClick={() => setCurrentStep(3)}
        >
          Create Resume Now
        </button>
      </div>
      <div>
        {/* <p className="max-w-[500px]">
          At Seekho, we understand the importance of having a standout resume
          that gets you noticed by potential employers. That's why we've
          developed a comprehensive resume builder that helps you create a
          professional and effective resume, no matter your career field or
          level of experience.
        </p> */}
      </div>
    </div>
    <div className="grid grid-cols-2 gap-y-28 gap-x-48 lg:gap-x-16 mb:grid-cols-1">
      {dataSection2.map((item, index) => (
        <div className="max-w-[350px] text-center" key={index}>
          <p>{item.icon}</p>
          <p className="text-xl text-primary font-semibold mb-2 mt-1 sm:text-lg mb:text-base">
            {item.title}
          </p>
          <p className="mb:text-sm">{item.des}</p>
        </div>
      ))}
    </div>
  </div>
);

const dataSection2 = [
  {
    icon: (
      <SavingsOutlined
        className="text-primary"
        sx={{ width: "60px", height: "60px", marginBottom: "8px" }}
      />
    ),
    title: "100% Free:",
    des: "You can use our resume builder completely free of charge. No hidden charges, no locked features. Print or download your resumes as a PDF",
  },
  {
    icon: (
      <DocumentScannerOutlined
        className="text-primary"
        sx={{ width: "55px", height: "55px", marginBottom: "8px" }}
      />
    ),
    title: "Resumes For Every Role:",
    des: "Maangler is an inclusive platform for professionals of all career fields and levels of experience. Our platform helps users create a standout resume that gets noticed by potential employers.",
  },
  {
    icon: (
      <ReceiptLongOutlined
        className="text-primary"
        sx={{ width: "55px", height: "55px", marginBottom: "8px" }}
      />
    ),
    title: "Creative and Professional Resume Templates:",
    des: "We have a wide range of resume templates to choose from, including classic black-and-white templates and more unique options.",
  },
  {
    icon: (
      <InsightsOutlined
        className="text-primary"
        sx={{ width: "55px", height: "55px", marginBottom: "8px" }}
      />
    ),
    title: "ATS-Friendly:",
    des: " Our resume templates are designed to be easily read by Applicant Tracking Systems (ATS), so your resume won't be automatically rejected because of formatting issues.",
  },
  {
    icon: (
      <BoltOutlined
        className="text-primary"
        sx={{ width: "55px", height: "55px", marginBottom: "8px" }}
      />
    ),
    title: "Extensive AI Power:",
    des: "Our AI has been trained on millions of successful resumes and has the combined knowledge of more than 40 HR experts.",
  },
  {
    icon: (
      <HistoryToggleOffOutlined
        className="text-primary"
        sx={{ width: "55px", height: "55px", marginBottom: "8px" }}
      />
    ),
    title: "Real Time Editing:",
    des: "As you make changes to your resume with our builder, you'll be able to see the updates applied in real time.",
  },
];

const Section3 = ({ setCurrentStep }) => (
  <div className="layout py-20">
    <p className="text-center text-4xl font-semibold mb-2 text-gray-700 lg:text-3xl mb:text-[21px]">
      How does it work?
    </p>
    <p className="text-center text-gray-700 text-lg lg:text-base mb:text-sm">
      Did you know, only 1% of resumes make it past the first round. Craft a
      professional <br className="mb:hidden" /> resume that catches immediate
      attention of recruiters.
    </p>
    <div className="flex justify-center space-x-20 mt-10 lg:space-x-10 sm:space-x-0 sm:flex-wrap sm:gap-4">
      {dataSection3.map(({ id, title, des }) => (
        <div
          key={id}
          className="flex flex-col items-center max-w-[370px] space-y-4 mb:space-y-1"
        >
          <p className="text-center bg-primaryLight text-primary text-4xl w-[70px] h-[70px] flex flex-col justify-center rounded-full">
            {id}
          </p>
          <p className="text-center text-primary text-xl font-semibold mb:text-base">
            {title}
          </p>
          <p className="text-center text-gray-600 mb:text-sm">{des}</p>
        </div>
      ))}
    </div>
    <button
      className="text-white bg-primary rounded-3xl mx-auto py-2 px-6 w-fit flex mt-16"
      onClick={() => setCurrentStep(3)}
    >
      Create Resume Now
    </button>
  </div>
);

const dataSection3 = [
  {
    id: 1,
    title: "Add your details",
    des: 'Go to "My Profile" and add different sections you want to highlight in your resume like your work ex, education, projects, internships, and more.',
  },
  {
    id: 2,
    title: "Choose a template",
    des: 'Click on "Preview Resume" and choose from our ATS-friendly, hand-crafted resume templates. Change templates and choose the one which works.',
  },
  {
    id: 3,
    title: "Download your resume",
    des: "Yes, it’s that easy! Simply download your resume in a PDF format and send out to recruiters! And yes, it's free!",
  },
];

const Section4 = () => (
  <div className="py-20 bg-white layout">
    <p className="text-center text-4xl text-gray-700 font-semibold mb-6 mb:text-xl">
      FAQs
    </p>
    <div className="max-w-[900px] mx-auto border shadow-lg rounded">
      {faqData.map((item,index) => (
        <Accordion key={index}>
          <AccordionSummary sx={{ height: "70px" }}>
            <p className="font-semibold mb:text-sm">{item.ques}</p>
          </AccordionSummary>
          <AccordionDetails>
            <p className="mb:text-sm">{item.ans}</p>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  </div>
);

const faqData = [
  {
    ques: "Do I need a different resume for every different job application",
    ans: "Adapting your resume to the job description each time you submit a job application is crucial. Even though it might not need to be completely redone, you should still make some changes. You may want to make a new resume that differs in content and format if you're applying for a different position. Use our resume builder to make a variety of resumes using the many templates available that best represent you while also fitting the various job applications you're submitting.",
  },
  {
    ques: "How do I determine which resume template is the best fit for me?",
    ans: "When selecting a resume template, it ultimately comes down to what you personally like. Of course, if you're applying for a job in finance, you probably don't want to use a template that's too creative or abstract like a graphic designer might use. As you go through all the different templates, keep in mind what the hiring manager might expect to see. Choose a template that showcases both your personality and aligns with your career aspirations.",
  },
  {
    ques: "Free resume builder doesn't usually mean free. Is Maangler's Resume builder really free?",
    ans: "It is, indeed! Use of our resume generator is totally free. You won't be charged anything for creating an account, uploading your resume, downloading it, or printing it.",
  },
  {
    ques: "What does ATS-friendly mean?",
    ans: "ATS-Friendly refers to a resume format that is optimized for applicant tracking systems (ATS). These systems are used by many companies to scan resumes for keywords and other relevant information during the hiring process. An ATS-friendly resume is formatted in a way that allows the system to easily extract the information it needs, increasing the chances of your resume making it through to a human recruiter. If you're applying for jobs in India, it's a good idea to ensure your resume is ATS-friendly to maximize your chances of getting hired.",
  },
];

export default ResumeBuilderIntro;
