import React from "react";
import heronamebg from "../../static/Hiring/HeroNameBG.png";
import heroCardBg from "../../static/Images/heroCardBg.png";
import HeroBg from "../../static/Hiring/HeroBg.png";
import { Slide } from "@mui/material";
import { Link } from "react-router-dom";
import HiringHeroRight from "./HiriingHeroRight";

const HeroSection = () => {
  return (
    <div className="flex justify-between py-20 items-center relative  sm:flex-col sm:space-y-4 overflow-hidden px-[8%] xl:px-[3%] lg:px-[2%] md:px-[1%]">
      <div className="space-y-10 mb-6 sm:flex sm:flex-col sm:items-center">
        <p className="text-4xl font-bold lg:text-3xl mb:text-2xl">
          Hire Top 2%
          <span
            className="px-2"
            style={{
              backgroundImage: `url(${heronamebg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          >
            Tech Talent
          </span>
          <br /> with Zero Backouts
        </p>
        <p className="font-semibold mb:text-center">
          We curate the top 2% of developers, product managers & data <br />
          scientists. No subscription costs - Pay only if you hire.
        </p>
        <a
          href="https://calendly.com/ritik-gupta/30min"
          target="_blank"
          rel="noreferrer"
        >
          <button className="bg-primary text-white font-semibold px-14 py-3 rounded-3xl hover:bg-[#0288b0] mt-10 hover:shadow-primary hover:shadow">
            Start Hiring
          </button>
        </a>
      </div>

      <div className="z-10 relative">
        {/* <BookCard people={people} threeLines={threeLines} type="noCross" /> */}
        <HiringHeroRight />
      </div>

      <div className="absolute right-[5%] top-20 sm:hidden xl:right-[-2%] md:hidden">
        <img src={heroCardBg} alt="" />
      </div>
      <div className="absolute left-[40%]">
        <img src={HeroBg} alt="" />
      </div>
    </div>
  );
};

export default HeroSection;
