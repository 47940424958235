import { CircularProgress, Dialog } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Thankyou from "../../static/Thankyou.jpg";
import { BASE_URL } from "../../utils/Constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../global/CustomInput";
import { Link } from "react-router-dom";
import india from "./india.png";

const BookCard = ({ people, threeLines, type = "cross", onClose }) => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        phone: "",
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phone: Yup.string()
          .matches(phoneRegex, "Invalid phone number")
          .required("Phone is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
      }),
      onSubmit: (values) => {
        submitHandler(values);
        resetForm();
      },
    });

  const submitHandler = async (values) => {
    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/consultation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      setLoading(false);
      setOpenDialog(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mb:px-3">
      <div
        className={clsx(
          type === "cross" ? "" : "border rounded-3xl",
          "bg-white   shadow-lg  flex flex-col items-center space-y-6 py-7 px-10 w-[460px] z-10 lg:w-[390px] lg:px-5 md:w-[340px] sm:w-[400px] mb:w-full "
        )}
      >
        <div className=" flex items-center justify-center w-[300px]  md:w-full">
          <p className="text-2xl font-semibold items-center text-center mb:text-xl">
            Claim your free one-on-one consultation now!
          </p>
          {/* <img
            src={threeLines}
            alt="threeLines"
            className="w-8 absolute right-0 bottom-2 "
          /> */}
        </div>
        <div className="w-full space-y-4">
          <div>
            <CustomInput
              className="py-[14px] rounded-2xl"
              placeholder={"Enter Name"}
              name={"name"}
              error={errors.name}
              value={values.name}
              touched={touched.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <CustomInput
              className="py-[14px] rounded-2xl"
              placeholder={"Enter Email"}
              name={"email"}
              error={errors.email}
              value={values.email}
              touched={touched.email}
              onChange={handleChange}
            />
          </div>
          <div>
            {/* <CustomInput
              className="py-[14px] rounded-2xl"
              placeholder={"Enter Phone number"}
              name={"phone"}
              error={errors.phone}
              value={values.phone}
              touched={touched.phone}
              onChange={handleChange}
            /> */}
            <div className="flex items-center space-x-2">
              {/* <p className="text-gray-500 text-sm absolute left-2  top-[14px]">
                🇮🇳
              </p> */}
              <div className="flex items-center border space-x-2 w-[75px] px-2 py-[12px] mb-0 rounded-xl bg-gray-200">
                <img alt="Icon by Freepik" src={india} className="w-4 " />
                <p className="text-gray-500 text-sm">+91</p>
              </div>
              <div className="w-full">
                <input
                  className={`w-full px-4 rounded-2xl py-[14px] text-sm  bg-gray-200 focus:outline-primary `}
                  type={type}
                  value={values.phone}
                  onChange={handleChange}
                  name={"phone"}
                  placeholder={"Enter Phone number"}
                />
                {errors.phone && touched && (
                  <p className="text-xs ml-4 text-red-500 absolute">
                    * {errors.phone}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex space-x-2">
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-primary px-3 text-sm  py-3 text-white rounded-3xl hover:bg-primaryDark lg:text-sm hover:shadow-primary hover:shadow "
            >
              {loading ? (
                <CircularProgress
                  size={20}
                  sx={{ color: "white", margin: "auto" }}
                />
              ) : (
                <p>Continue Booking Live Class</p>
              )}
            </button>
            <div className="flex items-center space-x-2 text-sm">
              <img src={people} alt="people" className="w-5" />
              <p className="text-red-400 font-semibold lg:text-sm">
                Limited seats left
              </p>
            </div>
          </div>
          <p className="mt-2 ml-2 text-center text-sm">
            Already have an account ?{" "}
            <a href="https://lms.maangler.in/">
              <span className="text-primary">Click here</span>
            </a>{" "}
          </p>
        </div>

        <div>
          <p className="text-gray-500 text-center lg:text-sm">
            By continuing you agree to Maangler's
          </p>
          <p className="text-primary text-center lg:text-sm">
            <Link to={"/terms-of-use"}> Terms of service</Link>{" "}
            <span className="text-black">and</span>{" "}
            <Link to={"/privacy-policy"}>Privacy Policy</Link>
          </p>
        </div>
        {/* {type === "cross" && (
          <button
            className="absolute text-xl -top-[4%] right-2"
            onClick={onClose}
          >
            <CancelIcon />
          </button>
        )} */}
        {/* <img alt="" src={heroCardBg} className="absolute -right-20 -z-20 "/> */}
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <button
          className="ml-auto"
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          <CancelIcon />
        </button>
        <img
          src={Thankyou}
          alt="Image by storyset on Freepik"
          className="w-[350px]"
        />
        <p className="text-center pb-6 pt-4 text-sm">
          Thanks for applying for
          <span className="font-semibold">Super30 Batch</span> <br /> You will
          get a callback shortly
        </p>
      </Dialog>
    </div>
  );
};

const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
const regexNumber = /^[0-9\b]+$/;

export default BookCard;
