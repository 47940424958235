import React from "react";
import CustomSelect from "../CustomSelect";
import CustomInput from "../CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  branchOptions,
  degreeOptions,
  graduationYearOptions,
  yearsOfExperienceOptions,
} from "../../utils/staticData";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../../redux/profile/profileSlice";
import { putUserProfile } from "../../redux/auth/apiSlice";

const AcademicProfile = () => {
  const data = useSelector(selectUserData);
  const academicData = data?.academic;
  const dispatch = useDispatch();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        graduationYear: academicData?.graduationYear,
        experienceYears: academicData?.experienceYears,
        collegeUniversity: academicData?.collegeUniversity,
        degree: academicData?.degree,
        branch: academicData?.branch,
        gpa: academicData?.gpa,
      },
      validationSchema: Yup.object().shape({
        graduationYear: Yup.string().required("Graduation year is required"),
        experienceYears: Yup.string().required(
          "Year of experience is required"
        ),
        collegeUniversity: Yup.string().required("This field is required"),
        degree: Yup.string().required("This field is required"),
        branch: Yup.string().required("This field is required"),
        gpa: Yup.string().required("This field is required"),
      }),
      onSubmit: (values) => {
        dispatch(putUserProfile({ ...data, academic: values }));
      },
    });
  return (
    <div className="h-full flex flex-col">
      <div className="space-y-3 grow">
        {feilds.map((item) =>
          item.type === "select" ? (
            <div>
              <p className="ml-1 mb-1 font-semibold">
                {item.label} <span className="text-red-500">*</span>
              </p>
              <CustomSelect
                name={item.name}
                setFieldValue={setFieldValue}
                options={item?.options}
                error={errors[item.name]}
                touched={touched[item.name]}
                defaultValue={{
                  label: values[item.name],
                  value: values[item.name],
                }}
              />
            </div>
          ) : (
            <div>
              <p className="ml-1 mb-1 font-semibold">{item.label}</p>
              <CustomInput
                name={item.name}
                error={errors[item.name]}
                onChange={handleChange}
                touched={touched[item.name]}
                value={values[item.name]}
              />
            </div>
          )
        )}
      </div>
      <button
        className="bg-primary w-full text-white py-3 rounded-3xl mt-10"
        onClick={handleSubmit}
        type="submit"
      >
        Update Academic Profile
      </button>
    </div>
  );
};

const feilds = [
  {
    type: "select",
    name: "graduationYear",
    label: "Grad Year",
    options: graduationYearOptions,
  },
  {
    type: "select",
    name: "experienceYears",
    label: "Years of Experience",
    options: yearsOfExperienceOptions,
  },
  { type: "text", name: "collegeUniversity", label: "College/University" },
  { type: "select", name: "degree", label: "Degree", options: degreeOptions },
  { type: "select", name: "branch", label: "Branch", options: branchOptions },
  { type: "text", name: "gpa", label: "GPA" },
];

export default AcademicProfile;
