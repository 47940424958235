import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { useDispatch } from "react-redux";
import { socialLogin } from "../../redux/auth/apiSlice";
import Google from "./Google.png";

const ContinueWithGoogle = () => {
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      dispatch(socialLogin({ access_token: codeResponse?.access_token }));
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <div className="flex space-x-3 mt-2 mb:flex-col mb:space-x-0 mb:space-y-2">
      <div
        className="border rounded-3xl border-[#232323] flex items-center space-x-2 py-3 px-3 text-sm cursor-pointer mx-auto"
        onClick={() => login()}
      >
        <img src={Google} alt="" className="w-5" />
        <p>Continue with google</p>
      </div>
    </div>
  );
};

export default ContinueWithGoogle;
