import React from "react";
import chat from "../../static/Icons/chat.png";
import Manas from "../../static/Images/Manas.jpg";
import Pragya from "../../static/Images/Pragya.png";
import Bhargavi from "../../static/Images/Bharghavi.jpg";
import TestimonialCard from './TestimonialCard';
import Tarannum from '../../static/Images/Tarannum.jpeg'
import sairaj from '../../static/Images/sairaj.jpeg'
import priti from '../../static/Images/priti.jpeg'
import defa from '../../static/Images/Default.png'
import tanzeel from '../../static/Images/tanzeel.jpeg'

const Testimonials = () => {
  return (
    <div className="min-w-screen flex min-h-screen items-center justify-center bg-gray-50 py-5">
      <div className="w-full border-b border-t border-gray-200 bg-white px-5 py-16 text-gray-800 min-[768px]:py-24">
        <div className="flex items-center justify-center mb:flex-col mb:mb-6 mr-[100px] mb:mr-1">
          <div className="">
            <img src={chat} alt="" className="sm:w-[140px] mb:w-[120px]" />
          </div>
          <div>
            <p className="text-4xl font-semibold md:text-3xl sm:text-2xl mb:text-2xl mb:text-center">
              Testimonials
            </p>
            <p className="text-lg text-gray-500 font-semibold md:text-base mb:text-sm mb:text-center">
              What People Are Saying
            </p>
          </div>
          
        </div>
        <div className="mx-auto w-full max-w-6xl">
          <div className="-mx-3 items-start [@media(min-width:768px)]:flex">
            <div className="px-3 [@media(min-width:768px)]:w-1/3 ">
              <TestimonialCard
                profile={Tarannum}
                name="Tarannum Khan"
                review="I'm thrilled to announce that I've successfully completed the prestigious Super 30 Batch in Java Training, under the mentorship of the incredible Jainil Vachhani Sir and with the invaluable guidance of Rishita Arora maa'm . 🎓
                Jainil Vachhani Sir, your mentorship has been a beacon of inspiration, and Rishita Arora maa'm , your guidance has been invaluable. I owe a great deal of this achievement to you both."
              />
              <TestimonialCard
                profile={sairaj}
                name="Sairaj Todkar"
                review="Today marks the beginning of my learning , skill-building and working on live project journey with Maangler , amez to be part prestigious Super 30 group! 🌟
                I'm thrilled to embark on this new adventure, where I'll be honing my skills and pushing my limits to reach new heights. 💪"
              />
              
            </div>
            <div className="px-3 [@media(min-width:768px)]:w-1/3">
              <TestimonialCard
                profile={priti}
                name="Priti Singh"
                review="I'm excited to announce that I've earned a new certification from Kaggle in data visualization! I appreciate Maangler providing the excellent platform. Thank you as well for Muskan Handa Mam's excellent instruction.  "
              />
              <TestimonialCard
                profile={defa}
                name="Taanisha Sharma"
                review="I'm truly honored to share that I've recently received a new letter of recommendation from Maangler, which has left me both humbled and motivated.
                I'm immensely grateful for this endorsement and eager to channel this newfound motivation into my work.
                "
              />
              
            </div>
            <div className="px-3 [@media(min-width:768px)]:w-1/3">
              <TestimonialCard
                profile={tanzeel}
                name="Ahamad Tanzeel Khan"
                review="Maangler has been making waves in the industry with its innovative approach to develop students on the path to becoming adept problem solvers, ready for a digital economy that thrives on critical and technical skills.
                I want to express my sincere gratitude to the Maangler team for seeing potential in me and offering me this incredible opportunity. I'm excited to learn, grow, and contribute my best to the team's success."
              />
              <TestimonialCard
                profile={defa}
                name="Dipan Mondal"
                review="Grateful for the wonderful learning experience at Maangler 🚀. Their letter of recommendation reflects the incredible journey and skills I've gained. Excited to continue growing and contributing to the data science community! 🙌"
              />
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
