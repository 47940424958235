import Cookies from "js-cookie";

export const isLoggedIn = () => {
  const access_token = Cookies.get("access_token");
  if (access_token) {
    const isTokenExpired = new Date(access_token.expiry) < new Date();
    if (isTokenExpired) {
      console.log("Token expired .....");
    } else {
      console.log("Token not expired .....");
      return true;
    }
  } else {
    return false;
  }
};
