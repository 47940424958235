import React from "react";

const ProfileDrawerLeftPanel = ({
  drawerOptions,
  currentTab,
  setCurrentTab,
}) => {
  return (
    <div className="bg-white w-fit h-fit px-6 py-4 space-y-3 text-gray-600 border rounded-lg mb:text-sm mb:w-full">
      {drawerOptions.map((item) => (
        <div
          key={item.value}
          className="cursor-pointer"
          onClick={() => setCurrentTab(item.value)}
        >
          <p
            className={
              currentTab === item.value
                ? "text-black font-semibold border-b-[3px] pb-1 w-fit border-b-primary "
                : "hover:text-gray-800 hover:font-semibold"
            }
          >
            {item.label}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ProfileDrawerLeftPanel;
