import { CircularProgress, Drawer } from "@mui/material";
import React, { useState } from "react";
import ProfileDrawerLeftPanel from "./ProfileDrawerLeftPanel";
import AccountDetailsSection from "./AccountDetailsSection";
import ProfileDrawerRightPanel from "./ProfileDrawerRightPanel";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { selectStatusProfile } from "../../redux/profile/profileSlice";
import { STATUS_CODES } from "../../utils/Constants";

const ProfileDrawer = ({ openProfileDrawer, setOpenProfileDrawer }) => {
  const status = useSelector(selectStatusProfile);
  const [currentTab, setCurrentTab] = useState("account_details");
  return (
    <>
      <Drawer
        open={openProfileDrawer}
        anchor="right"
        onClose={() => setOpenProfileDrawer(false)}
        sx={{zIndex:99999}}
      >
        {
          <div className="bg-[#fafafa] ">
            <div className="w-[800px]  min-h-screen  py-6 px-8 flex space-x-6 md:w-[90vw] md:flex-col md:space-x-0 md:space-y-4 mb:px-3 ">
              
              {(status === "" || status === STATUS_CODES.PENDING) && (
                <div className="flex justify-center items-center grow">
                <CircularProgress />
                </div>
              )}
              {status === STATUS_CODES.REJECTED && (
                <p>Opps Something Went wrong </p>
              )}
              {status === STATUS_CODES.FULFILLED && (
                <>
                  <ProfileDrawerLeftPanel
                    drawerOptions={drawerOptions}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                  />
                  <ProfileDrawerRightPanel currentTab={currentTab} />
                </>
              )}
            </div>
          </div>
        }
      </Drawer>
    </>
  );
};

const drawerOptions = [
  { label: "Account Details", value: "account_details" },
  { label: "Academic Profile", value: "academic_profile" },
  { label: "Job Profile", value: "job_profile" },
  {
    label: "Social Preferences & Contributions",
    value: "social_preferences_contributions",
  },
  // { label: "Change Email", value: "change_email" },
  // { label: "Update Phone Number", value: "update_phone_number" },
  // { label: "Change Password", value: "change_password" },
];

export default ProfileDrawer;
