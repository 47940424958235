import React from "react";
import duties from "./Duties.png";

const DutiesSection = () => {
  return (
    <div className="layout pb-20">
      <p className="text-3xl font-semibold text-center">
        Your duties are easier than you think! <br /> We'll help you every step
        of the way 🤗
      </p>
      <div
        style={{
          backgroundImage: `url(${duties})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition:'center'
        }}
        className="mt-16 py-20"
      >
        <div className="grid grid-cols-2 w-fit mx-auto gap-[200px] md:gap-[50px] sm:flex-col sm:flex">
          {cardDetails.map(({ id, title, des }) => (
            <div key={id} className="max-w-[400px] border bg-white shadow-lg p-6 rounded-lg space-y-2">
              {<p className="font-semibold">{title}</p>}
              {<p className="text-sm">{des}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const cardDetails = [
  {
    id: 1,
    title: "Conduct interview simulations to help your mentee build confidence",
    des: "Help mentees beat the stress of a closed 1:1 interview setup by conducting simulations. Help build confidence and precision in the mentees.",
  },
  {
    id: 2,
    title: "Guide young professionals in their learning journey",
    des: "Understand mentees background and aspirations. Using that guide them on the best course of action to achieve their goals",
  },
  {
    id: 3,
    title: "Guide your mentees to make the best career decisions",
    des: "Help your mentees evaluate opportunities, organizations and roles to make the right career decisions that will help them grow to their full potential.",
  },
  {
    id: 4,
    title:
      "Help your mentees evaluate their skills and suggest upskilling tracks",
    des: "Based on the mentees weakness/area of improvement provide suggestions on how can they overcome these hurdles and perform better",
  },
];

export default DutiesSection;
