import React from "react";
import HeroMentor from "../../static/BecomeMentor/HeroMentor.png";
import heronamebg from "../../static/Images/heronamebg.png";
import path from "../../mocks/path.png";
import heroCardBg from "../../static/Images/heroCardBg.png";
import heroBg from "../../static/Images/heroBg.png";
import Arrow1 from "../../static/BecomeMentor/Arrow1.png";
import Arrow2 from "../../static/BecomeMentor/Arrow2.png";
import Arrow3 from "../../static/BecomeMentor/Arrow3.png";

const HeroSection = () => {
  return (
    <div className="layout">
      <div className="flex justify-between py-20 items-center relative bg-[#fcfcfc] md:flex-col md:space-y-4 overflow-hidden">
        <div className="z-10 relative max-w-[500px]">
          <img
            alt=""
            src={HeroMentor}
            className="max-w-[450px] xl:max-w-[430px] lg:max-w-[380px] md:max-w-[400px] sm:max-w-[340px]"
          />
          <Card
            icon={Arrow1}
            className={"mb:w-[200px] absolute top-0 right-0 mb:right-4"}
            text={"Free access to exclusive content & Masterclasses"}
          />
          <Card
            icon={Arrow3}
            className={"mb:w-[220px] absolute bottom-[20%] left-0"}
            text={"Build a network with like minded people"}
          />
          <Card
            icon={Arrow2}
            className={
              "mb:w-[150px] absolute bottom-[40%] -right-40 lg:-right-32 md:right-4"
            }
            text={"Earn upto ₹40k per month"}
          />
          {/* <BookCard people={people} threeLines={threeLines} type="noCross" /> */}
        </div>
        <div className="space-y-10 mb-6 mb:space-y-6 sm:flex sm:flex-col sm:items-center">
          <p className="text-5xl font-bold xl:text-4xl mb:text-3xl animate-pop-in-down leafBox-2">
            Become a{" "}
            <span
              className="px-6"
              style={{
                backgroundImage: `url(${path})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            >
              Mentor
            </span>
            <br /> make a difference
          </p>
          <p className="font-semibold mb:text-center animate-pop-in-down leafBox-3">
            Join the community. Gain credibility.{" "}
            <br className="hidden mb:block" /> Get paid.
          </p>
          <div className="flex items-start space-x-4 mb:space-x-0">
            <a
              href="https://calendly.com/ritik-gupta/30min"
              className="border-primary border-2 hover:text-white animate-pop-in-down leafBox-4 text-primary font-semibold px-14 py-3 rounded-3xl hover:bg-[#0288b0] mt-10 hover:shadow-primary hover:shadow mb:mt-0"
            >
              Apply Now
            </a>
            <div className="mb:hidden">
              <img src={heroBg} alt="" className="w-[150px]" />
            </div>
          </div>
        </div>

        <div className="absolute right-[-4%] top-20 sm:hidden xl:right-[-2%] md:hidden">
          {/* <img src={heroCardBg} alt="" /> */}
        </div>
        {/* <div className="absolute left-[40%]">
          <img src={heroBg} alt="" />
        </div> */}
      </div>
    </div>
  );
};

const Card = ({ text, icon, className }) => (
  <div
    className={`w-[280px] border bg-white p-4 rounded-lg flex items-center space-x-5 ${className} shadow lg:w-[250px] lg:p-2 lg:text-sm mb:p-1 mb:text-xs`}
  >
    <img src={icon} alt="" className="w-[40px] mb:w-[25px]" />
    <p>{text}</p>
  </div>
);
export default HeroSection;
