import React, { useState, useEffect } from "react";
import {
  Fade,
  Box,
  Button,
  Typography,
  Grid,
  Link,
  Card,
  CardContent,
} from "@mui/material";
import metaNew from "../../static/Icons/metaNew.png";
import NVIVIA from "../../static/Icons/NVIVIA.png";
import netflixNew from "../../static/Icons/netflixNew.png";
import trainers from "../../static/Images/trainers.png";
import blazeclan from "../../static/Icons/blazeclan.png";
import microsoftNew from "../../static/Icons/microsoftNew.png";
import shell from "../../static/Icons/shell.png";
import morgun from "../../static/Icons/morgun.png";
import multiplier from "../../static/Icons/multiplier.png";
import peopleGrove from "../../static/Icons/peopleGrove.png";
import googleNew from "../../static/Icons/googleNew.png";
import exponential from "../../static/Icons/exponential.png";
import appleNew from "../../static/Icons/appleNew.png";
import amazonNew from "../../static/Icons/amazonNew.png";
import Hero1 from "../../static/Python/Hero1.png";
import Hero2 from "../../static/Python/Hero2.png";
import Hero3 from "../../static/Python/Hero3.png";
import Hero4 from "../../static/Python/Hero4.png";
import WhiteMaangler from "../../static/Icons/WhiteMaangler.png";
import zIndex from "@mui/material/styles/zIndex";
import "./Courses.css";
import Roshan from "../../static/Courses/Roshan.jpg";
import Alka from "../../static/Courses/Alka.jpg";
import Vishal from "../../static/Courses/Vishal.jpg";
import Soumya from "../../static/Courses/Somya.jpg";
import Priti from "../../static/Courses/Priti.jpg";
import ApplyNowModal from "./CommonApplyNowBtn";
import { selectIsLoggedIn, setLoggedIn } from "../../redux/auth/authSlice";
import { isLoggedIn } from "../../utils/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { getProfileData } from "../../redux/auth/apiSlice.js";

const HeroSection = ({
  title,
  courseDescription,
  courseName,
  CourseDuration,
  ButtonColor,
  InstructorBgColor,
  instrctorNameBgColor,
  applicationDeadLine,
  broachrLink,
}) => {
  const [show, setShow] = useState(false); // Correct usage of useState

  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit timeout={1200}>
        <div className="flex flex-col justify-between items-center px-[10%] xl:px-[3%] lg:px-[2%] md:px-[1%] relative pt-4 pb-20 font-sans z-20 sm:flex-col sm:px-[3%] md:text-sm">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={8}
          >
            {/* <Button
              variant="contained"
              className="test"
              sx={{
                background: ButtonColor,
                color: "#000",
                padding: "10px 20px",
                fontWeight: "bold",
                textTransform: "none",
                width: "500px",
                position: "fixed",
              }}
              onClick={() => {
                window.open(broachrLink, "_blank"); // Redirect to the brochure link
              }}
            >
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                Download Brochure
              </p>
            </Button> */}
          </Box>

          {/* Apply Button */}
          <Box
            sx={{
              textAlign: "center",
              marginTop: "20px",
              display: { xs: "block", sm: "none" },
            }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                background: ButtonColor,
                color: "#000",
                padding: "18px 40px",
                borderRadius: "10px",
                width: "80%", // Adjust the width for a balanced look in mobile view
                fontWeight: "bold",
                textTransform: "none",
                zIndex: 1000,
                position: "fixed",
                bottom: "20px", // Stick to the bottom of the viewport with a margin
                left: "50%", // Center horizontally
                transform: "translateX(-50%)", // Adjust centering because `left: 50%` centers the left side of the button, so we need to translate it back by 50% of its own width
                display: { xs: "block", sm: "none" }, // Show only on mobile (extra small screens)
              }}
              onClick={() => setShow(true)}
            >
              APPLY NOW
            </Button>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <img alt="logo" style={{ width: "150px" }} src={WhiteMaangler} />

              <Typography
                variant="h4"
                color="white"
                fontWeight="bold"
                gutterBottom
                sx={{ fontSize: "28px" }}
              >
                Master {courseName} Programming and Become Industry Ready in
                Just {CourseDuration} Weeks!
              </Typography>
              <p
                style={{
                  fontSize: "15px",
                  color: "#CECFD2",
                  fontFamily: "sans-serif",
                  marginTop: "20px",
                }}
              >
                Your roadmap to building a global career in Python <br />
                programming starts here! Learn relevant skills hands-on <br />
                and create a portfolio from scratch with our intensive <br />
                {CourseDuration}-week program.
              </p>
              <Button
                variant="contained"
                size="large"
                sx={{
                  background: ButtonColor,
                  color: "#000",
                  mt: 3,
                  padding: "18px 40px",
                  width: "420px",
                  fontWeight: "bold",
                  textTransform: "none",
                  borderRadius: "10px",
                  display: { xs: "none", sm: "block" },
                }}
                onClick={() => setShow(true)}
              >
                <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Apply Now
                </p>
              </Button>
              {/* <Link
                href="#"
                underline="hover"
                color="white"
                display="block"
                marginLeft={11}
                mt={2}
                sx={{
                  display: { xs: "none", sm: "block" }, // Hide on mobile, show on tablet and above
                }}
              >
                click here to get team discount
              </Link> */}
              <Typography
                variant="body2"
                color="gray"
                mt={1}
                ml={4}
                sx={{
                  display: { xs: "none", sm: "block" }, // Hide on mobile, show on tablet and above
                }}
              >
                In the last 10 hours, 6 learners have onboarded with us.
              </Typography>
            </Grid>

            {/* Image/Instructor Section */}
            <Grid item xs={12} md={5} className="test">
              <Card
                sx={{
                  background: InstructorBgColor,
                  color: "white",
                  padding: "20px",
                  borderRadius: "16px",
                }}
              >
                <CardContent>
                  {/* Instructor Images and Logos */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    {/* Placeholder for Instructor Image */}
                    <img
                      src={metaNew}
                      alt="Instructor 1"
                      width="50px"
                      height="50px"
                      // marginRight="10px"
                    />
                    <img
                      src={googleNew}
                      alt="Instructor 2"
                      width="55px"
                      height="50px"
                    />
                    <img
                      src={amazonNew}
                      alt="Instructor 3"
                      width="55px"
                      height="50px"
                    />
                    <img
                      src={netflixNew}
                      alt="Instructor 4"
                      width="55px"
                      height="50px"
                    />
                    <img
                      src={appleNew}
                      alt="Instructor 4"
                      width="50px"
                      height="50px"
                    />
                  </Box>
                </CardContent>
                <img
                  src={trainers}
                  alt="Instructor 4"
                  width="450px"
                  height="70px"
                />
              </Card>
              <Card
                sx={{
                  background: instrctorNameBgColor,
                  color: "white",
                  padding: "20px",
                  marginTop: "30px",
                  border: "1px solid #71613f",
                  borderRadius: "12px",
                }}
              >
                <CardContent>
                  {/* Instructor Images and Logos */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Box
                      className="DesignedByCSS"
                      sx={{
                        background: InstructorBgColor,
                        display: { xs: "block", md: "block" },
                        marginBottom: { xs: "60px", md: "0px" },
                      }}
                    >
                      <b>Designed by</b>
                    </Box>
                  </Box>

                  {/* <Box
                      className=""
                      sx={{
                        background: InstructorBgColor,
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      <b>Designed by</b>
                    </Box> */}

                  <Typography
                    variant="h5"
                    color="white"
                    fontWeight="bold"
                    textAlign="center"
                    sx={{
                      fontSize: { xs: "16px", md: "18px" }, // Smaller on mobile (xs), larger on desktop (md)
                      display: { xs: "inline", md: "block" }, // Inline on mobile, block on larger screens
                      textAlign: "center",
                    }}
                  >
                    Gaurav Jha and Muskan Handa
                  </Typography>
                  <Typography variant="body2" color="white" gutterBottom>
                    <Box className="designed-by-logos">
                      {/* Placeholder for Instructor Image */}
                      <img
                        src={googleNew}
                        alt="Instructor 1"
                        width="50px"
                        height="50px"
                      />
                      <img
                        src={microsoftNew}
                        alt="Instructor 2"
                        width="50px"
                        height="50px"
                      />
                      <img
                        src={amazonNew}
                        alt="Instructor 3"
                        width="50px"
                        height="50px"
                      />
                      <img
                        src={netflixNew}
                        alt="Instructor 4"
                        width="50px"
                        height="50px"
                      />
                      <img
                        src={appleNew}
                        alt="Instructor 5"
                        width="60px"
                        height="50px"
                      />
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Box
            sx={{
              backgroundColor: "black",
              textAlign: "center",
            }}
          >
            {/* company logo */}
            <Typography variant="h6" sx={{ color: "#CECFD2", mb: 3, mt: 3 }}>
              Learn {courseName} from mentors who have worked with brands like
            </Typography>
            <Box className="CompayNamesMain">
              {companies.map((company, index) => (
                <Box key={index} className="companyNames">
                  <img
                    src={company.logo}
                    alt={company.name}
                    style={{ maxHeight: "40px", maxWidth: "70%" }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          <br />
          <br />
          <br />
          <Button
            variant="contained"
            size="large"
            sx={{
              background: applicationDeadLine,
              // background: "linear-gradient(90deg, #F9F2D0 0%, #ECA877 100%)",
              // background:
              //   "linear-gradient(91.74deg, rgba(142, 139, 77, 0.8) 16.87%, rgba(35, 107, 122, 0.8) 93.38%)",
              color: "#000",
              mt: 3,
              padding: "18px 40px",
              marginTop: "150px",
              borderRadius: "30px",
              width: "800px",
              fontWeight: "bold",
              textTransform: "none",
              height: "120px",
            }}
          >
            <Box className="applicationBox">
              <Box textAlign="center">
                <Typography
                  variant="caption"
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  APPLICATION DEADLINE
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "24px", fontWeight: "bold" }}
                >
                  01 Nov, 2024
                </Typography>
              </Box>

              {/* <Box textAlign="center">
                <Typography
                  variant="caption"
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  COHORT START
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "24px", fontWeight: "bold" }}
                >
                  16 April, 2024
                </Typography>
              </Box> */}
            </Box>
          </Button>
          <br />
          <br />
          <br />
          <hr
            style={{
              color: "white",
              width: "200px",
              marginTop: "40px",
            }}
          />
        </div>
      </Fade>
      <ApplyNowModal show={show} setShow={setShow} />
    </>
  );
};

const data1 = [
  { name: "Industry Experts as Mentors", icon: Hero1 },
  { name: "Includes Certification", icon: Hero2 },
  { name: "Learn at Your Own Pace", icon: Hero3 },
  { name: "Unlimited Access Forever", icon: Hero4 },
];
const companies = [
  { name: "Meta", logo: metaNew },
  { name: "NVIDIA", logo: NVIVIA },
  { name: "BlazeClan", logo: blazeclan },
  { name: "Microsoft", logo: microsoftNew },
  { name: "Shell", logo: shell },
  { name: "Morgan Stanley", logo: morgun },
  { name: "Multiplier", logo: multiplier },
  { name: "PeopleGrove", logo: peopleGrove },
  { name: "Google", logo: googleNew },
  { name: "Exponentia.ai", logo: exponential },
  { name: "Apple", logo: appleNew },
];

const formData = [
  { id: 1, name: "name", label: "Candidate Full Name" },
  { id: 2, name: "email", label: "Email id " },
  { id: 3, name: "phone", label: "Phone Number" },
  { id: 4, name: "clgName", label: "College / Company Name" },
  { id: 5, name: "exp", label: "Year of graduation/Experience" },
  { id: 6, name: "companyName", label: "Dream Company" },
];

const data = [
  { name: "Roshan Kumar", rating: "4.3", icon: Roshan },
  { name: "Alka kashyap", rating: "4.2", icon: Alka },
  { name: "Priti Singh", rating: "4.5", icon: Priti },
  { name: "Vishal Reddy", rating: "4.4", icon: Vishal },
  { name: "Soumya BU", rating: "4.1", icon: Soumya },
];

export default HeroSection;
