import React from 'react'
import { Link } from "react-router-dom";
import { Error } from '@mui/icons-material';
import { pink } from '@mui/material/colors';

const Failure = () => {
  return (
    <div className="min-h-screen flex bg-slate-100 items-center justify-center text-center -mt-10">
      
      <div className="bg-white p-6  md:mx-auto rounded-xl">
      <div className='mx-auto my-6'>
        <Error sx={{ fontSize: 70, color:pink[500]  }} />
      </div>
        

        
        <div className="text-center">
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Payment Failed
          </h3>
          <p className="text-gray-600 my-2">
            Oops looks like there was some problem,<br/> please try again
          </p>
          <p> - Maangler </p>
          <Link to={"/"}>
            <div className="py-10 text-center">
              <button className="px-12 bg-primary hover:bg-[#0288b0] text-white font-semibold py-3 rounded-lg">
                Go Back
              </button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Failure