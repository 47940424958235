import React, { useState, useEffect } from "react";

function Timer() {
  // Set the initial time (5 hours, 59 minutes, 50 seconds)
  const [time, setTime] = useState(5 * 60 * 60 + 59 * 60 + 50);

  useEffect(() => {
    // Set an interval to decrement the time every second
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) return prevTime - 1;
        clearInterval(timer);
        return 0;
      });
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  // Format the time to display as HH:MM:SS
  const formatTime = () => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <div>
      <p className="font-semibold text-base">{formatTime()}</p>
    </div>
  );
}

export default Timer;
