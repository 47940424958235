import React from "react";
import computer from "../../static/Hiring/computer.png";
import Hat from "../../static/Hiring/Hat.png";
import calendar from "../../static/Hiring/callendar.png";
import mailbox from "../../static/Hiring/Mailbox.png";
import clsx from "clsx";
import leftSpring from "../../static/leftSpring.png";
import rightSpring from "../../static/rightSpring.png";

const TechTeamSection = () => {
  return (
    <div className="mt-20 relative">
      <p className="text-4xl font-bold lg:text-3xl mb:text-2xl text-center">
        The easiest way to build your <br /> Tech team
      </p>
      <div
        className="grid-cols-2 grid  gap-6 mt-10 md:grid-cols-1"
        
      >
        {data.map((item, index) => (
          <Card
            icon={item.icon}
            des={item.des}
            title={item.title}
            index={index}
            type={item.type}
            className={item.className}
          />
        ))}
      </div>
      <img src={leftSpring} alt="" className="absolute left-0  md:hidden h-[300px] bottom-0" />
      <img src={rightSpring} alt="" className="absolute right-0 md:hidden h-[300px] top-0" />
    </div>
  );
};

const Card = ({ icon, title, des, index, type, className }) => {
  return (
    <div
      className={clsx(
        "border-[#9207FF] border w-[400px] space-y-2 px-4 py-2 rounded-xl bg-[#373046] h-[300px] mb:w-[330px]",
        index % 2 === 0
          ? "justify-self-end md:justify-self-center"
          : "justify-self-start md:justify-self-center",
          className
      )}
    >
      <img src={icon} className="w-[90px] object-contain" alt="" />
      {type === "blue" ? (
        <div className="w-[100px] h-[6px] bg-[#076AFF] rounded"></div>
      ) : (
        <div className="w-[100px] h-[6px] bg-[#9207FF] rounded"></div>
      )}

      <p className="text-lg font-semibold">{title}</p>
      <p className="">{des}</p>
    </div>
  );
};

const data = [
  {
    icon: computer,
    title: "Only pre-screened, high quality candidates",
    des: "We handpick candidates to ensure you’re only choosing from the top 2% of tech talent. You’re never overwhelmed by thousands of candidates.",
    type: "blue",
    className: "",
  },
  {
    icon: Hat,
    title: "Only candidates who are ready-to-interview",
    des: "We verify candidates are ready to interview before accepting them onto Maangler. No more reaching out to candidates who are no longer open to a move.",
    type: "purple",
    className: "",
  },
  {
    icon: calendar,
    title: "2-week batches",
    des: "Every two weeks we remove all existing candidates and replace them with a brand new batch - to ensure that you always see fresh candidates.",
    type: "blue",
    className: "",
  },
  {
    icon: calendar,
    title: "Learn what candidates want",
    des: "Traditional resumes and profiles don't tell you the full story. We help hiring managers understand what candidates really want in their next role.",
    type: "purple",
    className: "",
  },
  {
    icon: mailbox,
    title: "Easy outreach & guaranteed responses",
    des: "Send an interview request with just two clicks and get a guaranteed response within 72 hours.",
    type: "blue",
    className: "col-span-2 row-span-1 mx-auto ",
  },
];

export default TechTeamSection;
