import React from "react";
import meta from "../../static/Icons/meta.png";
import apple from "../../static/Icons/apple.png";
import amazon from "../../static/Icons/amazon.png";
import google from "../../static/Icons/google.png";
import netflix from "../../static/Icons/netflix.png";
import boy from "../../static/Icons/boy.png";
import laptop from "../../static/Icons/laptop.png";
import verticalArrows from "../../static/Icons/verticalArrows.png";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Grow, Zoom } from "@mui/material";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";

const MentorsStrip = ({ background = "#fcfcfc", stripBg = "#fff" }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  return (
    <ResponsiveWrapper>
      <div className="mt-16 flex flex-col items-center justify-center bg-[#fcfcfc] mb:px-2">
        <div className=" flex items-center mb:justify-center mb:flex-col mb:mb-4" ref={ref}>
          <img alt="" className="w-[130px] mb:w-[90px]" src={boy} />
          <p className="text-4xl font-semibold md:text-3xl mb:text-2xl mb:text-center">
            Our Mentors are from
          </p>
        </div>
        <Grow in={inView} timeout={1500}>
          <div className="flex justify-between space-x-10 border bg-white py-9 px-10 w-full rounded-3xl shadow-lg md:grid md:grid-cols-2 md:gap-3 mb:hidden">
            {imgData.map((item, index) => (
              <Image key={index} src={item} />
            ))}
          </div>
        </Grow>
        <div className="hidden mb:block bg-white py-9 px-10 border mx-10 w-full rounded-xl">
          <Splide
            options={{
              perPage: 1,
              gap: "5rem",
              width: "100%",
              rewind: true,
              arrows: false,
              autoplay: true,
              interval: 2600,
              padding: 0,
              mediaQuery: "max",
            }}
          >
            {imgData.map((item, index) => (
              <SplideSlide key={index}>
                <Image key={index} src={item} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
        <div className="flex space-x-6  mb:mt-8">
          <div className="flex items-center">
            <img alt="" src={verticalArrows}  className="mb:w-[40px]"/>
            <a href="/#mentors">
              <button className="border-2 border-primary px-16 py-3 text-primary rounded-3xl  md:px-10 sm:px-8 mb:px-4 mb:text-sm mb:py-2 transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white">
                Meet My Mentors
              </button>
            </a>
          </div>
          <div>
            <img alt="" src={laptop} className="mb:w-[85px]"/>
          </div>
        </div>
      </div>
    </ResponsiveWrapper>
  );
};

const Image = ({ src }) => (
  <img
    className="w-auto h-[35px] transition ease-in-out duration-500 hover:scale-110 cursor-pointer mb:h-[25px]"
    src={src}
    alt=""
    style={{ margin: "auto" }}
  />
);

const imgData = [meta, amazon, apple, netflix, google];

export default MentorsStrip;
