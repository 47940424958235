import React from "react";
import data from "../../static/Icons/data.png";
import teacher from "../../static/Icons/teacher.png";
import community from "../../static/Icons/community.png";
import clown from "../../static/Icons/clown.png";
import rightVerticalLines from "../../static/Icons/rightVerticalLines.png";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import plane from "../../static/Images/plane.png";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Grow } from "@mui/material";

const SkillsInfoSection = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  return (
    <ResponsiveWrapper className={"relative"}>
      <div className="mt-16">
        <p className="text-center text-4xl font-semibold mb-16 z-10 xl:text-3xl mb:text-2xl mb:mb-6">
          The Maanglers recipe to nurture you <br className="block mb:hidden" />{" "}
          with Industry relevant <br className="hidden mb:block" /> skills
        </p>
        <div
          className="flex justify-center z-10 md:grid md:grid-rows-2 md:grid-cols-2 md:mx-auto md:gap-4 sm:grid-cols-1 space-x-8 lg:space-x-2 md:space-x-0 relative"
          style={{ zIndex: 30 }}
          ref={ref}
        >
          {Data.map((item, index) => (
            <Grow in={inView} timeout={1500} key={index}>
              <div
                key={index}
                className="md:mx-auto"
                style={{ margin: "auto" }}
              >
                <Box
                  icon={item.icon}
                  heading={item.heading}
                  infoText={item.infoText}
                />
              </div>
            </Grow>
          ))}
        </div>
        <div className="flex justify-center items-center mt-8 space-x-4">
          <img
            alt=""
            src={clown}
            className="w-[120px] mb:w-[80px] opacity-40"
          />
          <div className="flex items-center">
            <Link to={"/super-30"}>
              <button className="border-2 border-primary px-16 py-3 text-primary rounded-3xl  md:px-10 sm:px-8 mb:px-6 mb:text-sm mb:py-2 transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white">
              I Want To Join
              </button>
            </Link>
            <img
              src={rightVerticalLines}
              alt=""
              className="w-[50px] mb-10 mb:w-[35px]"
            />
          </div>
        </div>
      </div>
      <img
        src={plane}
        alt=""
        className="absolute top-0 left-[10%] sm:hidden"
        style={{ zIndex: 10 }}
      />
    </ResponsiveWrapper>
  );
};

const Box = ({ icon, heading, infoText }) => (
  <div className="w-[350px] border bg-white px-6 py-8 h-[350px] rounded-2xl shadow-xl space-y-3 xl:w-[310px] lg:w-[270px] lg:h-[380px] transition ease-in-out duration-500 hover:scale-110 cursor-pointer mb:w-[350px]">
    <div className="h-[100px]">
      <img alt="" src={icon} />
    </div>
    <div>
      <p className="text-lg font-semibold">{heading}</p>
      <p className="text-gray-500">{infoText}</p>
    </div>
  </div>
);

const Data = [
  {
    icon: data,
    heading: "A Well-Structured & Adaptable Program, Tailored to Your Needs",
    infoText:
      "No matter if you're a budding coding enthusiast, at an intermediate level of your career, or an advanced pro-coder, our curriculum has got you covered.",
  },
  {
    icon: teacher,
    heading: "Gain Personalised One-On-One Mentoring by Seasoned Professionals",
    infoText:
      "Receive guidance from MAANG industry experts who can assist you with career insights, resume building, interview preparation, and more",
  },
  {
    icon: community,
    heading: "Join a Thriving Coders’ Community — For Life, at No Cost",
    infoText:
      "Through our extensive alumni network, you can access job referrals, get career guidance, and even build potential co-founder connections.",
  },
];

export default SkillsInfoSection;
