import React from "react";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import CallbackCard from "./CallbackCard";

const CallbackSection = () => {
  return (
    <ResponsiveWrapper className={'mt-20'}>
      <div className="flex justify-center space-x-10 items-center sm:flex-col sm:space-x-0 sm:space-y-10">
        <div className="space-y-4">
          <p className="text-4xl font-semibold leading-[45px] sm:text-center">
            Upskill your students & <br className="mb:hidden"/> get them placed with <br className="mb:hidden"/> Maangler
          </p>
          <p className="sm:text-center">
            Our expert will assist you withthe best solutions for your
            institution
          </p>
        </div>
        <div>
          <CallbackCard />
        </div>
      </div>
    </ResponsiveWrapper>
  );
};

export default CallbackSection;
