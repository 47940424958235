import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import React, { useState } from "react";
import { LinkedIn } from "@mui/icons-material";
import Jainil from "../../static/Images/Jainil.jpg";
import Gaurav from "../../static/Images/Gaurav.jpg";
import Swaraj from "../../static/Images/Swaraj.jpg";
import Muskan from "../../static/Images/Muskan.jpg";
import Certification from "../../static/Images/certification.png";
import TranningVideo1 from "../../static/Images/TrainningVideo1.png";
import TranningVideo2 from "../../static/Images/TrainningVideo2.png";
import trainer1 from "../../static/Images/trainer1.jpeg";
import trainner2 from "../../static/Images/trainner2.jpeg";
import Group from "../../static/Images/Group.png";
import Loganpriya from "../../static/Images/Logapriya.jpg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/skyblue";
import { Grow } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import smile from "../../static/smile.png";
import { styled } from "@mui/system";
import "./PythonInternship.css";
import {
  Grid,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ApplyNowModal from "../Courses/CommonApplyNowBtn";

const MentorsSections = ({
  courseName,
  CourseDuration,
  ButtonColor,
  GPTBoxColor,
  BonusBgColor,
  BonusCardBgColor,
  accordionBgColor,
}) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  const cardData = [
    {
      title: "Freshers in any field",
      description: `Completed your 0-1 journey in ${courseName} programming, with opportunities to work on real-world software development projects.`,
    },
    {
      title: "Working Professionals",
      description:
        "Improved problem-solving skills to break down complex programming challenges into manageable components and create effective solutions.",
    },
    {
      title: "Programming Enthusiasts",
      description:
        "Gained a deeper understanding of the tech industry through insights from mentors and the vibrant Maangler community.",
    },
    {
      title: `Anyone curious about what ${courseName} programmers truly do`,
      description: `Developed the right strategies and approaches to excel in technical interviews at top tech companies as a ${courseName} programmer.`,
    },
  ];

  const modules = [
    {
      id: 1,
      title: "Application Process:",
      description: `Complete the application form to apply for the ${courseName} Programming Program.`,
    },
    {
      id: 2,
      title: "Selection",
      description: "Successful candidates will receive a confirmation email.",
    },
    {
      id: 3,
      title: "Building Relationships",
      description: "Network and collaborate with your cohort members.",
    },
  ];

  const modules1 = [
    {
      id: 1,
      title: "Application Review",
      description:
        "Each application is reviewed by experts to ensure alignment with our goals and values.",
    },
    {
      id: 2,
      title: "Program Kick-off & Onboarding",
      description:
        "Welcome to an intensive 8-week journey of learning and achievement.",
    },
    {
      id: 3,
      title: "Sharing Success:",
      description:
        "Celebrate your progress and insights with the Maangler community.",
    },
  ];

  // Custom styles for the cards
  const BonusCard = styled(Card)(({ theme }) => ({
    background: BonusCardBgColor,
    color: "#fff",
    borderRadius: "16px",
    padding: theme.spacing(4), // Increased padding for a more spacious look
    boxShadow: "0px 4px 10px rgba(0,0,0,0.5)",
    border: "1px solid #71613f", // Orange border similar to the image
    textAlign: "center",
    width: "100%",
    maxWidth: "400px", // Set a max width for card
    "&:hover": {
      transform: "scale(1.05)",
      transition: "0.3s",
    },
  }));

  // Styled Card for Certificate section
  const CertificateCard = styled(Card)(({ theme }) => ({
    background: BonusBgColor,
    color: "#fff",
    borderRadius: "16px",
    border: "1px solid #4A443C",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(4),
  }));

  const CustomAccordionNew = styled(Accordion)(({ theme }) => ({
    // background: "linear-gradient(90deg, #6F4F32 100%, #573115 100%)",
    background: accordionBgColor,
    color: "#fff",
    borderRadius: "8px",
    border: "1px solid #4A443C",
    marginBottom: "10px",
    "& .MuiAccordionSummary-content": {
      fontWeight: "bold",
    },
    "& .MuiAccordionSummary-root": {
      backgroundColor: accordionBgColor,
    },
  }));

  const [show, setShow] = useState(false); // Correct usage of useState
  return (
    <div id="mentors" className="overflow-hidden relative">
      <Box
        sx={{ backgroundColor: "#000", color: "#fff", padding: "60px 20px" }}
      >
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ color: "#ECC578", marginBottom: "10px" }}
        >
          Know-how
        </Typography>
        <Typography variant="h4" align="center" gutterBottom>
          How does the Program work?
        </Typography>

        <Grid container spacing={4} sx={{ mt: 3 }} direction="row">
          {/* Left Side - List of Benefits */}
          <Grid item xs={12} md={5}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon
                    sx={{ color: "#00e676", marginBottom: "70px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Live Classes and Lifetime Access to Recorded Sessions"
                  secondary="Engage in live, interactive classes to dive deep into the fundamentals of Full Stack Web Development. Participate in step-by-step training sessions that will help you master Web Development, work on real-world projects, and kick-start your career as a Full Stack Web Developer. Additionally, enjoy lifetime access to all recorded sessions for continuous learning and reference."
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontWeight: "bold", // Make the primary text bold
                    },
                    "& .MuiListItemText-secondary": {
                      color: "#b0b0b0", // Setting secondary text color to a light gray
                    },
                  }}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon
                    sx={{ color: "#00e676", marginBottom: "50px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="On-Demand Videos"
                  secondary="6 months of step-by-step pre-recorded video training & course material to help you dive deep into the fundamentals of Full Stack Web Development, crack case interviews & kick-start your career in Full Stack Web Development."
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontWeight: "bold", // Make the primary text bold
                    },
                    "& .MuiListItemText-secondary": {
                      color: "#b0b0b0", // Setting secondary text color to a light gray
                    },
                  }}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon
                    sx={{ color: "#00e676", marginBottom: "70px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Industry Case Studies and Projects"
                  secondary="Learn the exact roles & responsibilities of a Full Stack Developer by working on real-life projects and case studies."
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontWeight: "bold", // Make the primary text bold
                    },
                    "& .MuiListItemText-secondary": {
                      color: "#b0b0b0", // Setting secondary text color to a light gray
                    },
                  }}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon
                    sx={{ color: "#00e676", marginBottom: "70px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Weekly Jamming & Networking Sessions"
                  secondary="Learning doesn’t have to be lonely. Learn, Discuss, Network, Grow together! Every week, there will be a live session for you to discuss and share ideas with your peers, and jam on different projects so you can learn while having fun."
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontWeight: "bold", // Make the primary text bold
                    },
                    "& .MuiListItemText-secondary": {
                      color: "#b0b0b0", // Setting secondary text color to a light gray
                    },
                  }}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon
                    sx={{ color: "#00e676", marginBottom: "70px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Lifetime Access to Community"
                  secondary="Become part of a community where you can network, share opportunities, and build relations beyond work."
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontWeight: "bold", // Make the primary text bold
                    },
                    "& .MuiListItemText-secondary": {
                      color: "#b0b0b0", // Setting secondary text color to a light gray
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>

          {/* Right Side - Image */}
          <Grid item xs={12} md={5} justifyContent="center" alignItems="center">
            <div
              style={{
                backgroundColor: "#344054",
                borderRadius: "10px",
                marginLeft: "20px",
                marginRight: "50px",
              }}
            >
              <img
                src={trainer1} // Replace this with the appropriate image path
                alt="Program Preview"
                style={{
                  width: "60%",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 10px rgba(0,0,0,0.5)",
                  marginLeft: "40px",
                }}
              />
              <img
                src={trainner2} // Replace this with the appropriate image path
                alt="Program Preview"
                style={{
                  width: "60%",
                  borderRadius: "10px",
                  marginLeft: "70px",
                  boxShadow: "0px 4px 10px rgba(0,0,0,0.5)",
                }}
              />
            </div>
          </Grid>
        </Grid>

        {/* Apply Button */}
        <Box sx={{ textAlign: "center", marginTop: "50px" }}>
          <Button
            variant="contained"
            size="large"
            sx={{
              background: ButtonColor,
              color: "#000",
              padding: "18px 40px",
              borderRadius: "10px",
              width: "400px",
              fontWeight: "bold",
              textTransform: "none",
              display: { xs: "none", sm: "inline" },
            }}
            onClick={() => setShow(true)}
          >
            APPLY NOW
          </Button>
          <p className="applyButtonText">
            {" "}
            In the last 10 hours, 6 learners have onboarded with us
          </p>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "black",
          padding: "50px 0",
          textAlign: "center",
        }}
      >
        <hr
          style={{
            color: "white",
            width: "200px",
            marginBottom: "20px",
            marginTop: "60px",
            marginLeft: "690px",
            textAlign: "center",
          }}
        />

        <Typography
          variant="subtitle1"
          align="center"
          sx={{ color: "#ECC578", marginBottom: "10px" }}
        >
          End Goal
        </Typography>
        <Typography variant="h4" align="center" gutterBottom>
          By the end of the program you'll
          <br /> have:
        </Typography>

        {/* Subheading Text */}
        <Typography
          variant="body2"
          sx={{
            color: "#A0A0A0",
            marginBottom: "40px",
            marginTop: "20px",
            fontSize: "13px",
          }}
        >
          Of like-minded people looking to build, accelerate or enhance their
          existing careers by learning strategic
          <br />
          growth frameworks. Through this community, you can....
        </Typography>

        <Box
          sx={{
            backgroundColor: "black",
            padding: "50px 0",
            marginTop: "20px",
            marginLeft: { xs: "0px", sm: "100px" },
          }}
        >
          {/* Cards Section */}
          <Grid container spacing={2} justifyContent="center" direction="row">
            {cardData.map((card, index) => (
              <Grid item xs={12} sm={6} md={5} key={index}>
                <Card
                  sx={{
                    background: GPTBoxColor,
                    color: "white",
                    borderRadius: "16px",
                    textAlign: "left",
                    border: "1px solid #71613f",
                    width: { xs: "auto", sm: "440px" },
                    height: "110px",
                  }}
                >
                  <CardContent>
                    <Typography variant="body2" sx={{ marginTop: "10px" }}>
                      {card.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ textAlign: "center", marginTop: "50px" }}>
          <Button
            variant="contained"
            size="large"
            sx={{
              background: ButtonColor,
              color: "#000",
              padding: "18px 40px",
              borderRadius: "10px",
              width: "400px",
              fontWeight: "bold",
              textTransform: "none",
              display: { xs: "none", sm: "inline" },
            }}
            onClick={() => setShow(true)}
          >
            APPLY NOW
          </Button>
          <p className="applyButtonText">
            {" "}
            In the last 10 hours, 6 learners have onboarded with us
          </p>
        </Box>

        <hr
          style={{
            color: "white",
            width: "200px",
            marginBottom: "100px",
            marginTop: "60px",
            marginLeft: "690px",
            textAlign: "center",
          }}
        />

        <Typography
          variant="subtitle1"
          align="center"
          sx={{ color: "#ECC578", marginBottom: "10px" }}
        >
          Community Building
        </Typography>
        <Typography variant="h4" align="center" gutterBottom>
          Office Meetup events
        </Typography>

        <Grid item xs={12} md={5} justifyContent="center" alignItems="center">
          <div
            style={{
              // backgroundColor: "#344054",
              borderRadius: "10px",
              marginTop: "50px",
              // marginRight: "50px",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img
              src={Group} // Replace this with the appropriate image path
              alt="Program Preview"
              style={{
                width: "auto",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.5)",
                display: "inline",
              }}
            />
          </div>
        </Grid>
      </Box>
      <hr
        style={{
          color: "white",
          width: "200px",
          marginBottom: "60px",
          marginTop: "50px",
          marginLeft: "690px",
          textAlign: "center",
        }}
      />

      <Box
        sx={{ backgroundColor: "#000", color: "#fff", padding: "50px 20px" }}
      >
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ color: "#ECC578", marginBottom: "10px" }}
        >
          Only for the first 50 people
        </Typography>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold", marginBottom: "50px" }}
        >
          Bonus
        </Typography>
        <Box
          sx={{
            marginTop: "30px",
            background: BonusBgColor,
            color: "white",
            padding: "20px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column", // Ensures content is stacked in a column
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ marginLeft: "20px" }}
          >
            <Grid item xs={12} md={4} display="inline" justifyContent="center">
              <BonusCard>
                <CardContent>
                  <Typography
                    variant="h3"
                    sx={{ color: "#f5af19", fontWeight: "bold" }}
                  >
                    1
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Access to exclusive Maangler {courseName} development
                    workshops for free
                  </Typography>
                </CardContent>
              </BonusCard>
            </Grid>

            <Grid item xs={12} md={4} display="inline" justifyContent="center">
              <BonusCard>
                <CardContent>
                  <Typography
                    variant="h3"
                    sx={{ color: "#f5af19", fontWeight: "bold" }}
                  >
                    2
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    Access to 100+ interview questions and a cheatsheet to help
                    you crack your next {courseName} developer role
                  </Typography>
                </CardContent>
              </BonusCard>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "black",
          padding: "50px 0",
          textAlign: "center",
        }}
      >
        {/* Main Heading */}
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ color: "#ECC578", marginBottom: "10px" }}
        >
          Course Program
        </Typography>
        <p className="text-4xl font-semibold md:text-3xl">
          How The Journey Looks?
        </p>

        {/* Subheading Text */}
        <Typography
          variant="body2"
          sx={{
            color: "#A0A0A0",
            marginBottom: "40px",
            marginTop: "40px",
            fontSize: "13px",
          }}
        >
          Maangler's {courseName} Web Development Program is an exclusive{" "}
          {CourseDuration}-week immersive cohort-based
          <br />
          experience with a lifelong community to support your growth and goals.
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#000",
          padding: "50px 0",
          position: "relative",
          width: "100%", // Use full width for better alignment
          margin: "0 auto",
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          alignItems: "flex-start", // Align the content to the left
          justifyContent: "center",
          marginLeft: "240px",
          alignItems: "center",
        }}
      >
        <Grid container spacing={10} direction="row">
          {/* Left Side Modules */}
          <Grid item xs={4}>
            {modules.map((module, index) => (
              <Box key={module.id} sx={{ mb: 20 }}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: "20px",
                    backgroundColor: "#000",
                    color: "white",
                    borderRadius: "10px",
                    // border: "1px solid #4A443C",
                    width: "80%", // Adjust width to fit within the left area
                    position: "relative",
                    overflow: "hidden",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)", // Shadow effect
                    paddingTop: "0px",
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.3s ease",
                    },
                  }}
                >
                  {/* Module Title */}
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      mt: 2,
                      color: "#ECA877",
                      textAlign: "right",
                    }}
                  >
                    {module.title}
                  </Typography>

                  {/* Module Description */}
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    {module.description}
                  </Typography>
                </Paper>
              </Box>
            ))}
          </Grid>

          {/* Right Side Vertical Line */}
          <Grid item xs={4} sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "10%",
                height: "100%",
                width: "2px",
                backgroundColor: "#4A443C", // Vertical line color
              }}
            />
            {modules1.map((module, index) => (
              <Box key={module.id} sx={{ mt: 20 }}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: "20px",
                    backgroundColor: "#000",
                    color: "white",
                    borderRadius: "10px",
                    width: "80%",
                    position: "relative",
                    overflow: "hidden",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)",
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.3s ease",
                    },
                  }}
                >
                  {/* Module Title */}
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      mt: 2,
                      color: "#FAF4D2",
                    }}
                  >
                    {module.title}
                  </Typography>

                  {/* Module Description */}
                  <Typography variant="body2">{module.description}</Typography>
                </Paper>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>

      {/* for mobile view */}
      <Box
        sx={{
          backgroundColor: "#000",
          padding: "50px 0",
          position: "relative",
          width: "60%", // Use full width for better alignment
          margin: "0 auto",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          alignItems: "flex-start", // Align the content to the left
          justifyContent: "center",
          // marginLeft: "340px",
          alignItems: "center",
        }}
      >
        <Grid container spacing={10} direction="row">
          {/* Left Side Modules */}

          {/* Right Side Vertical Line */}
          <Grid item xs={12} sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "10%",
                height: "100%",
                width: "2px",
                backgroundColor: "#4A443C", // Vertical line color
              }}
            />
            {modules.map((module, index) => (
              <Box key={module.id} sx={{ mb: 20 }}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: "20px",
                    backgroundColor: "#000",
                    color: "white",
                    borderRadius: "10px",
                    // border: "1px solid #4A443C",
                    // width: "300px", // Adjust width to fit within the left area
                    position: "relative",
                    overflow: "hidden",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)", // Shadow effect
                    paddingTop: "0px",
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.3s ease",
                    },
                  }}
                >
                  {/* Module Title */}
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      mt: 2,
                      color: "#ECA877",
                      textAlign: "ḷeft",
                    }}
                  >
                    {module.title}
                  </Typography>

                  {/* Module Description */}
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {module.description}
                  </Typography>
                </Paper>
              </Box>
            ))}
            {modules1.map((module, index) => (
              <Box key={module.id} sx={{ mt: 20 }}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: "20px",
                    backgroundColor: "#000",
                    color: "white",
                    borderRadius: "10px",
                    width: "80%",
                    position: "relative",
                    overflow: "hidden",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)",
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.3s ease",
                    },
                  }}
                >
                  {/* Module Title */}
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      mt: 2,
                      color: "#FAF4D2",
                    }}
                  >
                    {module.title}
                  </Typography>

                  {/* Module Description */}
                  <Typography variant="body2">{module.description}</Typography>
                </Paper>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
      {/* Apply Button */}
      <Box sx={{ textAlign: "center", marginTop: "50px" }}>
        <Button
          variant="contained"
          size="large"
          sx={{
            background: ButtonColor,
            color: "#000",
            padding: "18px 40px",
            borderRadius: "10px",
            width: "400px",
            fontWeight: "bold",
            textTransform: "none",
            display: { xs: "none", sm: "inline" },
          }}
          onClick={() => setShow(true)}
        >
          APPLY NOW
        </Button>
        <p className="applyButtonText">
          {" "}
          In the last 10 hours, 6 learners have onboarded with us
        </p>
      </Box>
      <hr
        style={{
          color: "white",
          width: "200px",
          marginBottom: "100px",
          marginTop: "60px",
          marginLeft: "680px",
        }}
      />

      <Box sx={{ backgroundColor: "#000", color: "#fff", padding: "50px 0" }}>
        <Container maxWidth="sm" sx={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#ECC578", marginBottom: "10px" }}
          >
            Pricing
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", marginBottom: "40px" }}
          >
            Program Details
          </Typography>

          {/* Elite Plan */}
          {/* <CustomAccordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>Elite</Typography>

              <Typography sx={{ marginLeft: "auto" }}>at ₹85,000/-</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <hr />
              <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
                * Access to weekly program Modules <br />& Workbooks of Cloud
                Data Engineering Program
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
                * Access to weekend live classes
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
                * Access to 2 Projects and 1 Mega Capstone Project
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
                * Learning of all the 3 clouds (AWS/AZURE/GCP)
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
                * Access to our Premium Discord Community
              </Typography>
            </AccordionDetails>
          </CustomAccordion> */}

          {/* Premium Plan */}
          <CustomAccordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>Premium</Typography>
              <Typography sx={{ marginLeft: "auto" }}>at ₹18,000/-</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Includes all features of the program with access to group
                mentoring sessions.
              </Typography>
            </AccordionDetails>
          </CustomAccordion>

          {/* Starter Plan */}
          <CustomAccordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>Starter</Typography>
              <Typography sx={{ marginLeft: "auto" }}>at ₹12,000/-</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Access to the basic {courseName} course with limited mentoring
                sessions.
              </Typography>
            </AccordionDetails>
          </CustomAccordion>
        </Container>

        {/* Apply Button */}
        <Box sx={{ textAlign: "center", marginTop: "50px" }}>
          <Button
            variant="contained"
            size="large"
            sx={{
              background: ButtonColor,
              color: "#000",
              padding: "18px 40px",
              borderRadius: "10px",
              width: "400px",
              fontWeight: "bold",
              textTransform: "none",
              display: { xs: "none", sm: "inline" },
            }}
            onClick={() => setShow(true)}
          >
            APPLY NOW
          </Button>
          <p className="applyButtonText">
            {" "}
            In the last 10 hours, 6 learners have onboarded with us
          </p>
        </Box>
      </Box>

      <hr
        style={{
          color: "white",
          width: "200px",
          marginBottom: "100px",
          marginTop: "60px",
          marginLeft: "680px",
        }}
      />

      <Box sx={{ backgroundColor: "#000", color: "#fff", padding: "50px 0" }}>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ color: "#ECC578", marginBottom: "10px" }}
        >
          Rewards
        </Typography>
        <Typography
          variant="h3"
          align="center"
          sx={{ fontWeight: "bold", marginBottom: "40px" }}
        >
          Certification
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{ marginBottom: "60px", color: "#bbb" }}
        >
          Simple, transparent pricing that grows with you. Try any plan free for
          30 days.
        </Typography>

        <Grid container justifyContent="center" direction="row">
          <Grid item xs={12} md={6}>
            <CertificateCard>
              {/* Left side content */}
              <Box>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", marginBottom: "20px" }}
                >
                  Earn a Course Completion <br />
                  Certificate
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    background: ButtonColor,
                    color: "#000",
                    padding: "5px 10px",
                    borderRadius: "10px",
                    width: "180px",
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                  onClick={() => setShow(true)}
                >
                  APPLY NOW
                </Button>
              </Box>

              {/* Right side - Certificate Image */}
              <Box
                sx={{
                  backgroundColor: "#000",
                  height: "250px",
                  textAlign: "center",
                  width: "280px",
                  borderRadius: "10px",
                  boxShadow: "1px solid #4A443C",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                    marginTop: "20px",

                    // color: "#f5af19",
                  }}
                >
                  Certificate of <br />
                  <span
                    style={{
                      color: "#ECA877",
                    }}
                  >
                    Completion
                  </span>
                </Typography>
                <img
                  src={Certification} // Replace this with the certificate image link
                  alt="Certificate"
                  style={{
                    borderRadius: "10px",
                    width: "50%",
                    alignItems: "center",
                    display: "inline",
                  }}
                />
              </Box>
            </CertificateCard>
          </Grid>
        </Grid>
        <hr
          style={{
            color: "white",
            width: "200px",
            marginBottom: "30px",
            marginTop: "60px",
            marginLeft: "680px",
          }}
        />
      </Box>

      <Box sx={{ backgroundColor: "#000", color: "#fff", padding: "50px 0" }}>
        <Container maxWidth="md">
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ color: "#ECC578", marginBottom: "10px" }}
          >
            FAQ
          </Typography>
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: "bold", marginBottom: "40px" }}
          >
            Here’s everything you may ask...
          </Typography>

          {/* FAQ 1 */}
          <CustomAccordionNew>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>Who is this program for?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                This program is designed for college students in their 1st, 2nd,
                3rd, or 4th year who want to enhance their resumes and build
                projects to showcase their skills. It’s also suitable for
                individuals transitioning from various professions such as
                sales, marketing, software engineers/developers/QAs, analysts
                (data/business), and others aiming to develop foundational
                [Python/Java/Web Development] skills for career advancement.
              </Typography>
            </AccordionDetails>
          </CustomAccordionNew>

          {/* FAQ 2 */}
          <CustomAccordionNew>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>What is the application process?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our [Python/Java/Full-Stack Web Development] program, limited to
                only 30 students in our exclusive Super 30 Batch, follows a
                structured and selective application process similar to a
                professional hiring procedure. Candidates begin by completing a
                comprehensive application form where we evaluate their
                backgrounds and motivations. Those who submit thorough
                applications are then invited to a qualifier interview. During
                this interview, we assess candidates' career journeys and how
                our program can accelerate their skills development. We
                prioritize proactive and enthusiastic individuals committed to
                advancing their programming abilities. Additionally, we offer
                discounts and scholarships specifically for students, as well as
                limited scholarships to support individuals affected by layoffs,
                ensuring affordability, accessibility, and inclusivity in our
                program.
              </Typography>
            </AccordionDetails>
          </CustomAccordionNew>

          {/* FAQ 3 */}
          <CustomAccordionNew>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>
                Are there any prerequisites for this program?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                For [Python/Java/Full-Stack Web Development], there are no
                strict prerequisites, but basic knowledge in programming
                concepts and languages like [Python, SQL, or Java] (overview
                level) is recommended. Just bring your enthusiasm and readiness
                to learn!
              </Typography>
            </AccordionDetails>
          </CustomAccordionNew>

          {/* FAQ 4 */}
          <CustomAccordionNew>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>Is this a Job Guarantee Program?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No, this is not a job guarantee program. However, we offer job
                assistance that includes: Building your portfolio with a minimum
                of 2 unique projects Interview preparation through 1-on-1
                sessions with mentors (if chosen) and practice questions Access
                to our community of mentors and learners for securing referrals
                Submitting your profile to potential hiring managers and
                partners When do the live group calls & guest sessions happen?
                The live group call with a dedicated mentor takes place after 7
                pm on a weekday. The live guest session occurs every Sunday or
                Saturday evening at 6 pm or 8 pm, depending on cohort
                availability.
              </Typography>
            </AccordionDetails>
          </CustomAccordionNew>

          {/* FAQ 5 */}
          <CustomAccordionNew>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>
                When do the live group calls & guest sessions happen?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The live group call with a dedicated mentor takes place after 7
                pm on a weekday. The live guest session occurs every Sunday or
                Saturday evening at 6 pm or 8 pm, depending on cohort
                availability.
              </Typography>
            </AccordionDetails>
          </CustomAccordionNew>

          {/* FAQ 6 */}
          <CustomAccordionNew>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>What if I can’t make the live sessions?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Maangler’s [Python/Java/Full-Stack Web Development] Program is
                designed to accommodate your schedule. We prioritize community
                engagement by polling the cohort to set convenient session
                times. While attending live sessions is highly recommended for
                direct interaction with mentors and experts, if you miss one,
                you can watch the session recordings and engage with discussions
                or provide feedback through our community forum.
              </Typography>
            </AccordionDetails>
          </CustomAccordionNew>

          {/* FAQ 7 */}
          <CustomAccordionNew>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>How much access do I get to the mentors?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our mentors are available for asynchronous communication via
                Discord/WhatsApp group and will also be highly active during
                live sessions. They are open to answering any questions you have
                and providing feedback during both group and individual
                sessions.
              </Typography>
            </AccordionDetails>
          </CustomAccordionNew>

          {/* FAQ 8 */}
          <CustomAccordionNew>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>
                How much time can I expect to spend on the program each week?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can expect to dedicate at least 5-7 hours per week for the 8
                weeks of the program. This will include: Live mentor sessions
                (60 minutes each) Access to recorded content, approximately 3
                hours per week This structured approach ensures you can maintain
                momentum while balancing other commitments.
              </Typography>
            </AccordionDetails>
          </CustomAccordionNew>

          {/* FAQ 9 */}
          <CustomAccordionNew>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>What's included with the program?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                With your tuition, you’ll receive lifetime access to: All
                pre-study materials Recordings of live cohort sessions and guest
                sessions A private online community where you can connect with
                your batchmates Program materials and community access, which
                will remain available even after the course ends.
              </Typography>
            </AccordionDetails>
          </CustomAccordionNew>

          {/* FAQ 10 */}
          <CustomAccordionNew>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
            >
              <Typography>When will I get my Maangler Certificate?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Students who successfully complete 80% of the coursework,
                including all learning modules and project submissions, will
                receive a Certificate of Completion from Maangler. This
                certificate, validated by industry leaders, will help reinforce
                your credentials and accomplishments in the
                [Python/Java/Full-Stack Web Development] domain.
              </Typography>
            </AccordionDetails>
          </CustomAccordionNew>
        </Container>

        <hr
          style={{
            color: "white",
            width: "30px",
            marginBottom: "30px",
            marginTop: "60px",
            marginLeft: "770px",
          }}
        />

        {/* Apply Button */}
        <Box sx={{ textAlign: "center", marginTop: "50px" }}>
          <Button
            variant="contained"
            size="large"
            sx={{
              background: ButtonColor,
              color: "#000",
              padding: "18px 40px",
              borderRadius: "10px",
              width: "400px",
              fontWeight: "bold",
              textTransform: "none",
              display: { xs: "none", sm: "inline" },
            }}
            onClick={() => setShow(true)}
          >
            APPLY NOW
          </Button>
          <p className="applyButtonText">
            {" "}
            In the last 10 hours, 6 learners have onboarded with us
          </p>
        </Box>

        <hr
          style={{
            color: "white",
            width: "30px",
            marginBottom: "30px",
            marginTop: "60px",
            marginLeft: "770px",
          }}
        />
      </Box>

      {/* <div className="flex justify-center space-x-16 mt-6  sm:space-y-10 mb:hidden lg:grid lg:grid-cols-2 lg:space-x-0 lg:gap-4 sm:grid-cols-1">
          {Data.map((item, index) => (
            <Box
              image={item.image}
              name={item.name}
              position={item.position}
              link={item.link}
              inView={inView}
            />
          ))}
        </div> */}
      {/* <div className="mt-10">
        <Splide
          options={{
            perPage: 4,
            arrows: false,
            type: "loop",
            drag: "free",
            focus: "center",
            padding: "5rem",
            rewind: true,
            autoplay: true,
            interval: 1300,
            breakpoints: {
              1400: {
                perPage: 3,
              },
              1100: {
                perPage: 2,
              },
              800: {
                perPage: 1,
                padding: "3rem",
              },
            },
            mediaQuery: "max",
          }}
          extensions={AutoScroll}
        >
          {Data.map((item) => (
            <SplideSlide>
              <BoxData
                image={item.image}
                name={item.name}
                position={item.position}
                link={item.link}
                inView={inView}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <img src={smile} alt="" className="absolute top-[67px]" /> */}
      <ApplyNowModal show={show} setShow={setShow} />
    </div>
  );
};

const BoxData = ({ image, position, name, link, inView }) => (
  <div className="relative w-[270px] lg:justify-self-center lg:self-center sm:mx-auto  sm:mt-8 hover:shadow-xl">
    <img alt="" src={image} className="w-[270px] rounded-3xl" />
    <div className="absolute inset-0 bg-black opacity-30 rounded-3xl w-[270px]"></div>
    <a href={link} target="_blank" rel="noreferrer">
      <div
        className="absolute bottom-3 justify-between px-2 py-3 text-white  w-[250px] left-3 rounded-xl flex items-center "
        style={{
          background: "rgba( 84, 86, 87, 0.25 )",
          boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
          backdropFilter: " blur( 4px )",
          WebkitBackdropFilter: "blur(4px)",
        }}
      >
        <div className="">
          <p className="font-semibold ">{name}</p>
          <p className="text-xs mt-1">{position}</p>
        </div>

        <LinkedIn className="text-[#025f9f]" style={{ fontSize: "35px" }} />
      </div>
    </a>
  </div>
);

const Data = [
  {
    name: "Gaurav",
    position: "SWE - III @ Google",
    image: Gaurav,
    link: "https://www.linkedin.com/in/gaurav-jha-776039119/",
  },
  {
    name: "Jainil",
    position: "SDE II @Microsoft ",
    image: Jainil,
    link: "https://www.linkedin.com/in/jainilvachhani/",
  },
  {
    name: "Logapriya",
    position: "Senior Data Scientist @Amazon",
    image: Loganpriya,
    link: "https://www.linkedin.com/in/logapriya-viswanathan-546626125/",
  },

  {
    name: "Swaraj",
    position: "SDE II @ Microsoft",
    image: Swaraj,
    link: "https://www.linkedin.com/in/swaraj-software-engineer/",
  },
  {
    name: "Muskan",
    position: "SDE @Microsoft",
    image: Muskan,
    link: "https://www.linkedin.com/in/muskan-handa-8b087217a/",
  },
];

// Custom styled Accordion
const CustomAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "#000",
  borderRadius: "10px",
  border: "1px solid #F4B742",
  marginBottom: "16px",
  color: "#fff",
  "& .MuiAccordionSummary-content": {
    fontWeight: "bold",
  },
}));

// Custom styled Button
const ApplyButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(90deg, #f5af19, #f12711)",
  color: "#fff",
  padding: "10px 30px",
  borderRadius: "50px",
  fontWeight: "bold",
  fontSize: "16px",
  marginTop: "20px",
  "&:hover": {
    background: "linear-gradient(90deg, #f12711, #f5af19)",
  },
}));

export default MentorsSections;
