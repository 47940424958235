import React, { useState } from "react";
import rightVerticalLines from "../../static/Python/blueVerticalLines.png";
import Hat from "../../static/Python/Hat.png";
import Medal from "../../static/Python/Medall.png";
import Rocket from "../../static/Python/Rocket.png";
import { Dialog } from "@mui/material";
import BookCard from "../HomePage/BookCard";
import people from "../../static/Icons/people.png";
import threeLines from "../../static/Icons/threeLines.png";
import chatGPT from "../../static/Icons/chatGPT.png";

import python from "../../static/Courses/python.png";
import powreBI from "../../static/Courses/powreBI.png";
import mysql from "../../static/Courses/mysql.png";
import chatGPTNew from "../../static/Courses/chatGPTNew.png";
import SQL from "../../static/Courses/SQL.png";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";

import { isLoggedIn } from "../../utils/helperFunctions";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  Grid,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  CardContent,
  Card,
} from "@mui/material";

const CourseInfo = ({
  courseName,
  CourseDuration,
  CourseInfo1,
  CourseInfo2,
  data,
  border,
  ButtonColor,
}) => {
  const [openModal, setOpenModal] = useState(false); //modal state
  const handleClick = async () => {
    const authorized = isLoggedIn();

    const api_URL = `https://maangler-backend-production.up.railway.app/payment/${courseName}`;
    //const authToken= "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ZGY0NWUwMWE3MWYzNzMzNTVhZGEwOCIsImVtYWlsIjoidXNlckBleGFtcGxlLmNvbSIsImlhdCI6MTcwMjI5NjYyNywiZXhwIjoxNzAyOTAxNDI3fQ._E6-g0cIY6wl7gp8Jm6Vvu2Un2_KDupIi4WEp5T2UEc"; // local
    const authToken = Cookies.get("access_token"); // Prod
    if (authorized) {
      await axios
        .get(api_URL, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          console.log(response.data.paymentUrl);
          window.location.href = response.data.paymentUrl;
        })
        .catch((error) => {
          console.error("Error:", error);
          console.log(error);
          // Handle errors appropriately in your application
        });
    } else {
      //setOpenModal(true)
      toast.warn("Please log in to make a payment.", {
        position: "bottom-left",
      });
    }
  };
  return (
    <ResponsiveWrapper>
      <div className="flex flex-col items-center space-y-16">
        <Box
          sx={{
            backgroundColor: "black",
            padding: "50px 0",
            textAlign: "center",
          }}
        >
          {/* Main Heading */}
          <p className="text-4xl font-semibold md:text-3xl">
            You do not need to come from a traditional career path to
            <br />
            secure your spot as a {courseName} Programmer.
          </p>

          {/* Subheading Text */}
          <Typography
            variant="body2"
            sx={{
              color: "#A0A0A0",
              marginBottom: "40px",
              marginTop: "40px",
              fontSize: "13px",
            }}
          >
            This {CourseDuration}-week cohort-based course is ALL you need to
            get your foot in <br />
            the door as a {courseName} Programmer.
          </Typography>

          {/* Additional Description */}
          <Typography
            variant="body1"
            sx={{
              color: "#A0A0A0",
              maxWidth: "800px",
              margin: "0 auto",
              fontSize: "13px",
              marginBottom: "40px",
            }}
          >
            Taught by 10+ experienced {courseName} developers, you’ll learn
            everything from the fundamentals of {courseName} programming to
            real-life problem-solving assignments so you can dive deeper into
            the roles and responsibilities of a software developer.
          </Typography>
        </Box>
        <div>
          <Box
            sx={{
              backgroundColor: "#000",
              padding: "50px 0",
              position: "relative",
              width: "100%",
              margin: "0 auto",
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
              alignItems: "flex-start", // Align the content to the left
              justifyContent: "center",
              marginLeft: "240px",
              alignItems: "center",
            }}
          >
            <Grid container spacing={10} direction="">
              {/* Left Side Modules */}
              <Grid item xs={4}>
                {CourseInfo1?.map((module, index) => (
                  <Box key={module.id} sx={{ mb: 30 }}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: "20px",
                        backgroundColor: "#000",
                        color: "white",
                        borderRadius: "10px",
                        border: "1px solid #4A443C",
                        width: "80%", // Adjust width to fit within the left area
                        // width: { xs: "70%", md: "80%" },
                        position: "relative",
                        overflow: "hidden",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)", // Shadow effect
                        "&:hover": {
                          transform: "scale(1.05)",
                          transition: "transform 0.3s ease",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "-12px",
                          right: "auto",
                          left: "calc(100% - 100px)",
                          background: ButtonColor,
                          padding: "12px 20px",
                          borderRadius: "10px",
                          color: "black",
                          transform: "rotate(0deg)",
                          whiteSpace: "nowrap",
                          overflow: "visible",
                          fontSize: "13px",
                        }}
                      >
                        MODULE {module.id}
                      </Box>

                      {/* Module Title */}
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", mb: 2, mt: 2 }}
                      >
                        {module.title}
                      </Typography>

                      {/* Module Description */}
                      <Typography variant="body2">
                        {module.description}
                      </Typography>
                    </Paper>
                  </Box>
                ))}
              </Grid>

              {/* Right Side Vertical Line */}
              <Grid item xs={4} sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: "10%",
                    height: "100%",
                    width: "2px",
                    backgroundColor: "#4A443C", // Vertical line color
                  }}
                />
                {CourseInfo2?.map((module, index) => (
                  <Box key={module.id} sx={{ mt: 30 }}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: "20px",
                        backgroundColor: "#000",
                        color: "white",
                        borderRadius: "10px",
                        border: "1px solid #4A443C",
                        width: "80%",
                        position: "relative",
                        overflow: "hidden",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)",
                        "&:hover": {
                          transform: "scale(1.05)",
                          transition: "transform 0.3s ease",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "-12px",
                          right: "auto",
                          left: "calc(100% - 100px)",
                          background: ButtonColor,
                          padding: "12px 20px",
                          borderRadius: "10px",
                          color: "black",
                          transform: "rotate(0deg)",
                          whiteSpace: "nowrap",
                          overflow: "visible",
                          fontSize: "13px",
                        }}
                      >
                        MODULE {module.id}
                      </Box>

                      {/* Module Title */}
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", mb: 2, mt: 2 }}
                      >
                        {module.title}
                      </Typography>

                      {/* Module Description */}
                      <Typography variant="body2">
                        {module.description}
                      </Typography>
                    </Paper>
                  </Box>
                ))}
              </Grid>
            </Grid>
            <hr
              style={{
                color: "white",
                width: "200px",
                marginBottom: "100px",
                marginTop: "60px",
                marginRight: "500px",
              }}
            />
          </Box>

          {/* for mobile view */}
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              backgroundColor: "#000",
              padding: "50px 0",
              position: "relative",
              width: "65%",
              margin: "0 auto",
              flexDirection: "column",
              alignItems: "flex-start", // Align the content to the left
              justifyContent: "center",
              marginLeft: "350px",
              alignItems: "center",
            }}
          >
            <Grid container direction="column">
              {/* Left Side Modules */}

              {/* Right Side Vertical Line */}
              <Grid
                item
                xs={12}
                sx={{ position: "relative", display: "inline-block" }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: "10%",
                    height: "100%",
                    width: "2px",
                    backgroundColor: "#4A443C", // Vertical line color
                  }}
                />
                {CourseInfo1?.map((module, index) => (
                  <Box key={module.id} sx={{ mb: 30 }}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: "20px",
                        backgroundColor: "#000",
                        color: "white",
                        borderRadius: "10px",
                        border: "1px solid #4A443C",
                        width: "50%", // Adjust width to fit within the left area
                        position: "relative",
                        overflow: "hidden",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)", // Shadow effect
                        "&:hover": {
                          transform: "scale(1.05)",
                          transition: "transform 0.3s ease",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "-12px",
                          right: "auto",
                          left: "calc(100% - 100px)",
                          background: ButtonColor,
                          padding: "12px 20px",
                          borderRadius: "10px",
                          color: "black",
                          transform: "rotate(0deg)",
                          whiteSpace: "nowrap",
                          overflow: "visible",
                          fontSize: "13px",
                        }}
                      >
                        MODULE {module.id}
                      </Box>

                      {/* Module Title */}
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", mb: 2, mt: 2 }}
                      >
                        {module.title}
                      </Typography>

                      {/* Module Description */}
                      <Typography variant="body2">
                        {module.description}
                      </Typography>
                    </Paper>
                  </Box>
                ))}

                {CourseInfo2?.map((module, index) => (
                  <Box key={module.id} sx={{ mt: 30 }}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: "20px",
                        backgroundColor: "#000",
                        color: "white",
                        borderRadius: "10px",
                        border: "1px solid #4A443C",
                        width: "50%", // Adjust width to fit within the left area
                        position: "relative",
                        overflow: "hidden",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)", // Shadow effect
                        "&:hover": {
                          transform: "scale(1.05)",
                          transition: "transform 0.3s ease",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "-12px",
                          right: "auto",
                          left: "calc(100% - 100px)",
                          background: ButtonColor,
                          padding: "12px 20px",
                          borderRadius: "10px",
                          color: "black",
                          transform: "rotate(0deg)",
                          whiteSpace: "nowrap",
                          overflow: "visible",
                          fontSize: "13px",
                        }}
                      >
                        MODULE {module.id}
                      </Box>

                      {/* Module Title */}
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", mb: 2, mt: 2 }}
                      >
                        {module.title}
                      </Typography>

                      {/* Module Description */}
                      <Typography variant="body2">
                        {module.description}
                      </Typography>
                    </Paper>
                  </Box>
                ))}
              </Grid>
            </Grid>
            <p
              style={{
                color: "white",
                width: "200px",
                marginBottom: "100px",
                marginRight: "500px",
              }}
            />
          </Box>
        </div>

        {/* here */}
        {/* <div className="flex items-center">
        <button
          onClick={handleClick}
          className="border-2 px-16 py-2 border-white  rounded-3xl  mb:px-10 transition ease-in-out duration-500 hover:scale-105"
        >
          Join Now
        </button>
        <img src={rightVerticalLines} alt="" className="w-[50px] mb-10" />
      </div> */}
      </div>
    </ResponsiveWrapper>
  );
};
const CardDataNew = ({ item, border }) => {
  const { title, des, info } = item;
  return (
    <div
      className={
        "space-y-1 border px-8 py-4 w-[290px] rounded-xl sm:my-2 mb:min-h-[280px] transition ease-in-out duration-500 cursor-pointer hover:scale-105 min-h-[280px] "
      }
      style={{ border: `1px solid ${border}`, maxWidth: "290px" }}
    >
      <p className="text-center text-lg font-semibold">{title}</p>
      <p
        className="text-[#076AFF] text-center font-semibold"
        style={{ color: `${border}` }}
      >
        {des}
      </p>
      <ul className="list-disc space-y-1 text-sm">
        {info.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default CourseInfo;
