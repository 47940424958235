import React, { useRef, useState } from "react";
import profile from "../../mocks/Profile.png";
import EditIcon from "@mui/icons-material/Edit";
import CustomInput from "../CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "../CustomSelect";
import { getTimezonesWithOffsets } from "../../utils/getTimeZone";
import { useDispatch, useSelector } from "react-redux";
import { putUserProfile, uploadImage } from "../../redux/auth/apiSlice";
import { selectUserData } from "../../redux/profile/profileSlice";

const AccountDetailsSection = ({}) => {
  const data = useSelector(selectUserData);
  const profileData = data?.account;
  const [selectedImage, setSelectedImage] = useState(profileData.profileImage);
  const [selectFile, setSelectedFile] = useState(null);
  const [editImage, setEditImage] = useState(true);
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        name: profileData?.name,
        phoneNumber: profileData?.phoneNumber,
        address: profileData?.address,
        pincode: profileData?.pincode,
        landmark: profileData?.landmark,
        city: profileData?.city,
        state: profileData?.state,
        mailId: data?.email,
        timezone: profileData?.timezone,
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Name is required"),
        pincode: Yup.string().matches(
          /^\d{6}$/,
          "Pin code must be a 6-digit number"
        ),
        phoneNumber: Yup.string().matches(
          /^[6-9]\d{9}$/,
          "Invalid Indian mobile number"
        ),
        mailId: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
      }),
      onSubmit: (values) => {
        if (selectFile === null) {
          dispatch(
            putUserProfile({
              ...data,
              account: { ...values, profileImage: profileData.profileImage },
            })
          );
        } else {
          const formData = new FormData();
          formData.append("file", selectFile);
          formData.append("upload_preset", "maangler");
          formData.append("cloud_name", "dbjn5gkqr");
          dispatch(
            uploadImage({
              formData: formData,
              data: {
                ...data,
              //  account: { ...values, profileImage: selectedImage },
                account: values,
              },
            })
          );
          // dispatch(
          //   putUserProfile({
          //     ...data,
          //     account: { ...values, profileImage: selectedImage },
          //   })
          // );
        }
      },
    });


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    //setSelectedImage(file)
    setSelectedFile(file);

    if (file) {
      const fileType = file.type;

      if (
        fileType !== "image/jpeg" &&
        fileType !== "image/png" &&
        fileType !== "image/jpg"
      ) {
        alert("Please upload a JPEG or PNG image.");
        return;
      }
      const fileSize = file.size / 1024;
      if (fileSize > 500) {
        alert("Please upload an image smaller than 500kb");
      }
      if (
        (fileType === "image/png" ||
          fileType === "image/jpeg" ||
          fileType === "image/jpg") &&
        fileSize < 500
      ) {
        // setSelectedImage(file);
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
        setEditImage(false);
      }
    }
  };
  const timeZones = getTimezonesWithOffsets();
  return (
    <div className="mb:text-sm">
      <div className="relative mb-4 w-[100px]">
        <img
          src={
            selectedImage === null || selectedImage === undefined
              ? profile
              : selectedImage
          }
          alt=""
          className="rounded-full w-[100px] h-[100px]"
        />
        <EditIcon
          fontSize="large"
          onClick={handleFileSelect}
          className="cursor-pointer absolute bottom-0 right-0 bg-primary text-white p-[6px] rounded-full "
        />
        <input
          type="file"
          className="w-full focus:outline-none"
          accept=".png, .jpeg, .jpg"
          onChange={handleImageUpload}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
      </div>
      <div className="space-y-3 ">
        {feilds.map((item) => (
          <div key={item.label}>
            <p className="font-semibold ml-1 mb-1">
              {item.label}{" "}
              {item.required && <span className="text-red-500">*</span>}
            </p>
            {item.type === "text" ? (
              <CustomInput
                value={values[item.name]}
                onChange={handleChange}
                name={item.name}
                placeholder={item.placeholder}
                error={errors[item.name]}
                touched={touched[item.name]}
                disabled={item.disabled}
              />
            ) : (
              <CustomSelect
                options={timeZones}
                name={item.name}
                setFieldValue={setFieldValue}
                defaultValue={{
                  label: values.timezone,
                  value: values.timezone,
                }}
              />
            )}
          </div>
        ))}
      </div>
      <button
        className="bg-primary w-full text-white py-3 rounded-3xl mt-10"
        onClick={handleSubmit}
      >
        Update Account Details
      </button>
    </div>
  );
};

const feilds = [
  {
    placeholder: "Name",
    disabled: false,
    required: true,
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    placeholder: "Enter Mobile Number",
    disabled: false,
    required: true,
    name: "phoneNumber",
    label: "Phone Number",
    type: "text",
  },
  {
    placeholder: "Address",
    disabled: false,
    required: false,
    name: "address",
    label: "Address",
    type: "text",
  },
  {
    placeholder: "Pincode",
    disabled: false,
    required: false,
    name: "pincode",
    label: "Pincode",
    type: "text",
  },
  {
    placeholder: "Landmark",
    disabled: false,
    required: false,
    name: "landmark",
    label: "Landmark",
    type: "text",
  },
  {
    placeholder: "City",
    disabled: false,
    required: false,
    name: "city",
    label: "City",
    type: "text",
  },
  {
    placeholder: "State",
    disabled: false,
    required: false,
    name: "state",
    label: "State",
    type: "text",
  },
  {
    placeholder: "Email",
    disabled: true,
    required: false,
    name: "mailId",
    label: "Mail ID",
    type: "text",
  },
  {
    placeholder: "Timezone",
    disabled: false,
    required: false,
    name: "timezone",
    label: "Timezone",
    type: "select",
  },
];

export default AccountDetailsSection;
