import React from "react";
import HeroSection from "../components/BecomeMentor/HeroSection";
import AllProgramsStrip from "../components/HomePage/AllProgramsStrip";
import MentorInfo from "../components/BecomeMentor/MentorInfo";
import MentorTestimonial from "../components/BecomeMentor/MentorTestimonial";
import DutiesSection from "../components/BecomeMentor/DutiesSection";
import MentorsSections from "../components/HomePage/MentorsSections";
import JoinUsSection from "../components/BecomeMentor/JoinUsSection";

const BecomeMentor = () => {
  return (
    <div>
      <HeroSection />
      <AllProgramsStrip /> 
      <MentorInfo />
      {/* <MentorTestimonial /> */}
      <DutiesSection />
      <MentorsSections />
      <JoinUsSection />
    </div>
  );
};

export default BecomeMentor;
