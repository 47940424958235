import React from "react";
import { useInView } from "react-intersection-observer";
import Roshan from "../../static/Courses/Roshan.jpg";
import Alka from "../../static/Courses/Alka.jpg";
import Vishal from "../../static/Courses/Vishal.jpg";
import clsx from "clsx";

const HiringHeroRight = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  return (
    <div className="flex">
      <div ref={ref}>
        <Box
          image={Data[0].image}
          inView={inView}
          link={Data[0].link}
          name={Data[0].name}
          position={Data[0].position}
          style={Data[0].style}
          className={Data[0].className}
        />
      </div>
      <div>
        <Box
          image={Data[1].image}
          inView={inView}
          link={Data[1].link}
          name={Data[1].name}
          position={Data[1].position}
          style={Data[1].style}
          className={Data[1].className}
        />
      </div>
      <div>
        <Box
          image={Data[2].image}
          inView={inView}
          link={Data[2].link}
          name={Data[2].name}
          position={Data[2].position}
          style={Data[2].style}
          className={Data[2].className}
        />
      </div>
    </div>
  );
};

const Box = ({ image, position, name, link, inView, style,className }) => (
  <div
    className={clsx("h-[330px] relative w-[240px] lg:justify-self-center lg:self-center sm:mx-auto  sm:mt-8 hover:shadow-xl transition ease-in-out duration-500 hover:scale-105",className)}
    style={style}
  >
    <img
      alt=""
      src={image}
      className="w-[240px] rounded-3xl h-[330px]"
      style={{ height: style?.height }}
    />
    <div
      className="absolute inset-0 bg-black opacity-30 rounded-3xl w-[240px]"
      style={{ height: style?.height }}
    ></div>
    <a href={link} target="_blank" rel="noreferrer">
      <div
        className="absolute bottom-3 bg-white justify-between px-2 py-2  w-[220px] left-3 rounded-xl flex items-center"
        //style={{ height: style?.height }}
      >
        <div className="">
          <p className="font-semibold text-black ">{name}</p>
          {/* <p className="text-sm text-gray-500">{position}</p> */}
        </div>

        {/* <LinkedIn className="text-[#025f9f]" style={{ fontSize: "35px" }} /> */}
      </div>
    </a>
  </div>
);

const Data = [
  {
    name: "Roshan",
    position: "SWE - III at Google",
    image: Roshan,
    link: "https://www.linkedin.com/in/gaurav-jha-776039119/",
    style: {
      // transform: "rotate(-8.089deg)",
      // transformStyle: 'preserve-3d',
      
      left: "45%",
      height: "310px",
      top: "7%",
    },
    className:"hover:z-10 transition ease-in-out duration-500 hover:scale-105"
  },
  {
    name: "Alka",
    position: "SE - II at Microsoft",
    image: Alka,
    link: "https://www.linkedin.com/in/jainilvachhani/",
    style: { zIndex: 1 ,transformStyle: 'preserve-3d',},
    className:""
  },
  {
    name: "Vishal",
    position: "CI Expert at Amazon",
    image: Vishal,
    link: "https://www.linkedin.com/in/logapriya-viswanathan-546626125/",
    style: {
      // transform: "rotate(8.09deg)",
      // transformStyle: 'preserve-3d',  
      right: "45%",
      height: "310px",
      top: "7%",
    },
    className:"hover:z-10 transition ease-in-out duration-500 hover:scale-105"
  },
];

export default HiringHeroRight;
