import React from "react";
import CustomSelect from "./CustomSelect";
import { Checkbox } from "@mui/material";

const CustomStartEndDateRange = ({
  setFieldValue,
  values,
  errors,
  touched,
}) => {
  return (
    <div className="w-full">
      <div className="flex space-x-2 mb-2">
        <div className="w-full">
          <p className="ml-1 mb-1 font-semibold">
            Start Date <span className="text-red-500">*</span>
          </p>

          <div className="flex space-x-1">
            <div>
              <CustomSelect
                name={"startMonth"}
                placeholder="Month"
                defaultValue={{
                  label: values?.startMonth,
                  values: values?.startMonth,
                }}
                options={monthsOptions}
                setFieldValue={setFieldValue}
              />
              {errors?.startMonth && touched?.startMonth && (
                <p className="text-xs ml-4 text-red-500">
                  * {errors?.startMonth}
                </p>
              )}
            </div>
            <div>
              <CustomSelect
                name={"startYear"}
                placeholder="Year"
                defaultValue={{
                  label: values?.startYear,
                  values: values?.startYear,
                }}
                options={yearOptions}
                setFieldValue={setFieldValue}
              />
              {errors?.startYear && touched?.startYear && (
                <p className="text-xs ml-4 text-red-500">
                  * {errors?.startYear}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-full">
          <p className="ml-1 mb-1 font-semibold">
            End Date <span className="text-red-500">*</span>
          </p>
          <div className="flex space-x-1">
            <div>
              <CustomSelect
                name={"endMonth"}
                placeholder="Month"
                defaultValue={{
                  label: values?.endMonth,
                  values: values?.endMonth,
                }}
                options={monthsOptions}
                setFieldValue={setFieldValue}
                isDisabled={values?.workingHere}
              />
              {errors?.endMonth && touched?.endMonth && (
                <p className="text-xs ml-4 text-red-500">
                  * {errors?.endMonth}
                </p>
              )}
            </div>
            <div>
              <CustomSelect
                name={"endYear"}
                placeholder="Year"
                defaultValue={{
                  label: values?.endYear,
                  values: values?.endYear,
                }}
                options={yearOptions}
                setFieldValue={setFieldValue}
                isDisabled={values?.workingHere}
              />
              {errors?.endYear && touched?.endYear && (
                <p className="text-xs ml-4 text-red-500">* {errors?.endYear}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <p className="ml-1 font-semibold">Currently work here</p>
        <Checkbox
          onChange={(e) => setFieldValue("workingHere", e.target.checked)}
          checked={values?.workingHere}
          value={values?.workingHere}
        />
      </div>
    </div>
  );
};

const yearOptions = [
  { label: "2023", value: "2023" },
  { label: "2022", value: "2022" },
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" },
  { label: "2017", value: "2017" },
  { label: "2016", value: "2016" },
  { label: "2015", value: "2015" },
  { label: "2014", value: "2014" },
  { label: "2013", value: "2013" },
  { label: "2012", value: "2012" },
  { label: "2011", value: "2011" },
  { label: "2010", value: "2010" },
  { label: "2009", value: "2009" },
  { label: "2008", value: "2008" },
  { label: "2007", value: "2007" },
  { label: "2006", value: "2006" },
  { label: "2005", value: "2005" },
  { label: "2004", value: "2004" },
  { label: "2003", value: "2003" },
  { label: "2002", value: "2002" },
  { label: "2001", value: "2001" },
  { label: "2000", value: "2000" },
  { label: "1999", value: "1999" },
  { label: "1998", value: "1998" },
  { label: "1997", value: "1997" },
  { label: "1996", value: "1996" },
  { label: "1995", value: "1995" },
  { label: "1994", value: "1994" },
  { label: "1993", value: "1993" },
  { label: "1992", value: "1992" },
  { label: "1991", value: "1991" },
  { label: "1990", value: "1990" },
  { label: "1989", value: "1989" },
  { label: "1988", value: "1988" },
  { label: "1987", value: "1987" },
  { label: "1986", value: "1986" },
  { label: "1985", value: "1985" },
  { label: "1984", value: "1984" },
  { label: "1983", value: "1983" },
  { label: "1982", value: "1982" },
  { label: "1981", value: "1981" },
  { label: "1980", value: "1980" },
  { label: "1979", value: "1979" },
  { label: "1978", value: "1978" },
  { label: "1977", value: "1977" },
  { label: "1976", value: "1976" },
  { label: "1975", value: "1975" },
];


const monthsOptions = [
  { label: "Jan", value: "Jan" },
  { label: "Feb", value: "Feb" },
  { label: "Mar", value: "Mar" },
  { label: "Apr", value: "Apr" },
  { label: "May", value: "May" },
  { label: "Jun", value: "Jun" },
  { label: "Jul", value: "Jul" },
  { label: "Aug", value: "Aug" },
  { label: "Sep", value: "Sep" },
  { label: "Oct", value: "Oct" },
  { label: "Nov", value: "Nov" },
  { label: "Dec", value: "Dec" },
];

export default CustomStartEndDateRange;
