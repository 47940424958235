import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentStep, selectStatus } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { REGISTRATION_STEP, STATUS_CODES } from "../../utils/Constants";
import { CircularProgress } from "@mui/material";
import SignUpForm from "./SignUpForm";
import OtpStep from "./OtpStep";
import { useFormik } from "formik";
import * as Yup from "yup";
import { OtpSent } from "../../redux/auth/apiSlice";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const currentStep = useSelector(selectCurrentStep);
  const status = useSelector(selectStatus);

  const { handleChange, errors, values, handleSubmit, touched, resetForm } =
    useFormik({
      initialValues: { email: "", password: "", rePassword: "", name: "" },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        password: Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one special character, and one digit"
          )
          .required("Password is required"),
        rePassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Please Re Enter your password"),
        name: Yup.string().required("Name is required"),
      }),
      onSubmit: (values) => {
        setFormData(values);
        dispatch(OtpSent({ email: values.email }));
      },
    });

  useEffect(() => {
    if (currentStep === REGISTRATION_STEP.STEP_REGISTERED_SUCCESSFULLY) {
      navigate("/");
    }
  }, [currentStep]);

  return status === STATUS_CODES.PENDING ? (
    <div className="flex justify-center items-center h-full">
      <CircularProgress size={40} />
    </div>
  ) : (
    <div className="">
      {currentStep === "" && (
        <SignUpForm
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          touched={touched}
          values={values}
        />
      )}
      {currentStep === REGISTRATION_STEP.STEP_OTP && (
        <OtpStep formData={formData} />
      )}
    </div>
  );
};

export default SignUp;
