import React from "react";
import AccountDetailsSection from "./AccountDetailsSection";
import AcademicProfile from "./AcademicProfile";
import JobProfile from "./JobProfile";
import SocialPreferences from "./SocialPreferences";
import ChangeEmail from "./ChangeEmail";
import UpdatePhoneNumber from "./UpdatePhoneNumber";
import ChangePassword from "./ChangePassword";

const ProfileDrawerRightPanel = ({ currentTab }) => {
  const renderOptions = () => {
    switch (currentTab) {
      case "account_details":
        return (
          <AccountDetailsSection />
        );
      case "academic_profile":
        return <AcademicProfile />;
      case "job_profile":
        return <JobProfile />;
      case "social_preferences_contributions":
        return <SocialPreferences />;
      case "change_email":
        return <ChangeEmail />;
      case "update_phone_number":
        return <UpdatePhoneNumber />;
      case "change_password":
        return <ChangePassword />;
      default:
        return null;
    }
  };
  return <div className="grow">{renderOptions()}</div>;
};

export default ProfileDrawerRightPanel;
