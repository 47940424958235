import React from "react";
import Arrow from "../../static/Images/AboutUs/Arrow.png";
import browserLayout from "../../static/Images/AboutUs/browserLayout.png";
import laptop from "../../static/Images/AboutUs/laptop.png";
import Medal from "../../static/Images/AboutUs/Medal.png";
import RecArrow from "../../static/Images/AboutUs/RecArrow.png";
import Rocket from "../../static/Images/AboutUs/Rocket.png";
import StandingPerson from "../../static/Images/AboutUs/StandingPerson.png";
import Wallet from "../../static/Images/AboutUs/Wallet.png";

const WhatYouCanExpect = () => {
  return (
    <div className="mt-20 flex flex-col items-center xl:px-[3%] ">
      <p className="text-4xl font-semibold text-center lg:text-3xl mb:text-2xl">
      Ready, Set, Maangler: Your Ultimate Learning Launchpad
      </p>
      <div className="grid grid-cols-3 gap-9 mt-10 md:grid-cols-2 mb:grid-cols-1  ">
        {cardData.map((e, i) => (
          <Card key={i} title={e.title} image={e.image} />
        ))}
      </div>
    </div>
  );
};

const Card = ({ title, image }) => (
  <div className="flex space-x-2 border py-5 px-3 bg-white rounded-lg items-center font-semibold shadow max-w-[330px] transition ease-in-out duration-500 hover:scale-105 cursor-pointer">
    <img src={image} alt="" className="w-12 h-12 object-contain" />
    <p>{title}</p>
  </div>
);

const cardData = [
  {
    title: "A meticulously crafted, industry-vetted curriculum",
    image: Arrow,
  },
  {
    title: "Engaging live classes hosted by seasoned mentors",
    image: laptop,
  },
  {
    title: "Hyper-personalised, hands-on learning experience ",
    image: browserLayout,
  },
  {
    title: "Opportunities for lucrative paid internships at top companies",
    image: Wallet,
  },
  {
    title: "Elevate your resume with insights from our experts",
    image: Rocket,
  },
  {
    title: "Connect and collaborate within a lively and supportive community",
    image: RecArrow,
  },
  {
    title: "Get regular, personalised one-on-one mentorship sessions",
    image: StandingPerson,
  },
  {
    title: "Guidance from over 32 mentors from top product companies",
    image: Medal,
  },
];

export default WhatYouCanExpect;
