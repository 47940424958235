import React from "react";
import AboutUsFrame from "../../static/Images/AboutUs/AboutUsFrame.png";
import people from "../../static/Icons/people.png";
import threeLines from "../../static/Icons/threeLines.png";
import ringLeft from "../../static/Images/AboutUs/ringleft.png";
import ringright from "../../static/Images/AboutUs/ringright.png";
import { Link } from "react-router-dom";

const BookClassesSection = () => {
  return (
    <div
      className="mt-20 flex items-center justify-between py-10"
      style={{
        backgroundImage: `url(${AboutUsFrame})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div>
        <img src={ringLeft} alt="" />
      </div>
      <div className="bg-white relative border shadow-lg rounded-3xl flex flex-col items-center space-y-6 py-7 px-10 w-[460px] z-10 lg:w-[390px] lg:px-5 md:w-[340px] sm:w-[400px] mb:w-[300px]">
        <div className="relative flex items-center justify-center w-[300px] md:w-[280px]">
          <p className="text-2xl font-semibold items-center">
            Book a live class for Free
          </p>
          <img
            src={threeLines}
            alt="threeLines"
            className="w-8 absolute right-0 bottom-2 "
          />
        </div>
        <input
          className="bg-gray-100 focus:outline-none px-4 py-3 rounded-xl w-full text-gray-500 text-sm"
          placeholder="Enter Name"
        />
        <input
          className="bg-gray-100 focus:outline-none px-4 py-3 rounded-xl w-full text-gray-500 text-sm"
          placeholder="Enter Mail ID"
        />
        <input
          className="bg-gray-100 focus:outline-none px-4 py-3 rounded-xl w-full text-gray-500 text-sm"
          placeholder="Enter Phone number"
        />
        <div className="flex items-center space-x-2">
          <img src={people} alt="people" className="w-5" />
          <p className="text-red-400 font-semibold lg:text-sm">
            Limited seats left
          </p>
        </div>
        <button className="bg-primary px-8 py-2 text-white rounded-3xl hover:bg-primaryDark lg:text-sm">
          Book Free Class
        </button>
        <div>
          <p className="text-gray-500 text-center lg:text-sm">
            By continuing you agree to Maangler's
          </p>
          <p className="text-primary text-center lg:text-sm">
            <Link to={"/terms-of-use"}> Terms of service</Link>{" "}
            <span className="text-black">and</span>{" "}
            <Link to={"/privacy-policy"}>Privacy Policy</Link>
          </p>
        </div>
        {/* <img alt="" src={heroCardBg} className="absolute -right-20 -z-20 "/> */}
      </div>
      <div>
        <img src={ringright} alt="" />
      </div>
    </div>
  );
};

export default BookClassesSection;
