import React from "react";
import { Instagram, Twitter, YouTube } from "@mui/icons-material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import whatsapp from "../static/Icons/whatsapp.png";

const ComingSoon = () => {
  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center text-center">
      <div className="text-5xl text-white font-bold mb-8 animate-pulse">
        <h1>Coming Soon</h1>
      </div>
      <div className="text-white text-lg mb-8 p-5 ">
        <p>We're working hard to bring you something amazing. Stay tuned!</p>
      </div>
      <div className="mt-6 flex text-white mx-auto">
        <div className="space-x-5 flex items-center">
          {/* <a
                        href={"https://www.linkedin.com/company/maangler/"}
                        target="_blank"
                        rel="noreferrer"
                        
                    >
                    <LinkedInIcon style={{ fontSize: 40 }} />
                    </a>
                    <a
                        href={"https://twitter.com/Maangler"}
                        target="_blank"
                        rel="noreferrer"
                        
                    >
                        <Twitter style={{ fontSize: 40 }}  />
                    </a>
                    <a
                        href={"https://www.youtube.com/@maangler1164/"}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <YouTube style={{ fontSize: 40 }} />
                    </a>
                    <a
                        href={"https://www.instagram.com/coders_place_/"}
                        target="_blank"
                        rel="noreferrer"
                        className="text-xl"
                    >
                        <Instagram style={{ fontSize: 40 }} />
                    </a> */}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/8497014749?text=I%20want%20to%20enroll%20for%20the%20next%20Super%2030%20Batch"
          >
            <div className="flex space-x-1 items-center">
              <span className="mr-2 text-lg mb-1">
                <img height={25} width={25} src={whatsapp}></img>
              </span>
              <p>WhatsApp Us</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
