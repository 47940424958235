import React, { useState } from "react";
import Select from "react-select";

const AddSkillSection = ({ setShowAddSkills, setJobProfileData }) => {
  const [skillData, setSkillData] = useState({
    title: "",
    years: "",
    months: "",
  });
  return (
    <div className="border bg-white px-2 py-3 rounded-xl shadow-md relative mt-2">
      {/* <button
        className="ml-auto mb-1 border block text-xs py-[1px] px-[6px] rounded-full border-primary text-primary bg-white font-semibold right-2 top-2"
       // onClick={closeHandler}
      >
        x
      </button> */}
      <div className="flex space-x-2">
        <input
          placeholder="Skill"
          className="bg-gray-200 w-1/2 px-4 py-[10px] text-sm rounded-3xl focus:outline-primary"
          onChange={(e) =>
            setSkillData((prev) => ({ ...prev, title: e.target.value }))
          }
        />
        <Select
          //defaultValue={defaultValue?.label &&  defaultValue}
          options={yearOptions}
          styles={customStyles}
          menuPlacement="auto"
          onChange={(e) => {
            setSkillData((prev) => ({ ...prev, years: e.value }));
          }}
          placeholder={"Years"}
        />
        <Select
          //defaultValue={defaultValue?.label &&  defaultValue}
          options={monthsOptions}
          styles={customStyles}
          menuPlacement="auto"
          onChange={(e) => {
            setSkillData((prev) => ({ ...prev, months: e.value }));
          }}
          placeholder={"Months"}
        />
      </div>
      <div className="flex space-x-2 mt-2 pl-1">
        <button
          className="w-[100px] bg-primary py-1 rounded-2xl text-white text-sm"
          onClick={() => {
            setJobProfileData((prev) => ({
              ...prev,
              skills: [...prev.skills, skillData],
            }));
            setShowAddSkills(false);
          }}
        >
          Add
        </button>
        <button
          className="w-[100px] border-primary py-1 rounded-2xl text-primary border text-sm"
          onClick={() => setShowAddSkills(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width:'90px',
    borderRadius: "24px",
    backgroundColor: "rgb(229, 231, 235)",
    border: state.isFocused ? `1px solid #32A3C5` : "none",
    fontSize: "14px",
    "&:hover": {
      border: "none",
    },
  }),
};

const yearOptions = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
];
const monthsOptions = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
];

export default AddSkillSection;
