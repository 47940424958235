import { Avatar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUserData } from "../redux/profile/profileSlice";

const Profile = () => {
  const data = useSelector(selectUserData);
  const profileImage = data?.account?.profileImage;
  return (
    <div className="cursor-pointer">
      {profileImage ? (
        <img src={profileImage} alt="" className="w-[40px] h-[40px] rounded-full " />
      ) : (
        <Avatar />
      )}
    </div>
  );
};

export default Profile;
