import { createSlice } from "@reduxjs/toolkit";
import { getProfileData, putUserProfile } from "../auth/apiSlice";
import { STATUS_CODES } from "../../utils/Constants";

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    userData: null,
    status: "",
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
  extraReducers: {
    // ^ Pending
    [getProfileData.pending]: (state, action) => {
      state.status = STATUS_CODES.PENDING;
    },
    [putUserProfile.pending]: (state, action) => {
      state.status = STATUS_CODES.PENDING;
    },

    // ^ Fulfilled
    [getProfileData.fulfilled]: (state, action) => {
      state.status = STATUS_CODES.FULFILLED;
      state.userData = action.payload;
    },
    [putUserProfile.fulfilled]: (state, action) => {
      state.status = STATUS_CODES.FULFILLED;
      state.userData = action.payload;
    },

    // ^ Rejected
    [getProfileData.rejected]: (state, action) => {
      state.status = STATUS_CODES.REJECTED;
    },
    [putUserProfile.rejected]: (state, action) => {
      state.status = STATUS_CODES.REJECTED;
    },
  },
});

export const { setUserData } = profileSlice.actions;
export const selectStatusProfile = (state) => state.profile.status;
export const selectUserData = (state) => state.profile.userData;
