import { Avatar } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadImage } from "../redux/auth/apiSlice";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const formData = new FormData();
  const [editImage, setEditImage] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;

      if (fileType !== "image/jpeg" && fileType !== "image/png") {
        alert("Please upload a JPEG or PNG image.");
        return;
      }
      const fileSize = file.size / 1024;
      if (fileSize > 500) {
        alert("Please upload an image smaller than 500kb");
      }
      if (fileType === "image/png" && fileSize < 500) {
        setSelectedImage(file);
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
        setEditImage(false);
      }
    }
  };

  const handleSave = () => {
    formData.append("image", selectedImage);
    dispatch(uploadImage(formData));
  };
 // console.log("hi 2,", formData);

  return (
    <div className="h-[50dvh] px-[5%]   flex">
      {/* <p className="mt-10 text-3xl font-semibold text-primary mb-4">Profile</p> */}
      <div className="flex flex-col items-center w-fit border-r pr-10">
        {
          <div className={`edit mt-10`}>
            {selectedImage === null && (
              <Avatar sx={{ width: "180px", height: "180px" }} />
            )}
            <input
              type="file"
              className="w-full focus:outline-none"
              accept=".png"
              onChange={handleImageUpload}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
          </div>
        }
        {!editImage && (
          <img
            src={selectedImage}
            alt=""
            className="w-[180px] h-[180px] border rounded-full p-3"
          />
        )}
        <button
          className="text-sm px-4 py-2 bg-primary rounded-xl text-white mt-6 ml-6 w-[150px]"
          onClick={handleFileSelect}
        >
          Change Photo
        </button>
        <button
          className="text-sm px-4 py-2 bg-primary rounded-xl text-white mt-2 ml-6 w-[150px]"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
      <div className="mt-10 pl-10 space-y-6">
        <div>
          <p className="font-semibold mb-1">Name: </p>
          <input
            className="w-[300px] bg-gray-200 px-4 py-3 rounded text-sm focus:outline-none"
            placeholder="Test Name"
          />
        </div>
        <div>
          <p className="font-semibold mb-1">Email: </p>
          <input
            className="w-[300px] bg-gray-200 px-4 py-3 rounded text-sm focus:outline-none"
            placeholder="Test Email"
          />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
