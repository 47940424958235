export const graduationYearOptions = [
  { label: "2033", value: "2033" },
  { label: "2032", value: "2032" },
  { label: "2031", value: "2031" },
  { label: "2030", value: "2030" },
  { label: "2029", value: "2029" },
  { label: "2028", value: "2028" },
  { label: "2027", value: "2027" },
  { label: "2026", value: "2026" },
  { label: "2025", value: "2025" },
  { label: "2024", value: "2024" },
  { label: "2023", value: "2023" },
  { label: "2022", value: "2022" },
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" },
  { label: "2017", value: "2017" },
  { label: "2016", value: "2016" },
  { label: "2015", value: "2015" },
  { label: "2014", value: "2014" },
  { label: "2013", value: "2013" },
  { label: "2012", value: "2012" },
  { label: "2011", value: "2011" },
  { label: "2010", value: "2010" },
  { label: "2009", value: "2009" },
  { label: "2008", value: "2008" },
  { label: "2007", value: "2007" },
  { label: "2006", value: "2006" },
  { label: "2005", value: "2005" },
  { label: "2004", value: "2004" },
  { label: "2003", value: "2003" },
  { label: "2002", value: "2002" },
  { label: "2001", value: "2001" },
  { label: "2000", value: "2000" },
  { label: "1999", value: "1999" },
  { label: "1998", value: "1998" },
  { label: "1997", value: "1997" },
  { label: "1996", value: "1996" },
  { label: "1995", value: "1995" },
  { label: "1994", value: "1994" },
  { label: "1993", value: "1993" },
  { label: "1992", value: "1992" },
  { label: "1991", value: "1991" },
  { label: "1990", value: "1990" },
  { label: "1989", value: "1989" },
  { label: "1988", value: "1988" },
  { label: "1987", value: "1987" },
  { label: "1986", value: "1986" },
  { label: "1985", value: "1985" },
  { label: "1984", value: "1984" },
  { label: "1983", value: "1983" },
  { label: "1982", value: "1982" },
  { label: "1981", value: "1981" },
  { label: "1980", value: "1980" },
  { label: "1979", value: "1979" },
  { label: "1978", value: "1978" },
  { label: "1977", value: "1977" },
  { label: "1976", value: "1976" },
  { label: "1975", value: "1975" },
  { label: "1974", value: "1974" },
  { label: "1973", value: "1973" },
  { label: "1972", value: "1972" },
  { label: "1971", value: "1971" },
  { label: "1970", value: "1970" },
  { label: "1969", value: "1969" },
  { label: "1968", value: "1968" },
  { label: "1967", value: "1967" },
  { label: "1966", value: "1966" },
  { label: "1965", value: "1965" },
  { label: "1964", value: "1964" },
];


export const yearsOfExperienceOptions = [
  { label: "0 Years", value: "0 Years" },
  { label: "1 Years", value: "1 Years" },
  { label: "2 Years", value: "2 Years" },
  { label: "3 Years", value: "3 Years" },
  { label: "4 Years", value: "4 Years" },
  { label: "5 Years", value: "5 Years" },
  { label: "6 Years", value: "6 Years" },
  { label: "7 Years", value: "7 Years" },
  { label: "8 Years", value: "8 Years" },
  { label: "9 Years", value: "9 Years" },
  { label: "10 Years", value: "10 Years" },
  { label: "11 Years", value: "11 Years" },
  { label: "12 Years", value: "12 Years" },
  { label: "13 Years", value: "13 Years" },
  { label: "14 Years", value: "14 Years" },
  { label: "15 Years", value: "15 Years" },
  { label: "16 Years", value: "16 Years" },
  { label: "17 Years", value: "17 Years" },
  { label: "18 Years", value: "18 Years" },
  { label: "19 Years", value: "19 Years" },
  { label: "20 Years", value: "20 Years" },
  { label: "21 Years", value: "21 Years" },
  { label: "22 Years", value: "22 Years" },
  { label: "23 Years", value: "23 Years" },
  { label: "24 Years", value: "24 Years" },
  { label: "25 Years", value: "25 Years" },
  { label: "26 Years", value: "26 Years" },
  { label: "27 Years", value: "27 Years" },
  { label: "28 Years", value: "28 Years" },
  { label: "29 Years", value: "29 Years" },
  { label: "30 Years", value: "30 Years" },
  { label: "31 Years", value: "31 Years" },
  { label: "32 Years", value: "32 Years" },
  { label: "33 Years", value: "33 Years" },
  { label: "34 Years", value: "34 Years" },
  { label: "35 Years", value: "35 Years" },
  { label: "36 Years", value: "36 Years" },
  { label: "37 Years", value: "37 Years" },
  { label: "38 Years", value: "38 Years" },
  { label: "39 Years", value: "39 Years" },
  { label: "40 Years", value: "40 Years" },
  { label: "41 Years", value: "41 Years" },
  { label: "42 Years", value: "42 Years" },
  { label: "43 Years", value: "43 Years" },
  { label: "44 Years", value: "44 Years" },
  { label: "45 Years", value: "45 Years" },
  { label: "46 Years", value: "46 Years" },
  { label: "47 Years", value: "47 Years" },
  { label: "48 Years", value: "48 Years" },
  { label: "49 Years", value: "49 Years" },
];

export const degreeOptions = [
  { label: "BE/B.Tech/BS", value: "BE/B.Tech/BS" },
  { label: "ME/M.Tech", value: "ME/M.Tech" },
  { label: "Dual degree - BE + ME", value: "Dual degree - BE + ME" },
  { label: "PhD", value: "PhD" },
  { label: "MS", value: "MS" },
  { label: "MBA", value: "MBA" },
  { label: "MCA/BCA", value: "MCA/BCA" },
  { label: "BE + MBA", value: "BE + MBA" },
  { label: "Other", value: "Other" },
];

export const branchOptions = [
  { label: "Computer Science", value: "Computer Science" },
  { label: "Information Technology", value: "Information Technology" },
  { label: "Mathematics and Computing", value: "Mathematics and Computing" },
  { label: "Electronics", value: "Electronics" },
  { label: "Mechanical", value: "Mechanical" },
  { label: "MBA", value: "MBA" },
  { label: "Electrical", value: "Electrical" },
  { label: "Other", value: "Other" },
];
