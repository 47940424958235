import clsx from "clsx";
import React from "react";
import threeLines from "../../static/Icons/threeLines.png";

const CallbackCard = () => {
  return (
    <div
      className={clsx(
        "bg-white relative  shadow-2xl  flex flex-col items-center space-y-6 py-7 px-5 w-[460px] z-10 lg:w-[390px] lg:px-3 sm:px-2 md:w-[340px] sm:w-[400px] mb:w-[300px] rounded-3xl"
      )}
    >
      <div className="relative flex items-center justify-center w-[300px] md:w-[280px]">
        <p className="text-3xl font-semibold items-center text-center">
          Request a Callback
        </p>
        <img
          src={threeLines}
          alt="threeLines"
          className="w-8 absolute -right-2 bottom-5 "
        />
      </div>
      <div className="w-full">
        <input
          className={clsx(
            "bg-gray-100 focus:outline-none px-4 py-3 rounded-3xl w-full text-gray-500 text-sm border"
          )}
          placeholder="Enter Name"
          type="text"
        />
      </div>
      <div className="w-full">
        <input
          className={clsx(
            "bg-gray-100 focus:outline-none px-4 py-3 rounded-3xl w-full text-gray-500 text-sm border"
          )}
          placeholder="Enter Official Mail ID"
          type="email"
        />
      </div>
      <div className="w-full">
        <input
          className={clsx(
            "bg-gray-100 focus:outline-none px-4 py-3 rounded-3xl w-full text-gray-500 text-sm border"
          )}
          placeholder="Enter Phone number"
          type="tel"
          pattern="[0-9]*"
        />
      </div>
      <div className="w-full">
        <input
          className={clsx(
            "bg-gray-100 focus:outline-none px-4 py-3 rounded-3xl w-full text-gray-500 text-sm border"
          )}
          placeholder="Institution Name"
          type="tel"
          pattern="[0-9]*"
        />
      </div>
      <div className="w-full">
        <input
          className={clsx(
            "bg-gray-100 focus:outline-none px-4 py-3 rounded-3xl w-full text-gray-500 text-sm border"
          )}
          placeholder="Designation"
          type="tel"
          pattern="[0-9]*"
        />
      </div>
      <button
        type="submit"
        className="bg-primary  py-3 text-white rounded-3xl hover:bg-primaryDark lg:text-sm hover:shadow-primary hover:shadow w-[200px] "
      >
        <p>Request a Callback</p>
      </button>
    </div>
  );
};

export default CallbackCard;
