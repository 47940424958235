import React, { useEffect, useRef } from "react";

export const NumberCounter = ({ targetNumber, interval }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    let counterInterval;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          let currentNumber = 0;
          counterInterval = setInterval(() => {
            if (currentNumber < targetNumber) {
              currentNumber++;
              if (containerRef.current) {
                containerRef.current.innerText = currentNumber;
              }
            } else {
              clearInterval(counterInterval);
            }
          }, interval);

          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.7 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      // Check if containerRef.current is not null before calling unobserve and clearInterval
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      clearInterval(counterInterval);
    };
  }, [targetNumber]);

  return <div ref={containerRef}>0</div>;
};

export default NumberCounter;
