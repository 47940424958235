import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import heroGirl from "../../static/CampusAmbassador/heroGirl.png";
import topGirl from "../../static/CampusAmbassador/topGirl.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../global/CustomInput";
import axios from "axios";
import { toast } from "react-toastify";

const AddAmbassadorCard = ({ people, threeLines, type = "cross", onClose }) => {
  const [loading, setLoading] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        name: "",
        collegeName: "",
        email: "",
        phoneNumber: "",
        graduationYear: "",
        collegeCity: "",
        ugStream: "",
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Name is required"),
        collegeName: Yup.string().required("College Name is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        phoneNumber: Yup.string()
          .matches(phoneRegex, "Invalid phone number")
          .required("Phone is required"),
        graduationYear: Yup.string().required("Graduation Year is required"),
        collegeCity: Yup.string().required("College City is required"),
        ugStream: Yup.string().required("Ug Stream is required"),
      }),
      onSubmit: async (values) => {
        submitHandler(values);
        resetForm();
        setLoading(true);
        await axios.post(
          "https://maangler-c0b98-default-rtdb.firebaseio.com/campusAmbasdor.json",
          values
        );
        setLoading(false);
        toast.success("Your form have been submitted");
      },
    });
  // const formDataHandler = (event, type) => {
  //   const value = event.target.value;
  //   if (type === "phone") {
  //     if (regexNumber.test(value) || value === "") {
  //       console.log("test", regexNumber.test(value));
  //       setFormData((prev) => ({ ...prev, [type]: value }));
  //     }
  //   } else {
  //     setFormData((prev) => ({ ...prev, [type]: value }));
  //   }
  // };

  // useEffect(() => {
  //   toast.success("Your form have been submitted");
  // }, []);

  const submitHandler = async (event) => {
    // if (
    //   phoneRegex.test(formData.phone) &&
    //   emailRegex.test(formData.email) &&
    //   formData.name !== ""
    // ) {
    //   setLoading(true);
    //   const res = await fetch(
    //     "https://maangler-1eb9b-default-rtdb.firebaseio.com/userDataRecords.json",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         ...formData,
    //       }),
    //     }
    //   );
    //   setLoading(false);
    //   setFormData({ name: "", email: "", phone: "" });
    //   setOpenDialog(true);
    //   setValidateMessage({ name: "", email: "", phone: "" });
    // } else {
    //   setValidateMessage((prev) => ({
    //     phone: phoneRegex.test(formData.phone)
    //       ? ""
    //       : "*Please Enter Valid Phone Number",
    //     email: emailRegex.test(formData.email)
    //       ? ""
    //       : "*Please Enter Valid Email",
    //     name: formData.name !== "" ? "" : "*Please Enter Name",
    //   }));
    // }
  };

  return (
    <div className="sm:mt-44">
      <img
        src={heroGirl}
        alt=""
        className="absolute z-[100] -left-[23%] xl:-left-[25%] lg:-left-[31%] sm:hidden "
      />
      <img
        src={topGirl}
        alt=""
        className="absolute z-[100] hidden sm:block left-[110px] -top-[18px] mb:left-[69px]"
      />

      <div
        className={clsx(
          type === "cross" ? "" : "border rounded-3xl",
          "bg-white relative shadow-lg  flex flex-col items-center space-y-6 py-7 px-10 w-[520px] z-10 lg:w-[390px] lg:px-5 md:w-[340px] sm:w-[400px] mb:w-[300px] xl:w-[480px]"
        )}
      >
        <div className="grid grid-cols-2 gap-4 w-full mb:grid-cols-1">
          {/* {FormDetails.map((item, index) => (
            <div className="py-1">
              <Input placeholder={item.name} key={item.name} />
            </div>
          ))} */}
          <div>
            <CustomInput
              className="py-[14px] rounded-2xl text-black"
              placeholder={"Enter Name"}
              name={"name"}
              error={errors.name}
              value={values.name}
              touched={touched.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <CustomInput
              className="py-[14px] rounded-2xl text-black"
              placeholder={"Enter College Name"}
              name={"collegeName"}
              error={errors.collegeName}
              value={values.collegeName}
              touched={touched.collegeName}
              onChange={handleChange}
            />
          </div>
          <div>
            <CustomInput
              className="py-[14px] rounded-2xl text-black"
              placeholder={"Enter Mail Id"}
              name={"email"}
              error={errors.email}
              value={values.email}
              touched={touched.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <CustomInput
              className="py-[14px] rounded-2xl text-black"
              placeholder={"Enter Phone Number"}
              name={"phoneNumber"}
              error={errors.phoneNumber}
              value={values.phoneNumber}
              touched={touched.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div>
            <CustomInput
              className="py-[14px] rounded-2xl text-black"
              placeholder={"Enter Graduation Year"}
              name={"graduationYear"}
              error={errors.graduationYear}
              value={values.graduationYear}
              touched={touched.graduationYear}
              onChange={handleChange}
            />
          </div>
          <div>
            <CustomInput
              className="py-[14px] rounded-2xl text-black"
              placeholder={"Enter College City"}
              name={"collegeCity"}
              error={errors.collegeCity}
              value={values.collegeCity}
              touched={touched.collegeCity}
              onChange={handleChange}
            />
          </div>
          <div>
            <CustomInput
              className="py-[14px] rounded-2xl text-black"
              placeholder={"UG Stream"}
              name={"ugStream"}
              error={errors.ugStream}
              value={values.ugStream}
              touched={touched.ugStream}
              onChange={handleChange}
            />
          </div>
        </div>
        <button
          type="submit"
          // onClick={(event) => {
          //   if (!loading) {
          //     submitHandler(event);
          //   }
          // }}
          disabled={loading}
          onClick={handleSubmit}
          className="bg-primary  py-3 text-white rounded-3xl hover:bg-primaryDark lg:text-sm hover:shadow-primary hover:shadow w-[300px] mb:w-full"
        >
          {loading ? (
            <CircularProgress
              size={20}
              sx={{ color: "white", margin: "auto" }}
            />
          ) : (
            <p>Become An Ambassordor</p>
          )}
        </button>
        <div>
          <p className="text-gray-500 text-center lg:text-sm text-sm">
            By continuing you agree to Maangler's
          </p>
          <p className="text-primary text-center lg:text-sm text-sm">
            Terms of service <span className="text-black">and</span> Privacy
            Policy
          </p>
        </div>
        {type === "cross" && (
          <button
            className="absolute text-xl -top-[4%] right-2" 
            onClick={onClose}
          >
            <CancelIcon />
          </button>
        )}
        {/* <img alt="" src={heroCardBg} className="absolute -right-[100px] -z-20 " /> */}
      </div>
      {/* <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <button
          className="ml-auto"
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          <CancelIcon />
        </button>
        <img
          src={Thankyou}
          alt="Image by storyset on Freepik"
          className="w-[350px]"
        />
        <p className="text-center pb-6 pt-4 text-sm">
          Thanks for applying for
          <span className="font-semibold">Super30 Batch</span> <br /> You will
          get a callback shortly
        </p>
      </Dialog> */}
      {/* <img alt="" src={heroCardBg} className="absolute z-[100] " /> */}
    </div>
  );
};

const Input = ({ value, onChange, type, placeholder }) => {
  return (
    <input
      className={clsx(
        "bg-gray-100 focus:outline-none px-4 py-3 rounded-3xl w-full text-gray-500 text-sm border "
      )}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      type={type}
      pattern="[0-9]*"
    />
  );
};

const FormDetails = [
  { name: "Enter Name" },
  { name: "Enter College Name" },
  { name: "Enter Mail Id" },
  { name: "Enter Phone Number" },
  { name: "Enter Graduation Year" },
  { name: "Enter College City" },
  { name: "UG Stream" },
];

const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
const regexNumber = /^[0-9\b]+$/;

export default AddAmbassadorCard;
