import React from "react";

const TermsOfUse = () => {
  const bg = "#f8faff";
  return (
    <div className="bg-white">
      <p className="pt-16 text-2xl font-semibold text-center">TERMS OF USE</p>
      <p className="font-semibold text-center text-lg">
        Welcome! Thank you for your interest in Maangler!
      </p>
      <div className="mt-2">
        <Container>
          <p>
            These Terms of Service (the “ToS”) applies with respect to the usage
            of the Platform (defined hereinafter) and the services offered by
            Maangler Edtech OPC Private Limited (“Maangler Edtech OPC Private Limited,
            “we”, or “us”), a OPC private limited company incorporated under the
            Companies Act, 2013, as amended and having its registered office at
            Office No.162, Satna, Nagod, Satna-485221, Madhya Pradesh. Maangler
            conducts its business under the brand titled “Maangler”, which is
            owned and operated by Maangler Edtech OPC Private limited. These Terms of
            Service shall mutatis mutandis apply to mobile apps, WhatsApp
            groups, Facebook groups, Instagram pages, Facebook pages,
            email/SMS/phone communications and other communication forums/media
            hosted by Maangler, which shall be deemed to be part of the Platform
            (defined hereinafter). Your are deemed to have accepted these ToS
            upon usage of Maangler Services (defined hereinafter). The terms
            contained in our privacy policy available at (“PrivacyPolicy”), or
            any other policy of Maangler Services are incorporated into these
            ToS by this reference. Your acceptance of the same will irrevocably
            and unconditionally bind you to comply with and abide by all the
            obligations and conditions stipulated herein (unless otherwise
            explicitly made optional). You will be assumed to continue
            consenting to these ToS until you explicitly withdraw your consent
            by notifying Maangler of the same in writing. If an executed
            agreement exists between You and Maangler in relation to the
            Maangler Services, any deviations from these ToS shall only apply
            when explicitly agreed in writing between the parties to such an
            agreement. IF YOU DO NOT AGREE TO BE BOUND BY ALL CONDITIONS/CLAUSES
            CAPTURED IN THESE TOS, PLEASE DO NOT USE THE PLATFORM OR SERVICES.
          </p>
        </Container>
        <Container type="blue">
          <SubHeading>Updation of Terms</SubHeading>
          <Content>
            <p>
              These ToS may be updated, amended, modified or revised by us from
              time to time. Each such updation or modification to the ToS will
              be communicated to you via email, through the Website or any other
              medium. Your continued use of the Maangler Services after any such
              changes shall constitute your consent to such changes. We
              encourage you to refer to these ToS from time to time to ensure
              that you are aware of any additions, revisions, amendments or
              modifications that we may have made.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading> Service(s) provided to Users</SubHeading>
          <Content>
            <p>
              Maangler allows its users to access various training programs on
              computer sciences designed by Maangler for undergraduates and
              professionals, including Super 30 Programs in Python, Java, Data
              Science, Full Stack Web Development, or any other program or
              application that Maangler introduces from time to time
              (hereinafter collectively referred to as “Service(s)” or “Maangler
              Service(s)” and each of these training programs shall be referred
              to as a “Program” and collectively as “Programs”). Maangler
              reserves the right, in its sole discretion, to add, change,
              suspend, or discontinue all or any part of the Maangler Services
              at any time. Your continued use of Maangler Services following the
              posting of any changes to the same (including the addition or
              removal of features) constitutes your acceptance of those changes.
              Any information you provide to us for the purposes of registering
              and setting up your account (as specified below) shall be
              considered as accurate, complete and updated. You are solely
              responsible for the activity that occurs on your account
              (including those of others authorised to access and operate your
              account) and for maintaining the security of your account and any
              information you input. We are not responsible for the accuracy or
              legitimacy of any information, data, or User Data uploaded or
              posted on the Platform. For the purposes of clarity, as long as
              your account is accessed with the correct login credentials and
              unless you notify us of any unauthorized access, you shall be
              solely responsible for all actions/defaults related to your
              account.
            </p>
            <p className="mt-2">
              In consideration of your acceptance of these ToS and if
              applicable, any subscription agreement (or any other similar
              agreement) executed between you and Maangler, Maangler grants the
              User a personal, non-exclusive, non-transferable,
              non-sublicensable, revocable limited license to access and use the
              Platform and Services, in accordance with the ToS.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading></SubHeading>
          <Content>
            <p></p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>Access to Maangler resources</SubHeading>
          <Content>
            <p>
              During your time with Maangler, you would have access to multiple
              people/ resources that help you learn better. The access policy
              may vary across those.
            </p>
            <p>Following is the policy for each of those resources:</p>
            <p>
              <span className="font-semibold">
                Class recordings / Recording of mentor sessions / Recording of
                TA sessions / Recording of masterclasses:
              </span>{" "}
              We guarantee access to all recordings till
              <span className="font-semibold">
                {" "}
                3 months post-completion
              </span>{" "}
              of your last mandatory class with Maangler (as part of the program
              you joined), subject to the terms herein (including without
              limitation those on termination).
              <br />
              <span className="font-semibold">Mentors:</span> You will have
              access to mentor guidance throughout the program you opted for.
              However, access to these Live sessions is not forever. In other
              words, you cannot get any Live mentor sessions post the last
              mandatory class time. <br />
              <span className="font-semibold">TA Support:</span> TA support is
              available to you throughout your Maangler journey even when you
              have paused your course. You can also seek help from the community
              in Discord/ Whatsapp groups and chances are that you will find
              help from peers / other learners (Disclaimer: Maangler does not
              guarantee a response from other learners/peers on these groups and
              other peer discussion forums) <br />
              <span className="font-semibold">Community Groups:</span> You will
              continue to have access to the community groups created by
              Maangler forever (unless for some reason, the platforms themselves
              cease to exist - for example, if Discord shuts down / Whatsapp
              shuts down, then Maangler does not have any liability).
              <br />
              <span className="font-semibold">Assignments / Notes:</span> You
              will continue to have access to your assignments and notes taken
              during the Maangler course forever.
              <br />
              <span className="font-semibold"> Career opportunities:</span>{" "}
              Learners at Maangler are connected to relevant job opportunities
              through the following channels:
              <br /> Community groups where an alumni/peer/mentor/TA might post
              job opportunities and can connect you if there is relevance.
              <br />
              Recruiters discover you through the Maangler Talent portal.
              <br />
              Finally, there are a set of companies that Maangler might work
              with directly to help them hire. In such an engagement, there is
              an internal Maangler team that helps facilitate connections to
              hiring companies. Help from this channel is not available once you
              have completed 3 months post-completion of your last mandatory
              class (as part of the program you enrolled in).
              <br /> It is important to note that Maangler does not take any
              placement/job/internship guarantee. More details are in the
              placement policy document.
              <br className="" /> Notwithstanding the above, Maangler may
              discontinue access to the resources set out herein for learners
              who have completed its Programs, provided that such learners will
              be given a 30-day notice to download all assignments attempted,
              code, and all notes taken. Maangler also has the right to withdraw
              access to the above resources if you withdraw from the course,
              breach the ToS set out herein, or in the event of termination or
              suspension of your account.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Course Pause & Reset Feature</SubHeading>
          <Content>
            <p>
              Our Programs’ pace and workload can be varied by the learners as
              per their needs. We have enabled a “Pause” feature through which a
              learner enrolled in the new Maangler Programs may opt to pause
              their program. During this pause period, access to previous
              recordings and doubt resolution will be available to the learner.
              No new lectures will be added during the pause period.
            </p>
            <p className="mt-2">
              Our Program also provides learners the opportunity to redo
              lectures as per their needs. We have enabled a “Reset” feature
              through which a learner enrolled in the new Maangler Programs may
              opt to reset their program. Using the “Reset” feature, learners
              can go back in increments of 30 days in their program.
            </p>
            <p className="mt-2">
              Every learner is granted 3 Complimentary credits with the program
              that they can use across both “Pause” and “Reset” features. One
              credit allows them to take the pause/reset for one time and can
              help them “Pause” OR “Reset the course for up to 30 days, provided
              that if a learner wants to use the Reset Feature any time after 90
              days from the date of the first class/session of the relevant
              Program, 2 credits will be required. If a learner needs additional
              “Credits” they shall be chargeable at an amount determined by
              Maangler in its sole discretion, and such amount may be updated
              from time to time, exclusive of applicable taxes, and shall be
              subject to Maangler’s specific approval. For the avoidance of
              doubt 1 credit is equivalent to INR 1,500. Maangler reserves the
              right to revise this amount from time to time.
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>Program Change</SubHeading>
          <Content>
            <p className="mt-2">
              Maangler allows a one time Program Change within 30 days, from the
              date of the first class/session of the relevant Program (such date
              of first class/session being
              <span className="font-semibold">“Onboarding”</span> date).The
              'Program Change' allows Students to migrate from Maangler Super 30
              Programs. Example: Super 30 Python to Super 30 Java and vice
              versa, and start the new course afresh.
            </p>
            <p className="mt-2">
              A learner can request for a Program Change within 10 days of his
              Onboarding at no additional cost. Any Program Change requested
              after the expiry of the aforesaid period will be for a fee of the
              higher of Delta Fees or INR 10,000.“Delta Fees” will mean the
              difference between the fees payable for the program the learner
              wants to change to (at the time of the request), and the fees of
              the learner’s existing program at the time of enrolment.
            </p>
            <p className="mt-2">
              No refunds will be permitted post the Program Change
              notwithstanding anything contained herein.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Support</SubHeading>
          <Content>
            <p>
              Maangler offers online support tools. Registered students can
              reach us on our Priority helpline number available on their
              dashboard. You can also contact our support team by emailing us at
              support@maangler.in. Maangler shall use commercially reasonable
              efforts to respond to your request for support, or to fix any
              problems you may be having, as is applicable. Any suggestions by
              Maangler to you regarding use of the Platform shall not be
              construed as a warranty.
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>Withdrawal and Refund Policy</SubHeading>
          <Content>
            <p>
              Please note that we do not offer refunds. However, we do provide
              the option to change programs. If you wish to make changes to your
              subscribed program, please follow the guidelines outlined below.
            </p>
            <p>
              <span className="font-semibold">Program Change Requests:</span>{" "}
              You have the flexibility to switch to a different program within
              the Maangler platform. To request a program change, please send an
              email to support@maangler.in with the subject line: Request for
              Program Change. We will process your request and provide you with
              further instructions. More details are in the program change
              document.
            </p>
            <p>
              <span className="font-semibold">Withdrawal Policy:</span> If you
              decide to withdraw from your current program, you can do so within
              the specified withdrawal window. However, please be aware that no
              refunds will be provided. Withdrawals outside this window will not
              be eligible for a refund or program change.
            </p>
            <p className="font-semibold">Important Notes:</p>
            <ul className="list-inside list-disc">
              <li>
                Any change in the program will be subject to availability.
              </li>
              <li>
                Refunds will not be granted for lack of usage or dissatisfaction
                with the program.
              </li>
              <li>
                Any fees paid by a third party on your behalf are your
                responsibility, even in the case of withdrawal.
              </li>
              <li>
                Upon withdrawal, your access to Maangler resources will be
                discontinued.
              </li>
            </ul>
            <p>
              For program changes, withdrawals, or any related inquiries, please
              contact us at support@maangler.in. Your satisfaction and learning
              experience are important to us, and we are here to assist you
              throughout your journey with Maangler.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Termination</SubHeading>
          <Content>
            <p>
              Maangler reserves the right to suspend or terminate your access to
              the Platform and the services with or without notice and to
              exercise any other remedy available under law, in cases where: you
              are in breach of any terms and conditions of this ToS
            </p>

            <p>
              Maangler is unable to verify or authenticate any information
              provided to Maangler by you; or
            </p>
            <p>
              Maangler has reasonable grounds for suspecting any illegal,
              fraudulent or abusive activity on your part.
            </p>
            <p>
              Once temporarily suspended, indefinitely suspended or terminated,
              you may not continue to use the Platform under the same account, a
              different account or re-register under a new account. Maangler may
              destroy and/or delete all the data and other information related
              to your account as per its discretion. All provisions of the ToS,
              which by their nature should survive termination, shall survive
              termination, including without limitation, warranty disclaimers,
              indemnity and limitations of liability.
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>Registration and Account Integrity</SubHeading>
          <Content>
            <p>
              As part of the registration process, you will need to accept our
              ToS, and also provide us with your name, your phone number, your
              email ID and in certain scenarios, contact information of your
              parents/guardian. We would not at any time request you to provide
              us with access to your password, unless you have given us explicit
              written permission to access your account pursuant to a request
              for assistance or support.
            </p>
            <p className="mt-2">
              It is your responsibility to ensure that the information you
              provide is accurate, secure, and not misleading. You cannot create
              an account username and password using (i) the names and
              information of another person; or (ii) using words that are the
              trademarks or the property of another party (including ours); or
              (iii) words that are vulgar, obscene or in any other way
              inappropriate. You agree and confirm that you will never use
              another User’s account nor provide access to your account to any
              third-party. You agree and acknowledge that you are solely
              responsible for the activities that occur on your account, and you
              shall keep your account password secure and not share the same
              with anyone. You must notify Maangler immediately of any breach of
              security or unauthorized use of your account. At no point in time
              will Maangler be liable for any losses caused by any unauthorized
              use of your account, and you shall solely be liable for the losses
              caused to Maangler or others due to such unauthorized use, if any.
            </p>
            <p className="mt-2">
              You shall be solely responsible for obtaining and maintaining any
              equipment or ancillary services necessary to connect to, access,
              receive the Maangler Services or use the Platform, including,
              without limitation, hardware devices, software, and other
              internet, wireless, broadband, phone or other mobile communication
              device connection services. You shall be solely responsible for
              ensuring that such equipment or ancillary services are compatible
              with the Platform, and you shall be responsible for all charges
              incurred in connection with the use of the Platform or Maangler
              Services in connection with all such equipment and ancillary
              services.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Use of your Information and User Data</SubHeading>
          <Content>
            <p>
              All User Data that you create, transmit, transfer to us, submit,
              display or otherwise make available while using the Maangler
              Services, should only be information that you own or have the
              right to use or share (as may be applicable). If you transfer any
              personally identifiable information to us, we will assume that you
              have informed the person concerned and procured the necessary
              consent for such transfer, sharing, processing and/or using the
              information on the Platform and/or in respect of the Services. We
              may use the above information as per our Privacy Policy and in
              accordance with applicable laws.
            </p>
            <p className="mt-2">
              While we make commercially reasonable efforts to ensure that the
              data stored on our servers is persistent and always available to
              our Users, we will not be responsible in the event of failure of
              the third-party servers or any other factors outside our
              reasonable control, that may cause the User Data to be permanently
              deleted, irretrievable, or temporarily inaccessible.
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>Third Party Links and Services</SubHeading>
          <Content>
            <p>
              We use multiple third-party service providers in order to power
              and provide you with the Maangler Services. In this process, we
              share the User Data with some of the third-party service
              providers, as is necessary to enable them to provide the requisite
              services. We will attempt to ensure that all our third-party
              service providers are bound by and adhere to the same obligations
              of confidentiality and data privacy as we guarantee to you. The
              Platform may include links to any third-party websites. Maangler
              has no control over, and assumes no responsibility for, the
              content, privacy policies, or practices of any third-party
              website. By using the Platform, you expressly release Maangler
              from any liability arising from your use of any third-party
              website or services.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Rules and Acceptable Usage Policy</SubHeading>
          <Content>
            <p>
              As a condition of use, you promise not to use the Maangler
              Services for any purpose that is prohibited by these ToS, by law,
              or other rules or policies implemented by us from time to time.
              The Maangler Services and Maangler Content is provided only for
              your own personal, non-commercial use. You agree to notify us
              immediately in case you observe any other User breaching the terms
              contained herein or any policy associated with the Programs.
            </p>
            <p className="mt-2">
              While using the Platform, you shall not:harass any third party
              including any Users or Customers;
            </p>
            <p className="mt-2">
              create an atmosphere of disharmony, hostility within student
              groups, or behave inappropriately with our TA/mentor/Instructors;
            </p>
            <p>
              use unethical methods during any assignment, test/quiz/exam
              conducted on the Platform or otherwise in relation to any Program
              (including creating multiple IDs to enter quizzes);
            </p>
            <p>
              share any Maangler Content (including any study materials or test
              materials), or your log in credentials and details, with anybody
              through any media;
            </p>
            <p>
              share solutions to assignments/test materials/quizzes or any other
              evaluation pertaining to the Platform, or use any unfair methods
              to complete assignments/test materials/quizzes or any other
              evaluation (repeated winnings may be flagged as a suspicious
              activity on the Platform, and may result in further scrutiny or
              suspension).
            </p>
            <p>
              Furthermore, you shall not (directly or indirectly): (i) take any
              action that imposes or may impose an unreasonable or
              disproportionately large load on Maangler’s (or its third party
              providers’) infrastructure; (ii) interfere or attempt to interfere
              with the proper working of the Platform; (iii) bypass any measures
              we may use to prevent or restrict access to the Maangler Services
              (or parts thereof); (iv) modify, translate, or otherwise create
              derivative works of any part of the Maangler Services or the
              Platform; or (v) copy, rent, download, extract, lease, distribute,
              or transfer any part of the Platform, Maangler Content or any
              third party User Data, unless Maangler specifically allows you to
              do so.
            </p>
            <p className="mt-2">
              Maangler has the right to terminate or suspend your account and
              access to the Program in the event of a breach of the Rules and
              Acceptable Usage Policy. Any rewards or prizes for quizzes or
              other competitions will be awarded at the sole discretion of
              Maangler, and Maangler may also cap/limit rewards to repeat
              winners at its discretion, to encourage participation. Maangler
              will not be responsible and shall have no liability in the event
              you provide incorrect information or payment details to Maangler).
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>
            Content Ownership and Copyright Conditions of Access
          </SubHeading>
          <Content>
            <p className="mt-2">
              The Platform and the Maangler Content, including but not limited
              to all text, graphics, user interfaces, visual interfaces, sounds,
              artwork and computer code are owned and controlled by Maangler
              and/or its licensors and the design, structure, selection,
              expression and arrangement of such content mentioned hereinabove
              is protected by copyright, patent and trademark laws, and other
              applicable laws on intellectual property rights. We reserve all
              rights, title, and ownership in and to the Platform and Maangler
              Content.
            </p>
            <p className="mt-2">
              Maangler’s logos, trademarks, and service marks that may appear on
              the Platform, including but not limited to “Maangler” are the
              property of Maangler and are protected under applicable laws on
              intellectual property rights.
            </p>
            <p className="mt-2">
              Your copying, distributing, displaying, reproducing, recording,
              downloading, modifying, or in any other way using of the Maangler
              Content in any manner not specified in these ToS, will be a
              violation of copyright and other intellectual property rights and
              Maangler reserves its rights under applicable law accordingly.
            </p>
            <p className="mt-2">
              You shall, at all times, remain the complete owner and retain all
              rights and title in and to the User Data. This includes assuming
              liability and responsibility in cases where the User Data breaches
              any conditions of confidentiality, any intellectual property
              rights, or any other third-party rights. By posting any User Data
              through the Maangler Services, you grant Maangler and its
              affiliates, a worldwide, non-exclusive, royalty-free, irrevocable,
              sub-licensable and transferable license to use, copy, reformat,
              modify, translate, display, sell, create derivative works and
              distribute such User Data for or in connection with the use of the
              Maangler Services.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Reviews and Feedback</SubHeading>
          <Content>
            <p>
              You agree that we may contact you through telephone, email, SMS,
              or any other electronic means of communication for the purpose of:
            </p>
            <p className="mt-2">
              Obtaining feedback in relation to the Platform or Maangler
              Services; and/or Resolving any complaints, information, or queries
              received from you regarding the same.
            </p>
            <p>
              Any feedback provided by you may be used by Maangler without any
              restriction or obligation to compensate you, and we are under no
              obligation to keep such feedback confidential.
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>
            Rights and Obligations relating to the Content
          </SubHeading>
          <Content>
            <p>You are prohibited from:</p>
            <p className="mt-2">
              violating or attempting to violate the integrity or security of
              the Platform or any content therein;
            </p>
            <p>
              transmitting any information (including messages and hyperlinks)
              on or through the Platform that is disruptive or competitive to
              the provision of our Services;
            </p>
            <p>
              distributing in any medium, any part of the Platform or the
              Maangler Content without Maangler’s prior written authorisation;
            </p>
            <p>
              reproducing, duplicating, copying, selling, reselling or
              exploiting for any commercial purpose any Maangler Content or any
              portion thereof, other than as expressly allowed under this ToS;
            </p>
            <p>
              using the Maangler Marks in connection with, or to transmit, any
              unsolicited communications or emails or for any other unauthorised
              purpose;
            </p>
            <p>
              intentionally submitting on the Platform any incomplete, false or
              inaccurate information;
            </p>
            <p>
              using any engine, software, tool, agent or other device or
              mechanism (such as spiders, robots etc.) to navigate or search the
              Platform;
            </p>
            <p>
              attempting to decipher, decompile, disassemble, alter, modify or
              reverse engineer any part of the Platform; copying or duplicating
              in any manner any of the Maangler Content or other information
              available from the Platform; posting or transmitting, or cause to
              be posted or transmitted, any communication or solicitation or
              other “phishing”, “pharming” or “whaling” message designed or
              intended to obtain password, account, personal information, or
              confidential information from any other User or any other third
              party whatsoever;
            </p>
            <p>
              using or launching any automated system, including without
              limitation, “robots,” “spiders,” or “offline readers,” that
              accesses the Platform in a manner that sends more request messages
              to Maangler’s servers in a given period of time than a human can
              reasonably produce in the same period by using a conventional
              on-line web browser; circumventing or disabling any digital rights
              management, usage rules, or other security features of the
              Platform; or allowing any third party to access the Platform
              through your account unless Maangler specifically allows you to do
              so. You shall not host, display, upload, modify, publish,
              transmit, update or share any information/content that
            </p>
            <p className="mt-2">
              belongs to another person and to which the User does not have any
              right; is grossly harmful, harassing, blasphemous, defamatory,
              obscene, pornographic, paedophilic, libellous, invasive of
              another’s privacy, hateful, or racially, ethnically objectionable,
              disparaging, relating or encouraging money laundering or gambling,
              or otherwise unlawful or inappropriate in any manner whatsoever;
              harm minors in any manner; infringes any patent, trademark,
              copyright or other proprietary rights; violates any law for the
              time being in force; deceives or misleads the addressee about the
              origin of such messages or communicates any information which is
              grossly offensive or menacing in nature; impersonates another
              person; contains software viruses or any other computer code,
              files or programs designed to interrupt, destroy or limit the
              functionality of any computer resource; threatens the unity,
              integrity, defence, security or sovereignty of India, friendly
              relations with foreign states, or public order or causes
              incitement to the commission of any cognisable offence or prevents
              investigation of any offence or is insulting any other nation. You
              understand that while using the Platform, you may be exposed to
              User Data pertaining to other Users, and that Maangler is not
              responsible for such User Data. You further understand and
              acknowledge that you may be exposed to User Data that is
              inaccurate, offensive, indecent, or objectionable, and you agree
              to waive, and hereby do waive, any legal or equitable rights or
              remedies you have or may have against Maangler in relation
              thereto.
            </p>
            <p className="mt-2">
              Maangler shall, upon obtaining knowledge by itself or been brought
              to actual knowledge in writing or through email about any breach
              of the above conditions, may temporarily suspend/delete your
              account, impose penalties, or take any other actions that Maangler
              may deem fit. We shall also be entitled to preserve such
              information and produce such records before governmental and/or
              judicial and/or regulatory authorities for investigation purposes
              as may be required by applicable law.
            </p>
            <p className="mt-2">
              In your use of the Platform, you will at all times comply with all
              applicable laws and regulations, and any code of conduct issued by
              Maangler, or supplemental/additional terms issued by Maangler. In
              case of non-compliance with any applicable laws, rules or
              regulations, or this ToS by a User, we shall have the right to
              immediately terminate your access or usage rights to the Platform
              and to remove non-compliant User Data from the Platform. Maangler
              shall not be liable to refund any fees/consideration collected
              from a Customer in case such Customer’s access to the Platform is
              terminated by Maangler for any reason detailed herein. In case of
              suspicion of any non-compliance with any applicable laws, rules or
              regulations, or this ToS by a User (including but not limited to
              the Tos relating to the Rules and Acceptable Usage Policy and the
              Rights and Obligations relating to the Content), Maangler shall
              have the right to suspend your access to relevant content, or the
              entire Platform for such time as may be deemed necessary,
              notwithstanding anything contain above.
            </p>
            <p className="mt-2">
              In the course of your usage of the Maangler Services, you may post
              reviews, comments, suggestions, ideas, questions and other
              content, or send any other communications as long as the content
              is not illegal, obscene, threatening, defamatory, invasive of
              privacy, infringing any third party’s intellectual property
              rights, or otherwise injurious to third parties or objectionable
              and does not consist of or contain software viruses, political
              campaigning, commercial solicitation, chain letters, mass mailings
              or any other form of spam. Further, you hereby grant Maangler a
              limited, royalty-free, worldwide, non-exclusive license to use the
              User Data (including the contents specified herein) in further
              developing the Platform and for the purpose of its marketing or
              promotional activities.
            </p>
            <p className="mt-2">
              Maangler reserves the right to make changes to Maangler Content
              including but not limited to course duration, curriculum, product,
              and policies from time to time due to constantly evolving industry
              scenarios and other reasons, without any notice to you.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Disclaimer</SubHeading>
          <Content>
            <p className="mt-2">
              THE MAANGLER SERVICES AND THE MAANGLER CONTENT ARE PROVIDED ON AN
              “AS IS” AND “AS AVAILABLE” BASIS AND ARE WITHOUT WARRANTY OF ANY
              KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE
              IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY
              ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE
              EXPRESSLY DISCLAIMED. MAANGLER AND ITS DIRECTORS, EMPLOYEES,
              AGENTS, SUPPLIERS, SPONSORS AND PARTNERS DO NOT WARRANT THAT: (A)
              THE MAANGLER SERVICES AND THE PLATFORM WILL BE SECURE, ERROR-FREE
              OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS
              OR ERRORS WILL BE CORRECTED; (C) ANY CONTENT OR SOFTWARE AVAILABLE
              AT OR THROUGH THE PLATFORM IS FREE OF VIRUSES OR OTHER HARMFUL
              COMPONENTS; OR (D) THE RESULTS OF USING THE PLATFORM WILL MEET
              YOUR REQUIREMENTS. THE MAANGLER SERVICES MAY BE SUBJECT TO
              LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE
              INTERNET AND ELECTRONIC COMMUNICATIONS. MAANGLER IS NOT
              RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE
              RESULTING FROM SUCH PROBLEMS. YOUR USE OF THE PLATFORM AND THE
              MAANGLER SERVICES IS SOLELY AT YOUR OWN RISK.
            </p>
            <p className="mt-2">
              You will be awarded a certificate of successful completion of a
              Program (“Program Certificate”) upon fulfilling the criteria and
              requirements of Maangler, which Maangler may update from time to
              time. The decision to award any such Program Certificate or other
              acknowledgement of successful completion of a Program, would be
              solely at Maangler’s discretion. Maangler may choose not to offer
              any such Program Certificate or other acknowledgement of
              successful completion, for some Programs. Maangler may decide at
              its sole discretion whether to provide a record concerning a
              Customer’s performance in a Program. The format of any
              certificate, credential or other acknowledgement, and of any
              performance, provided by Maangler relating to a Program will be
              determined by Maangler at its sole discretion and may vary as per
              the Program.
            </p>
            <p className="mt-2">
              Maangler has no obligation to have the Maangler Services, Maangler
              Content, Programs or Program Certificates recognized by or
              affiliated to any educational institution or accreditation
              organization. Any Programs offered in collaboration with a
              university/college or other partner (“Partner”) will be subject to
              the terms of such Partner and you agree to be bound by them, upon
              subscribing to such Programs. Maangler provides education related
              services through the Platform. The Platform or the Maangler
              Services MUST NOT be construed as a university. The Users
              understand and acknowledge that Maangler cannot award certificates
              which are recognised by any regulatory body such as the All India
              Council for Technical Education or University Grants Commission.
            </p>
            <p className="mt-2">
              It is the sole responsibility of the Customer enrolling into a
              Program to check the accuracy of, and evaluate the suitability and
              relevance of, the Program elected. The enrolment into a Program is
              non-transferable.
            </p>
            <p className="mt-2">
              You acknowledge that certain parts of the Platform may be enabled
              by third-party service providers, and you agree to abide by their
              terms and conditions to the extent such terms are applicable to
              you. Maangler shall not be responsible for any disruption of
              Maangler Services caused by any such third-party service provider.
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>Indemnification</SubHeading>
          <Content>
            <p>
              You shall defend, indemnify, and hold harmless Maangler, its
              affiliates, subsidiaries and each of its, and its affiliates’ and
              subsidiaries’ employees, contractors, directors, suppliers and
              representatives from all liabilities, losses, claims, and
              expenses, including reasonable attorneys’ fees, that arise from or
              relate to (i) your use or misuse of, or access to, the Platform
              and/or Maangler Services; (ii) your violation of the ToS or any
              applicable law; (iii) your violation of any rights of a third
              party in relation to the usage of the Platform. We reserve the
              right to assume the exclusive defence and control of any matter
              otherwise subject to indemnification by you, in which event you
              will assist and cooperate with us in connection therewith.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Limitation of Liability</SubHeading>
          <Content>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL MAANGLER
              (NOR ITS DIRECTORS, EMPLOYEES, AGENTS, SPONSORS, PARTNERS,
              SUPPLIERS, CONTENT PROVIDERS, LICENSORS OR RESELLERS,) BE LIABLE
              UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER
              LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE (I) FOR ANY
              LOST PROFITS, DATA LOSS, LOSS OF GOODWILL OR OPPORTUNITY, OR
              SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES
              OF ANY KIND WHATSOEVER; OR (II) FOR YOUR RELIANCE ON THE MAANGLER
              SERVICES; OR (III) FOR ANY DIRECT DAMAGES IN EXCESS (IN THE
              AGGREGATE) OF ONE THOUSAND RUPEES (INR 1,000/-); OR (IV) FOR ANY
              MATTER BEYOND ITS REASONABLE CONTROL, EVEN IF MAANGLER HAS BEEN
              ADVISED OF THE POSSIBILITY OF ANY OF THE AFOREMENTIONED DAMAGES.
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>Governing Law</SubHeading>
          <Content>
            <p>
              The ToS shall be governed by and construed in accordance with the
              laws of India without regard to the conflict of laws provisions
              thereof. All claims, differences and disputes arising under or in
              connection with or in relation hereto the Platform, the ToS or any
              transactions entered into on or through the Platform shall be
              subject to the exclusive jurisdiction of the courts at Bengaluru,
              India and you hereby accede to and accept the jurisdiction of such
              courts.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Payments</SubHeading>
          <Content>
            <p>
              Unless otherwise expressly agreed in writing by Maangler, full
              payment for enrolment and participation in a Program is required
              at the time of registration. To make payment for any Program or to
              purchase any services or products offered by Maangler through the
              Platform, you must have internet access and a current valid
              accepted payment method as indicated on the Platform (“
              <span className="font-semibold">Payment Method”</span>). Maangler
              does not store any of your credit card information or such other
              information restricted by the Reserve Bank of India (RBI) for
              processing payment and has partnered with payment gateways for the
              payment towards the services. By using a third-party payment
              provider, you agree to abide by the terms/policies of such
              third-party payment provider. You agree that in case Maangler’s
              third-party payment provider stores any such information, Maangler
              will not be responsible for such storage, and it will be solely at
              your discretion to allow the third party to store such information
              as per its terms/policies. Maangler shall in no way be liable for
              loss of such information or any loss incurred by you due to the
              misuse of such information and Maangler shall not be obligated to
              reimburse you for any such loss or to hold you harmless in any
              manner. You also agree to pay all applicable fees for the payments
              made through the Platform.
            </p>
            <p className="mt-2">
              Maangler does not support all payment methods, currencies, or
              locations for payment. All applicable taxes are calculated based
              on the billing information you provide to us at the time of
              enrolment/purchase. Your transactions on the Platform may be
              subject to foreign exchange fees or exchange rates.
            </p>
            <p className="mt-2">
              Maangler will issue a ‘bill of supply’ for the exempted services
              and a separate ‘tax invoice’ where students wish to avail of any
              other Programs.
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>Policy for NDNC</SubHeading>
          <Content>
            <p>
              By using the Website and/or registering yourself at Maangler.in
              you authorise us to contact you via email or phone call or sms and
              offer you our services, imparting product knowledge, offer
              promotional offers running on website & offers offered by the
              associated third parties, for which reasons, personally
              identifiable information may be collected. And irrespective of the
              fact if also you have registered yourself under DND or DNC or NCPR
              service, you still authorise us to give you a call from Maangler
              for the above mentioned purposes. This Privacy Policy covers
              Maangler's treatment of personally identifiable information that
              Maangler collects when you are on the Maangler's site, and when
              you use Maangler's services. This policy also covers Maangler's
              treatment of personally identifiable information that Maangler's
              business partner shares with Maangler. This policy does not apply
              to the certain Maangler's products or services, when you visit
              Maangler's pages, and when you enter promotions. Maangler may also
              receive personally identifiable information from our business
              partners. When you registered with Maangler, we ask for your first
              name, last name, contact no, email, Date of Birth and gender. Once
              you register with Maangler and sign in to our services, you are
              not anonymous to us. Also during registration, you may be
              requested to register your mobile phone and email id, pager, or
              other device to receive text messages, and other services to your
              wireless device. By registration you authorise us to send
              sms/email alerts to you for your login details and any other
              service requirements or some advertising messages/emails from us.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Copyright Infringement Notice</SubHeading>
          <Content>
            <p>
              If a User believes in good faith that his/her copyright has been
              infringed, the User may provide us with a written communication
              which contains:
            </p>
            <p className="mt-2">
              An electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright interest/work;
            </p>
            <p>
              A description of where the material that the User claims is
              infringing is located on the Platform, and any other details
              identifying the infringing material;
            </p>
            <p>
              The User’s email address, address and telephone number; A
              statement by the User affirming that the User has a good faith
              belief that the disputed use is not authorized by the copyright
              owner, his/her agent, or the law; and
            </p>
            <p>
              A statement by the User, made under penalty of perjury, that the
              information in the copyright notice is accurate and that the User
              is the copyright owner or authorized to act on behalf of the
              copyright owner. The Copyright infringement notice needs to be
              sent to the Company address.
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>Miscellaneous</SubHeading>
          <Content>
            <p>
              If any provision of the ToS is found to be unenforceable or
              invalid, that provision will be limited or eliminated to the
              minimum extent necessary so that the ToS will otherwise remain in
              full force and effect and enforceable.
            </p>
            <p>
              Any failure by Maangler to enforce any right or failure to act
              with respect to any breach by you under the ToS shall not be
              construed as, or constitute, a continuing waiver of such
              provision, or a waiver of any other breach of or failure to comply
              with any other provision of the ToS, unless any such waiver has
              been consented to by us in writing.
            </p>
            <p>
              These ToS is personal to you and is not assignable or transferable
              by you except with Maangler’s prior written consent. Maangler may
              assign, transfer or delegate any of its rights and obligations
              hereunder without your consent.
            </p>
            <p>
              No agency, partnership, joint venture, or employment relationship
              is created as a result of the ToS and neither party has any
              authority of any kind to bind the other in any respect in relation
              to any third party.
            </p>
            <p>
              All notices under the ToS will be in writing and will be deemed to
              have been duly given when received, if personally delivered or
              sent by certified or registered mail, return receipt requested;
              when receipt is electronically confirmed, if transmitted by
              facsimile or e-mail; or two days after it is sent, if sent for
              next day delivery by recognized overnight delivery service.
            </p>
            <p>
              Maangler shall not be liable for failure to perform, or the delay
              in performance of, any of its obligations if, and to the extent
              that, such failure or delay is caused by events substantially
              beyond its control, including but not limited to acts of God, acts
              of the public enemy or governmental body in its sovereign or
              contractual capacity, war, terrorism, floods, fire, strikes,
              pandemics, epidemics, civil unrest or riots, power outage, and/or
              unusually severe weather.
            </p>
            <p>
              By accepting the ToS through your use of the Platform, you certify
              and acknowledge that you are 18 years of age or older. If you are
              under the age of 18 or under the legal age in your jurisdiction to
              enter into a binding contract, you must use the Platform only
              under the supervision of a parent or legal guardian who agrees to
              be bound by the ToS. If you are a parent or legal guardian
              agreeing to this ToS for the benefit of an individual below 18
              year of age, please be advised that you are fully responsible for
              his/her use of the Platform, including all financial charges and
              legal liability that he/she may incur. By using the Platform, you
              represent and warrant that you have the right, authority, and
              capacity to enter into this ToS and to abide by all of the terms
              and conditions set forth herein.
            </p>
          </Content>
        </Container>
        <Container>
          <SubHeading>Grievances</SubHeading>
          <Content>
            <p>
              In the event you have any queries/complaints regarding usage of
              the Platform, including but not limited to reporting any violation
              of these Terms of Service, please contact our Grievance Officer at
              support@maangler.in. The complaints will be redressed in the
              manner provided under the Information Technology Act, 2000 and
              rules framed thereunder.
            </p>
          </Content>
        </Container>
        <Container type="blue">
          <SubHeading>Definitions</SubHeading>
          <Content>
            <p>
              The following defined terms having their respective meanings as
              provided below have been used in these ToS:
            </p>
            <p className="mt-2">
              “Customer” refers to any User who has paid and subscribed to any
              of the Programs, which may include execution of a subscription
              agreement (or any similar agreement executed between such User and
              Maangler) or compliance with certain additional terms by the User
              in this regard; “Platform” shall mean the websites maangler.in
              (collectively, the “Website”), and any other platform (including
              chat groups, Maangler hosted social media forums/pages, and
              webpages) which may allow you to access and/or use the Maangler
              Services via any other device/medium; “Maangler Content” means and
              includes all questionnaires, study/course material in any format,
              ideas, concepts, inventions, systems, platforms, software,
              interfaces, tools, utilities, templates, forms, techniques, design
              formats, methods, processes, algorithms, know-how, trade secrets,
              and other technologies and information acquired, created,
              developed or licensed independently by us, prior to or outside the
              scope of these ToS and any improvement, modification, extension or
              other derivative works thereof and all intellectual property
              rights thereto. Maangler Content excludes all User Data; “User
              Data” means all electronic data, information, or other content
              submitted or provided by a User to be stored, collected or
              processed by us in connection with such User's authorised use of
              the Maangler Services; “ You ” or “ User ” shall mean any user of
              the Platform.
            </p>
          </Content>
        </Container>
        <div>
          <p className="font-semibold text-center px-[6%] my-20">
            These Terms of Service constitute an 'electronic record' within the
            meaning of the applicable law (including the Information Technology
            Act, 2000) and is generated by a computer system and does not
            require any physical or digital signatures.
          </p>
        </div>
      </div>
    </div>
  );
};

const SubHeading = ({ children }) => (
  <p className="text-lg font-semibold mb-2">{children}</p>
);

const Content = ({ children }) => <div>{children}</div>;

const Container = ({ children, className, type = "white" }) => (
  <div
    className={`${className} px-[6%] xl:px-[3%] lg:px-[2%] 
     py-6 ${
      type === "white" ? "bg-white" : "bg-[#f8faff]"
    }`}
  >
    {children}
  </div>
);

export default TermsOfUse;
