import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  CardMedia,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import msft from "../../static/Icons/microsoft.png";
import meta from "../../static/Icons/meta.png";
import apple from "../../static/Icons/apple.png";
import amazon from "../../static/Icons/amazon.png";
import amazon2 from "../../static/Icons/amazon2.png";
import google from "../../static/Icons/google.png";
import netflix from "../../static/Icons/netflix.png";
import icon1 from "../../static/CampusAmbassador/applicationIcon1.png";
import icon2 from "../../static/CampusAmbassador/applicationIcon2.png";
import icon3 from "../../static/CampusAmbassador/applicationIcon3.png";
import icon4 from "../../static/CampusAmbassador/applicationIcon4.png";
import boy from "../../static/Icons/boy.png";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import Jainil from "../../static/Images/Jainil.jpg";
import Gaurav from "../../static/Images/Gaurav.jpg";
import Swaraj from "../../static/Images/Swaraj.jpg";
import Muskan from "../../static/Images/Muskan.jpg";
import Kunika from "../../static/Images/kunika.jpeg";
import Loganpriya from "../../static/Images/Logapriya.jpg";
import "./PythonInternship.css";
import ApplyNowModal from "../Courses/CommonApplyNowBtn";

const MentorsStrip = ({
  CourseDuration,
  courseName,
  borderImageColor,
  instrctorNameBgColor,
  ButtonColor,
  SuccessStoryBgColor,
  SuccessStoryBoxShadoColor,
  SuccessStoryTextColor,
}) => {
  const [show, setShow] = useState(false); // Correct usage of useState

  const cardData = [
    {
      title: "Freshers in any field",
      description: `Learn the ins and outs of ${courseName} programming to make an informed decision about pursuing a career in software development.`,
    },
    {
      title: "Working Professionals",
      description: `Understand your current skill set and develop the skills required to successfully transition into a role focused on ${courseName} programming.`,
    },
    {
      title: "Programming Enthusiasts",
      description: `Hone your ${courseName} programming skills and become even more valuable to your company.`,
    },
    {
      title: `Anyone curious about what ${courseName} programmers truly do`,
      description: `Curious about a career in ${courseName} programming? Learn about ${courseName} and its applications in this course.`,
    },
  ];

  return (
    <ResponsiveWrapper>
      <div className="mt-16 flex flex-col items-center justify-center mb:px-2">
        <p style={{ textAlign: "center", color: "#ECC578" }}>The Mentors</p>
        <p
          className="text-4xl font-semibold md:text-3xl"
          style={{ textAlign: "center" }}
        >
          Meet your program mentors
        </p>
        <Box
          sx={{ backgroundColor: "#000", padding: "50px 0", marginTop: "5px" }}
          className="testtt"
        >
          <Grid
            container
            spacing={12}
            justifyContent="center"
            sx={{
              marginLeft: { xs: "-20px", sm: "-100px" }, // Apply -20px margin on mobile (xs) and 0px on larger screens (sm)
            }}
          >
            {profiles1.map((profile, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#000", // Content background
                    color: "white",
                    borderRadius: "20px",
                    padding: "16px",
                    textAlign: "center",
                    border: "2px solid", // Define the thickness of the border
                    borderImage: borderImageColor,
                    width: "200px",
                    marginLeft: "-20px",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={profile.image}
                    alt={profile.name}
                    sx={{
                      borderRadius: "12px",
                      height: "100px",
                      width: "100px",
                      margin: "0 auto",
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6" sx={{ marginTop: "10px" }}>
                      {profile.name}
                    </Typography>
                    <p style={{ fontSize: "12px" }}> {profile.title}</p>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <img
                        src={profile.companyLogo}
                        alt={profile.companyName}
                        style={{ maxHeight: "18px", marginRight: "8px" }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "black",
            padding: "30px 0",
          }}
        >
          <Grid
            container
            spacing={12}
            justifyContent="center"
            sx={{
              marginLeft: { xs: "-20px", sm: "-100px" }, // Apply -20px margin on mobile (xs) and 0px on larger screens (sm)
            }}
          >
            {profiles2.map((profile, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    backgroundColor: "#000", // Content background
                    color: "white",
                    borderRadius: "20px",
                    padding: "16px",
                    textAlign: "center",
                    border: "2px solid", // Define the thickness of the border
                    borderImage: borderImageColor,
                    width: "200px",
                    marginLeft: "-20px",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={profile.image}
                    alt={profile.name}
                    sx={{
                      borderRadius: "12px",
                      height: "100px",
                      width: "100px",
                      margin: "0 auto",
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6" sx={{ marginTop: "10px" }}>
                      {profile.name}
                    </Typography>
                    <p style={{ fontSize: "12px" }}> {profile.title}</p>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <img
                        src={profile.companyLogo}
                        style={{
                          maxHeight: "18px",
                          marginRight: "8px",
                          color: "white",
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <hr
          style={{
            color: "white",
            width: "500px",
            marginBottom: "100px",
            marginTop: "60px",
          }}
        />

        {/*  ECC578this program for? */}
        <p style={{ textAlign: "center", color: "#f5af19" }}>Know-how</p>
        <p className="text-4xl font-semibold md:text-3xl">
          Who is this program for?
        </p>
        <Box
          sx={{
            backgroundColor: "black",
            padding: "50px 0",
            marginTop: "20px",
          }}
        >
          {/* Cards Section */}
          <Grid container spacing={2} justifyContent="center" direction="row">
            {cardData.map((card, index) => (
              <Grid item xs={12} sm={6} md={5} key={index}>
                <Card
                  sx={{
                    background: instrctorNameBgColor,
                    color: "white",
                    borderRadius: "16px",
                    padding: "16px",
                    textAlign: "left",
                    border: "1px solid #71613f",
                    width: { xs: "100%", sm: "500px" },
                    height: "180px",
                    backdropFilter: "blur(20px)",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {card.title}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: "10px" }}>
                      {card.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ textAlign: "center", marginTop: "50px" }}>
            <Button
              variant="contained"
              size="large"
              sx={{
                background: ButtonColor,
                color: "#000",
                padding: "18px 40px",
                borderRadius: "10px",
                width: "400px",
                fontWeight: "bold",
                textTransform: "none",
                display: { xs: "none", sm: "inline" },
              }}
              onClick={() => setShow(true)}
            >
              APPLY NOW
            </Button>
            <p className="applyButtonText">
              {" "}
              In the last 10 hours, 6 learners have onboarded with us
            </p>
          </Box>
        </Box>

        <hr
          style={{
            color: "white",
            width: "500px",
            marginBottom: "100px",
            marginTop: "60px",
          }}
        />

        {/*  Success Stories */}
        <p style={{ textAlign: "center", color: "#ECC578" }}>Success Stories</p>
        <p
          className="text-4xl font-semibold md:text-3xl"
          style={{ textAlign: "center" }}
        >
          Here's what our learners say!
        </p>
        <Box sx={{ backgroundColor: "black", padding: "50px 0" }}>
          <Grid container spacing={2} justifyContent="center" direction="row">
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: SuccessStoryBgColor,
                    boxShadow: SuccessStoryBoxShadoColor,
                    color: "white",
                    borderRadius: "16px",
                    padding: "16px",
                    textAlign: "left",
                    width: "100%", // Adjusted width for responsive design
                    height: "100%", // Let the card take up the space dynamically
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ fontStyle: "italic", color: "#C8AA70" }}
                    >
                      99
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        marginTop: "10px",
                        color: "white",
                        fontSize: "11px",
                        lineHeight: "1.5",
                      }}
                    >
                      {/* Dynamic Content Based on Index */}
                      {index === 0 && (
                        <>
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            This course has been designed in such a way that
                            even a beginner like me could easily follow.
                          </span>{" "}
                          It was very easy for us to learn and practice,
                          especially by doing the assignments and more like a
                          practice session for us.{" "}
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            The course helped me to land a job in a startup.
                          </span>
                        </>
                      )}
                      {index === 1 && (
                        <>
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            My friend recommended this as he got a job after
                            completing certification from here.
                          </span>{" "}
                          You guys give a very good explanation of each and
                          every topic;{" "}
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            it was actually a deep dive program, which was very
                            helpful for me and added a lot of opportunities in
                            my career.
                          </span>
                        </>
                      )}
                      {index === 2 && (
                        <>
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            Thanks Maangler for such an opportunity, and I am
                            really grateful to be a part of it.
                          </span>{" "}
                          A lot of thanks to the team who made this Hackathon
                          possible,{" "}
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            Surya Saxena and Niharika, for helping us throughout
                            the event.
                          </span>
                        </>
                      )}
                      {index === 3 && (
                        <>
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            The course not only provided me with a solid
                            foundation in {courseName} programming but also
                            equipped me with the skills and knowledge necessary
                            to excel in this field.
                          </span>{" "}
                          The teaching staff is very experienced and gave
                          industry-based case studies for a good understanding
                          of real-world problems. The curriculum was
                          comprehensive and well-structured.{" "}
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            I would highly recommend the course to anyone who
                            wants to develop a solid foundation in Python
                            programming.
                          </span>
                        </>
                      )}
                      {index === 4 && (
                        <>
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            The mentors made the SQL and Python journey look so
                            easy, and I was easily able to grasp it.
                          </span>{" "}
                          My interview experience at Amazon was really good, as
                          the questions the interviewer was asking were taught
                          during the class.{" "}
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            I cleared the IJP written test and interview at
                            Amazon and joined as a CS data analyst.
                          </span>
                        </>
                      )}
                      {index === 5 && (
                        <>
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            The Python programming course from Maangler helped
                            me learn the perspective of looking at problems with
                            business-driven solutions strategically through
                            coding skills.
                          </span>{" "}
                          Python, SQL, and Power BI were the major requirements,
                          and the tutor touched upon all the required content to
                          master Python programming.{" "}
                          <span
                            style={{
                              backgroundColor: SuccessStoryTextColor, // Highlight color
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}
                          >
                            The approach to impact the training for people with
                            no background in this aspect is good.
                          </span>
                        </>
                      )}
                    </Typography>
                    {/* User Info Section */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "rgba(255, 196, 141, 0.15)",
                          marginRight: "10px",
                        }}
                      >
                        <p style={{ color: "#CECFD2", opacity: "1" }}>
                          {testimonial.initials}
                        </p>
                      </Avatar>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", fontSize: "12px" }}
                        >
                          {testimonial.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#888", fontSize: "12px" }}
                        >
                          {testimonial.title}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <hr
          style={{
            color: "white",
            width: "500px",
            marginBottom: "100px",
            marginTop: "60px",
          }}
        />
      </div>
      <ApplyNowModal show={show} setShow={setShow} />
    </ResponsiveWrapper>
  );
};

const imgData = [meta, amazon, apple, netflix, google];

const Image = ({ src }) => (
  <img
    className="w-auto h-[35px]"
    src={src}
    alt=""
    style={{ margin: "auto" }}
  />
);

const profiles1 = [
  {
    name: "Logapriya",
    title: "Sr. Ds",
    companyLogo: amazon2,
    companyName: "Amazon",
    image: Loganpriya,
    link: "https://www.linkedin.com/in/logapriya-viswanathan-546626125/",
  },
  {
    name: "Kunika",
    title: "Software Engineer",
    companyLogo: msft,
    companyName: "Microsoft",
    image: Kunika,
    link: "https://www.linkedin.com/in/kunikamalhotra/",
  },
  {
    name: "Gaurav Jha",
    title: "Software Engineer",
    companyLogo: google,
    companyName: "Google",
    image: Gaurav,
    link: "https://www.linkedin.com/in/gaurav-jha-776039119/",
  },
];
const profiles2 = [
  {
    name: "Jainil",
    title: "Software Engineer",
    companyLogo: msft,
    companyName: "Microsoft",
    image: Jainil,
    link: "https://www.linkedin.com/in/jainilvachhani/",
  },
  {
    name: "Swaraj",
    title: "Software Engineer",
    companyLogo: msft,
    companyName: "Microsoft",
    image: Swaraj,
    link: "https://www.linkedin.com/in/swaraj-software-engineer/",
  },
  {
    name: "Muskan",
    title: "Software Engineer",
    companyLogo: msft,
    companyName: "Microsoft",
    image: Muskan,
    link: "https://www.linkedin.com/in/muskan-handa-8b087217a/",
  },
];

const testimonials = [
  {
    quote:
      "This course has been designed in such a way that even a beginner like me could easily follow. It was very easy for us to learn and practice, especially by doing the assignments and more like a practice session for us. The course helped me to land a job in a startup.",
    name: "V. Manoj Surya",
    title: "Computer Science Student",
    initials: "V",
  },
  {
    quote:
      "My friend recommended this as he got a job after completing certification from here. You guys give a very good explanation of each and every topic; it was actually a deep dive program, which was very helpful for me and added a lot of opportunities in my career.",
    name: "Rohan Agarwal",
    title:
      "Computer Science Student | Finance Enthusiast | CMA Finalist | Open to Work",
    initials: "R",
  },
  {
    quote:
      "Thanks Maangler for such an opportunity, and I am really grateful to be a part of it. A lot of thanks to the team who made this Hackathon possible, Surya Saxena and Niharika, for helping us throughout the event.",
    name: "Palak Singh",
    title: "B.Sc (Hons) Statistics Student | Mata Sundari College",
    initials: "P",
  },
  {
    quote:
      "The course not only provided me with a solid foundation in Python programming but also equipped me with the skills and knowledge necessary to excel in this field. The teaching staff is very experienced and gave industry-based case studies for a good understanding of real-world problems.",
    name: "Sandeep Tanpure",
    title: "Engineering Student",
    initials: "S",
  },
  {
    quote:
      "The mentors made the SQL and Python journey look so easy, and I was easily able to grasp it. My interview experience at Amazon was really good, as the questions the interviewer was asking were taught during the class.",
    name: "B. Sushanth",
    title: "Data Science Student",
    initials: "B",
  },
  {
    quote:
      "The Python programming course from Maangler helped me learn the perspective of looking at problems with business-driven solutions strategically through coding skills. Python, SQL, and Power BI were the major requirements, and the tutor touched upon all the required content to master Python programming.",
    name: "Adeba Sheikh",
    title: "Information Technology Student",
    initials: "A",
  },
];

export default MentorsStrip;
