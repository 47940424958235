import React from "react";
import AllProgram from "../../static/AllProgram.png";
import human from "../../static/Icons/human.png";
import books from "../../static/Icons/books.png";
import stars from "../../static/Icons/stars.png";
import { East } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Zoom } from "@mui/material";
import { NumberCounter } from "../NumberCounter.jsx";
import leftCoin from "../../static/Icons/leftCoin.png";
import rightCoin from "../../static/Icons/rightCoin.png";

const AllProgramsStrip = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${AllProgram})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="py-10 relative  flex items-center space-x-10 xl:space-x-6 lg:space-x-2 md:grid md:grid-cols-2 mx-auto justify-center md:gap-6 mb:px-3"
    >
      {/* <img src={leftCoin} alt="" className="h-[250px] absolute left-0 md:-z-10" /> */}
      <img
        src={leftCoin}
        alt=""
        className="h-[250px] absolute left-0 md:-z-10 mb:hidden"
      />
      <img
        src={rightCoin}
        alt=""
        className="h-[250px] absolute right-0 md:-z-10 mb:hidden"
      />

      {boxData.map((item, index) => (
        <Box
          key={index}
          leftText={item.leftText}
          rightText={item.rightText}
          icon={item.icon}
        />
      ))}
      <Zoom in={true} timeout={1000}>
        <div
          className="cursor-pointer z-10 text-white bg-black h-[200px] w-[150px] rounded-tr-3xl rounded-br-[120px] flex flex-col items-center  justify-center  hover:bg-gray-950 hover:shadow-2xl mb:w-full"
          style={{ margin: "auto" }}
        >
          <Link to={"/super-30"}>
            <div>
              <p>Explore all</p>
              <p className="mb-2">programs</p>
              <East />
            </div>
          </Link>
        </div>
      </Zoom>

      {/* <img
        src={rightCoin}
        alt=""
        className="h-[250px] absolute right-0 lg:-z-10"
      /> */}
    </div>
  );
};

const Box = ({ icon, leftText, rightText }) => (
  <Zoom in={true} timeout={1000}>
    <div
      className="bg-white z-10 cursor-pointer border rounded-3xl h-[200px] w-[200px] flex flex-col items-center py-3 xl:w-[180px] lg:w-[160px]  transition ease-in-out duration-500 hover:shadow-xl mb:w-full"
      style={{ margin: "auto" }}
    >
      <div className="grow flex items-center">
        <img src={icon} alt="" className="w-20" />
      </div>
      <div className="flex space-x-2 items-center">
        <div className="text-2xl font-extrabold mb:text-lg">{leftText}</div>
        <p className="text-gray-500 font-semibold">{rightText}</p>
      </div>
    </div>
  </Zoom>
);

const boxData = [
  {
    leftText: (
      <div className="flex items-center">
        <NumberCounter targetNumber={100} interval={100} />
        <p>+</p>
      </div>
    ),
    rightText: "Mentors",
    icon: human,
  },
  {
    leftText: <NumberCounter targetNumber={10} interval={200} />,
    // ,
    rightText: "Programs",
    icon: books,
  },
  { leftText: "4.3/5", rightText: "Rating", icon: stars },
];

export default AllProgramsStrip;
