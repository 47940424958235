import { AccessTime, Description, Forum } from "@mui/icons-material";
import React from "react";
import resumeBuilderImage from "../static/Random/ResumeBuilder.webp";

const AlToolPage = ({ setCurrentStep }) => {
  return (
    <div className="mt-16">
      <Section1 />
      <Section2 setCurrentStep={setCurrentStep} />
      <Section3 />
    </div>
  );
};

const Section1 = () => (
  <div className="layout">
    <p className="text-center text-5xl font-bold leading-[60px] text-gray-500 xl:text-4xl mb:text-3xl">
      Find Your Dream Job with <br className="mb:hidden" />
      <span className="text-primary">AI-Powered Tools</span>
    </p>
    <p className="text-center mt-10 mb:mt-4">
      Unlock the potential of artificial intelligence with our cutting-edge{" "}
      <br className="mb:hidden" />
      suite of AI tools designed to revolutionize your career
    </p>
    <div className="flex justify-center mt-12 space-x-20 mb-16 lg:space-x-10 sm:space-x-0 sm:flex-wrap sm:gap-4">
      <div className="flex flex-col items-center space-y-2">
        <Description
          className="text-white bg-[#fdc10f] p-2 rounded"
          sx={{ width: "40px", height: "40px" }}
        />
        <p className="text-gray-800 font-semibold text-center mb:text-sm">
          Job Application made <br className="mb:hidden" />
          simpler with AI
        </p>
      </div>
      <div className="flex flex-col items-center space-y-2">
        <AccessTime
          className="text-white bg-[#fdc10f] p-2 rounded"
          sx={{ width: "40px", height: "40px" }}
        />
        <p className="text-gray-800 font-semibold text-center mb:text-sm">
          Save 100’s of Hours with <br className="mb:hidden" />
          these AI tools
        </p>
      </div>
      <div className="flex flex-col items-center space-y-2">
        <Forum
          className="text-white bg-[#fdc10f] p-2 rounded"
          sx={{ width: "40px", height: "40px" }}
        />
        <p className="text-gray-800 font-semibold text-center mb:text-sm">
          Tailored Feedback to
          <br className="mb:hidden" />
          improve your application
        </p>
      </div>
    </div>
  </div>
);

const Section2 = ({ setCurrentStep }) => (
  <div className="bg-[#ecf1f4] mb-16 layout py-20 mb:py-10">
    <div className="bg-white max-w-[490px] rounded-[24px] shadow flex flex-col p-[32px] space-y-4 mx-auto mb:space-y-2 mb:p-[16px]">
      <p className="text-[32px] font-semibold text-gray-600 md:text-xl ">
        Resume Builder
      </p>
      <p className="mb:text-sm">
        Leverage the power of AI to customize your resume, highlight your
        achievements, and showcase your skills effectively. With Resume Builder,
        you can create eye-catching resumes that make a lasting impression.
      </p>
      <button
        className="bg-primary w-fit text-white px-6 py-1 rounded-3xl mb:text-sm"
        onClick={() => setCurrentStep(2)}
      >
        Create Now
      </button>
      <img src={resumeBuilderImage} alt="" />
    </div>
  </div>
);

const Section3 = () => (
  <div className="layout mb-20">
    <p className="text-center text-3xl font-semibold text-gray-600 lg:text-2xl md:text-xl">
      How AI tools make your Job Application <br className="mb:hidden" />{" "}
      process the <span className="text-primary">easiest it can be</span>
    </p>
    <div className="mt-10 grid grid-cols-2 gap-10 mx-auto w-fit mb:grid-cols-1 mb:gap-4">
      {data.map((item, index) => (
        <div key={index} className="flex items-center space-x-4 max-w-[600px]">
          <p className="text-5xl text-gray-600 font-semibold mb:text-3xl">
            {index + 1}
          </p>
          {item}
        </div>
      ))}
    </div>
  </div>
);

const data = [
  <p className="mb:text-sm">
    Find Jobs and Companies that align best with your level of expertise and
    interest from a leading job search page
  </p>,
  <p className="mb:text-sm">
    Use the <span className="text-primary">InterviewPro</span> tool and get
    interview ready by answering probable questions and learning which skills
    need work
  </p>,
  <p className="mb:text-sm">
    Develop and learn Skills using the{" "}
    <span className="text-primary">AI Tutor</span> and improve your resume
  </p>,
  <p className="mb:text-sm">
    Create a Resume using the{" "}
    <span className="text-primary">Resume Builder</span> to apply for your Job
    Role
  </p>,
  <p className="mb:text-sm">
    Analyse your Resume using the{" "}
    <span className="text-primary">Resume Checker</span> to see how to perfect
    your resume for your Job Role
  </p>,
  <p className="mb:text-sm">Apply for the job</p>,
];

export default AlToolPage;
