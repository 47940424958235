import React from "react";
import icon1 from "../../static/CampusAmbassador/applicationIcon1.png";
import icon2 from "../../static/CampusAmbassador/applicationIcon2.png";
import icon3 from "../../static/CampusAmbassador/applicationIcon3.png";
import icon4 from "../../static/CampusAmbassador/applicationIcon4.png";
import rightVerticalLines from "../../static/Icons/rightVerticalLines.png";

const ApplicationAmabassadorSection = () => {
  return (
    <div className="mt-20 px-6">
      <p className="text-4xl font-semibold mb:text-3xl text-center">
        Application Process
      </p>
      <div className="flex justify-center space-x-10 mt-10 lg:grid lg:grid-cols-2 lg:space-x-0 lg:gap-6 lg:justify-items-center sm:grid-cols-1">
        {data.map((item, index) => (
          <div
            key={index}
            className="w-[300px] bg-white border rounded-2xl min-h-[220px] space-y-2 px-6 py-6 flex flex-col transition ease-in-out duration-500 hover:scale-105"
          >
            <div className="grow">
              <img src={item.icon} alt="" />
            </div>
            <p className="text-lg font-semibold">{item.title}</p>
            <p>{item.des}</p>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center mx-auto mt-8">
        <button className=" border-2 px-16 py-2 border-primary text-primary rounded-3xl  mb:px-10 transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white">
          Become an Ambassador
        </button>
        <img src={rightVerticalLines} alt="" className="w-[50px] mb-10" />
      </div>
    </div>
  );
};

const data = [
  {
    icon: icon1,
    title: "Fill your Application",
    des: "Apply quickly in 2 minutes",
  },
  {
    icon: icon2,
    title: "Telephonic interview",
    des: "Connect with Maangler team",
  },
  { icon: icon3, title: "Meet your team", des: "Get yourself onboarded" },
  {
    icon: icon4,
    title: "Start your journey",
    des: "Way to learning and rewards",
  },
];

export default ApplicationAmabassadorSection;
