import React from "react";
import Background from "../static/Hiring/HiringBg.png";
import HeroSection from "../components/Hiring/HeroSection";
import MentorsStrip from "../components/Hiring/MentorsStrip";
import WhyHireSection from "../components/CollegeReachOut/WhyHireSection";
import TechTeamSection from "../components/CollegeReachOut/TechTeamSection";
import SigningUpSection from "../components/Hiring/SigningUpSection";

const HiringPage = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="text-white pb-20 overflow-hidden"
    >
      <HeroSection />
      <MentorsStrip />
      <WhyHireSection />
      <TechTeamSection />
      <SigningUpSection />
    </div>
  );
};

export default HiringPage;
