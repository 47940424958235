import React from "react";


const TestimonialCard = (props) => {
  const testCard= props;
  return (
    <div className="mx-auto mb-6 w-full rounded-lg border border-gray-200 bg-white p-5 font-light text-gray-800 font-sans">
      <div className="mb-4 flex w-full items-center">
        <div className="h-10 w-10 overflow-hidden rounded-full border border-gray-200 bg-gray-50">
          <img src={testCard.profile} alt="" />
        </div>
        <div className="flex-grow pl-3">
          <h6 className="text-sm font-bold uppercase text-gray-600">
            {testCard.name}
          </h6>
        </div>
      </div>
      <div className="w-full">
        <p className="text-sm leading-tight font-semibold">
          <span className="mr-1 text-lg font-bold italic leading-none text-gray-400">
            "
          </span>
          {testCard.review}
          <span className="ml-1 text-lg font-bold italic leading-none text-gray-400">
            "
          </span>
        </p>
      </div>
    </div>
  );
};

export default TestimonialCard;
