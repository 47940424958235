import React from "react";
import Select from "react-select";

const CustomSelect = ({
  options,
  name,
  setFieldValue,
  defaultValue,
  error,
  touched,
  placeholder = "Select",
  isDisabled = false,
}) => {
  return (
    <>
      <Select
        defaultValue={defaultValue?.label &&  defaultValue}
        options={options}
        styles={customStyles}
        menuPlacement="auto"
        onChange={(e) => {
          setFieldValue(name, e.value);
        }}
        name={name}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
      {error && touched && (
        <p className="text-xs ml-4 text-red-500">* {error}</p>
      )}
    </>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "24px",
    backgroundColor: "rgb(229, 231, 235)",
    border: state.isFocused ? `1px solid #32A3C5` : "none",
    fontSize:'14px',
    "&:hover": {
      border: "none",
    },
  }),
};

export default CustomSelect;
