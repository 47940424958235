import React from "react";
import Info1 from "../../static/BecomeMentor/Info1.png";
import Info2 from "../../static/BecomeMentor/Info2.png";
import Info3 from "../../static/BecomeMentor/Info3.png";
import quote from "../../mocks/quote.png";
import image from "../../mocks/image.png";

const MentorInfo = () => {
  return (
    <div className="layout py-20">
      <p className="text-2xl font-semibold text-center">
        By mentoring at maangler you turn your passion to <br /> friendship,
        networking and a lot more 🤝
      </p>
      <div className="space-y-10 mt-10 ">
        {MentorsInfoDetails.map(
          ({
            id,
            origin,
            sectionImage,
            sectionDescription,
            sectionHeading,
            cardDescription,
            cardExperience,
            cardName,
          }) => (
            <div
              key={id}
              className={`flex items-center justify-center md:flex-col md:space-y-6 ${
                origin === "left" ? "flex-row" : "flex-row-reverse"
              }`}
            >
              <div className="w-[600px] mb:max-w-[400px]">
                <img
                  alt=""
                  src={sectionImage}
                  className="w-[450px] h-[300px] object-contain mx-auto lg:w-[380px] mb:max-w-[340px]"
                />
              </div>
              <div className="w-[450px] space-y-4 mb:w-[340px] ">
                <p className="text-lg font-semibold">{sectionHeading}</p>
                <p className="text-sm text-gray-500">{sectionDescription}</p>
                <div className="border py-3 px-5 bg-white rounded-xl shadow-lg space-y-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <img alt="" src={image} className="w-10" />
                      <div>
                        <p className="font-semibold">{cardName}</p>
                        <p className="text-xs text-gray-500">
                          {cardExperience}
                        </p>
                      </div>
                    </div>
                    <img alt="" src={quote} />
                  </div>
                  <p className="text-sm text-gray-500">{cardDescription}</p>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <a href="https://calendly.com/ritik-gupta/30min">
        <button className="border-2 border-primary text-primary font-semibold py-2 px-10 rounded-full block mx-auto mt-10">
          Apply Now
        </button>
      </a>
    </div>
  );
};

const MentorsInfoDetails = [
  {
    id: 1,
    origin: "left",
    sectionImage: Info1,
    sectionHeading:
      "Get connected to the biggest tech community in India, conduct events and share your expertise!",
    sectionDescription:
      "As a Maangler mentor, you would be able to connect and collaborate with other Maangler mentors on professional/personal projects. You would also get a podium to conduct your lectures with students on topics that you believe are important for the upcoming techies.",
    cardName: "John Smith, SDE @ Amazon",
    cardExperience: "Mentoring at Maangler for last 1 year",
    cardDescription:
      "I have tried several online resources to learn Python, but the live classes offered by Maangler were by far the most effective. The instructors were engaging and the hands-on approach made it much easier for me to understand the concepts. I also appreciated the opportunity to connect with other students and learn from each other.",
  },
  {
    id: 2,
    origin: "right",
    sectionImage: Info2,
    sectionHeading:
      "Maangler Masterclasses & their recordings to assist in your growth",
    sectionDescription:
      "You would have access to the Masterclasses we conduct at Maangler and their recordings. You would also have access to talent from Maangler to hire for your growing organizations and teams.",
    cardName: "John Smith, SDE @ Amazon",
    cardExperience: "Mentoring at Maangler for last 1 year",
    cardDescription:
      "I have tried several online resources to learn Python, but the live classes offered by maangler were by far the most effective. The instructors were engaging and the hands-on approach made it much easier for me to understand the concepts. I also appreciated the opportunity to connect with other students and learn from each other.",
  },
  {
    id: 3,
    origin: "left",
    sectionImage: Info3,
    sectionHeading:
      "Conduct Community classes to assist all mentees across Maangler & build your brand",
    sectionDescription:
      "You would have access to Maangler’s training program. A constant stream of content and newsletter would help you be a better mentor and team lead at work as well at Maangler. Not just that, you can also make your Mentor profile public with all your achievements that can be shared with peers, or social platforms.",
    cardName: "John Smith, SDE @ Amazon",
    cardExperience: "Mentoring at Maangler for last 1 year",
    cardDescription:
      "I have tried several online resources to learn Python, but the live classes offered by maangler were by far the most effective. The instructors were engaging and the hands-on approach made it much easier for me to understand the concepts. I also appreciated the opportunity to connect with other students and learn from each other.",
  },
];

//image
//sectionheadiing
//sectionDes
//cardName
//cardDesig
//cardImage
//cardDes

export default MentorInfo;
