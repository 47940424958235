import moment from "moment-timezone";
export function getTimezonesWithOffsets() {
  const timeZones = moment.tz.names(); // Get all time zone names

  const timeZonesWithOffsets = timeZones.map((zone) => {
    const offset = moment.tz(zone).format("Z"); // Get the offset for each time zone
    return {
      label: `${zone} (UTC ${offset})`,
      value: `${zone} (UTC ${offset})`,
    };
  });

  return timeZonesWithOffsets;
}
const allTimeZones = getTimezonesWithOffsets();
