import React from "react";
import whatIcon1 from "../../static/CampusAmbassador/whatIcon1.png";
import whatIcon2 from "../../static/CampusAmbassador/whatIcon2.png";
import whatIcon3 from "../../static/CampusAmbassador/whatIcon3.png";
import whatIcon4 from "../../static/CampusAmbassador/whatIcon4.png";
import rightVerticalLines from "../../static/Icons/rightVerticalLines.png";
import clsx from "clsx";
import plane from "../../static/Images/plane.png";

const WhatAmbassadorSection = () => {
  return (
    <div className="mt-20 px-4 relative">
      <p className="text-center text-4xl font-semibold mb:text-3xl">
        What will you be doing?
      </p>
      <div className="grid grid-cols-2 justify-items-center gap-10 mt-8 mb:grid-cols-1 xl:gap-6">
        {cardData.map((item, index) => (
          <Card key={index} des={item.des} icon={item.icon} index={index} />
        ))}
      </div>
      <div className="flex items-center justify-center mx-auto mt-8 relative">
        <button
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          className="border-2 px-16 py-2 border-primary text-primary rounded-3xl  mb:px-10 transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white"
        >
          Become an Ambassador
        </button>
        <img src={rightVerticalLines} alt="" className="w-[50px] mb-10" />
      </div>
      <img
        src={plane}
        alt=""
        className="absolute top-10 left-[5%] sm:hidden"
        style={{ zIndex: 10 }}
      />
    </div>
  );
};

const Card = ({ icon, des, index }) => {
  return (
    <div
      className={clsx(
        "border rounded-2xl max-w-[324px] h-[233px] z-20  px-7 py-6 flex-col flex bg-white transition ease-in-out duration-500 hover:scale-105 sm:text-center",
        index % 2 === 0 ? "ml-auto mb:mx-auto" : "mr-auto mb:mx-auto"
      )}
    >
      <div className="grow">
        <img
          src={icon}
          alt=""
          className="w-[100px] h-[90px] object-contain sm:mx-auto"
        />
      </div>
      <p className="font-semibold">{des}</p>
    </div>
  );
};

const cardData = [
  {
    icon: whatIcon1,
    des: "Connect your friends to the most fascinating internships & jobs",
  },
  {
    icon: whatIcon2,
    des: "Be the face of Maangler at your Campus and enhance your networking skills",
  },
  {
    icon: whatIcon3,
    des: "Assist us in reaching out to your College’s Communities & Clubs",
  },
  {
    icon: whatIcon4,
    des: "Influence your peers on social media and promote community learning",
  },
];

export default WhatAmbassadorSection;
