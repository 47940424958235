export const STATUS_CODES = {
  PENDING: "loading",
  FULFILLED: "success",
  REJECTED: "error",
};

export const REGISTRATION_STEP = {
  STEP_OTP: "otp",
  STEP_REGISTERED_SUCCESSFULLY: "registered_successfully",
};

export const BASE_URL = "https://maangler-backend-production.up.railway.app";
