import React from "react";
import CustomInput from "../CustomInput";
import CustomStartEndDateRange from "../CustomStartEndDateRange";
import { useFormik } from "formik";
import * as Yup from "yup";

const ExperiencesSection = ({
  initialValuesForExperience,
  setShowExperienceSection,
  setInitialValuesForExperience,
  setJobProfileData,
  jobProfileData,
  jobExperienceCardType
}) => {
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValuesForExperience,
      validationSchema: Yup.object().shape({
        company: Yup.string().required("Field is required"),
        jobTitle: Yup.string().required("Field is required"),
        startYear: Yup.string().required("Field is required"),
        startMonth: Yup.string().required("Field is required"),
        workingHere: Yup.boolean(),
        endYear: Yup.string().when('workingHere', {
          is: false,
          then: (schema) => schema.required("Field is required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        endMonth: Yup.string().when('workingHere', {
          is: false,
          then: (schema) => schema.required("Field is required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        annualSalary: Yup.string().required("Field is required"),
      }),
      onSubmit: (values) => {
        const jobExperienceId = initialValuesForExperience.jobExperienceId;
        const isIdPresent = jobProfileData.experience.some(
          (item) => item?.jobExperienceId === jobExperienceId
        );
        if (!isIdPresent) {
          setJobProfileData((prev) => ({
            ...prev,
            experience: [...prev.experience, values],
          }));
        } else {
          setJobProfileData((prev) => ({
            ...prev,
            experience: prev.experience.map((item) => {
              if (item.jobExperienceId === jobExperienceId) {
                return values;
              } else {
                return item;
              }
            }),
          }));
        }

        closeHandler();
      },
    });

  const closeHandler = () => {
    setShowExperienceSection(false);
    setInitialValuesForExperience(null);
  };
  return (
    <div className="border bg-white px-2 py-3 rounded-xl shadow-md relative mt-2">
      <button
        className="ml-auto border block text-xs py-[1px] px-[6px] rounded-full border-primary text-primary bg-white font-semibold absolute right-2 top-2"
        onClick={closeHandler}
      >
        x
      </button>
      <div className="space-y-3">
        <div>
          <p className="ml-1 mb-1 font-semibold">
            Company <span className="text-red-500">*</span>
          </p>
          <CustomInput
            value={values["company"]}
            error={errors["company"]}
            touched={touched["company"]}
            name={"company"}
            placeholder={"Enter the name of company"}
            onChange={handleChange}
          />
        </div>
        <div>
          <p className="ml-1 mb-1 font-semibold">
            Job Title/Role <span className="text-red-500">*</span>
          </p>
          <CustomInput
            value={values["jobTitle"]}
            error={errors["jobTitle"]}
            touched={touched["jobTitle"]}
            name={"jobTitle"}
            placeholder={"Enter the Job Title/Role"}
            onChange={handleChange}
          />
        </div>
        <CustomStartEndDateRange
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
        <div>
          <p className="ml-1 mb-1 font-semibold">
            Annual salary <span className="text-red-500">*</span>
          </p>
          <CustomInput
            value={values["annualSalary"]}
            error={errors["annualSalary"]}
            touched={touched["annualSalary"]}
            name={"annualSalary"}
            placeholder={"Enter Annual salary in INR"}
            onChange={handleChange}
          />
        </div>
      </div>
      <button
        type="submit"
        className="bg-primary w-full mt-4 py-2 text-white font-semibold rounded-3xl"
        onClick={handleSubmit}
      >
        Save
      </button>
    </div>
  );
};

export default ExperiencesSection;
