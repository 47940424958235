import React from "react";
import HeroSection from "../components/CollegeReachOut/HeroSection";
import AllProgramsStrip from "../components/HomePage/AllProgramsStrip";
import ProgramsWeOffer from "../components/Super30/ProgramsWeOffer";
import MentorsSections from "../components/HomePage/MentorsSections";
import Super30 from "../components/HomePage/Super30";
import SolutionSection from "../components/CollegeReachOut/SolutionSection";
import CallbackSection from "../components/CollegeReachOut/CallbackSection";

const CollegeReachOutPage = () => {
  return (
    <div className="mb-20">
      <HeroSection />
      <AllProgramsStrip />
      <SolutionSection />
      <Super30 />
      <ProgramsWeOffer />
      <MentorsSections />
      <CallbackSection />
    </div>
  );
};

export default CollegeReachOutPage;
