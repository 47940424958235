import React from "react";
import CustomInput from "../CustomInput";

const ChangeEmail = () => {
  return (
    <div className="space-y-3">
      <div>
        <p className="ml-1 mb-1 font-semibold">Current Email</p>
        <CustomInput />
      </div>
      <div>
        <p className="ml-1 mb-1 font-semibold">Change Email</p>
        <CustomInput />
      </div>
    </div>
  );
};

export default ChangeEmail;
