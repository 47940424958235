import React, { useState } from "react";
import heronamebg from "../../static/Images/heronamebg.png";
import people from "../../static/Icons/people.png";
import threeLines from "../../static/Icons/threeLines.png";
import heroCardBg from "../../static/Images/heroCardBg.png";
import heroBg from "../../static/Images/heroBg.png";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import { Link } from "react-router-dom";
import BookCard from "./BookCard";
import { TypeAnimation } from "react-type-animation";
import Roshan from "../../static/Courses/Roshan.jpg";
import Alka from "../../static/Courses/Alka.jpg";
import Vishal from "../../static/Courses/Vishal.jpg";
import Soumya from "../../static/Courses/Somya.jpg";
import Priti from "../../static/Courses/Priti.jpg";
import * as Yup from "yup";
import Star from "../../static/Star.png";
import {
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { Cancel } from "@mui/icons-material";

const HeroSection = () => {
  const [show, setShow] = useState(false);
  return (
    <ResponsiveWrapper>
      <div className="flex justify-between py-20 items-center relative bg-[#fcfcfc] sm:flex-col sm:space-y-4 overflow-hidden">
        <div className="space-y-10 mb-6 sm:flex sm:flex-col sm:items-center">
          <p className="text-4xl font-bold lg:text-3xl mb:text-[21px]  sm:text-center mb:h-[43px]">
            Upskilling Made{" "}
            {/* <span
              className="px-2"
              style={{
                backgroundImage: `url(${heronamebg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            > */}
            <span className="text-4xl text-primary lg:text-3xl mb:text-2xl">
              {"<"}
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "PRACTICAL",
                  1000, // wait 1s before replacing "Mice" with "Hamsters"
                  "AFFORDABLE",
                  1000,
                  "EASY",
                  1000,
                ]}
                wrapper="span"
                speed={50}
                style={{ display: "inline-block" }}
                repeat={Infinity}
              />
              {">"}
            </span>
            {/* </span> */}
            <br className="" /> with Maangler
          </p>
          <div className="font-semibold mb:text-center  space-y-6">
            <p className="text-lg mb:text-base">
              AI-powered, industry-oriented courses in software development and{" "}
              <br className="mb:hidden" />
              data science to land the tech job of your dreams.
            </p>
            <p className="text-gray-500">
              Learn industry-relevant skills with top tech veterans
            </p>
          </div>
          {/* <Link to={"/super-30"} className=""> */}
          <button
            onClick={() => setShow(true)}
            className="bg-primary text-white font-semibold px-14 py-3 rounded-3xl hover:bg-[#0288b0] mt-10 hover:shadow-primary hover:shadow mb:mt-0"
          >
            Get Started
          </button>
          {/* </Link> */}
          <div className="flex sm:flex-col">
            <div className="flex relative space-x-8 w-[200px] sm:h-[60px]">
              {data.map(({ icon, name }) => (
                <div key={name} className="">
                  <img
                    src={icon}
                    alt=""
                    className="w-12 rounded-full border-2 border-white absolute"
                  />
                </div>
              ))}
            </div>
            <div className="font-semibold">
              <div className="flex">
                {Array(5)
                  .fill("_")
                  .map(() => (
                    <img src={Star} alt="star" />
                  ))}
              </div>{" "}
              4.9 from 1000+ reviews
            </div>
          </div>
        </div>

        <div className="z-10 relative">
          <BookCard people={people} threeLines={threeLines} type="noCross" />
        </div>

        <div className="absolute right-[-4%] top-20 sm:hidden xl:right-[-2%] md:hidden">
          <img src={heroCardBg} alt="" />
        </div>
        <div className="absolute left-[40%]">
          <img src={heroBg} alt="" />
        </div>
      </div>
      <ApplyNowForm show={show} setShow={setShow} />
    </ResponsiveWrapper>
  );
};

// const ApplyNowForm = () => {
//   return (
//     <div className="h-screen w-screen bg-white z-[999999999999] bg-opacity-95 left-0 overflow-x-hidden fixed top-0 right-0 ">
//       <div className="min-w-[1000px] bg-white rounded pb-6 mt-10 lg:min-w-[760px] sm:min-w-[570px] mb:min-w-[330px]" style={style}>
//         <div className="border p-6 shadow-2xl rounded w-full">
//           <p className="text-3xl text-primary font-semibold text-center mb-6">
//             Apply Now
//           </p>
//           <div className="space-y-10">
//             <div>
//               <FormControl>
//                 <FormLabel id="demo-radio-buttons-group-label">
//                   Your topic of interest?
//                 </FormLabel>
//                 <RadioGroup
//                   aria-labelledby="demo-radio-buttons-group-label"
//                   defaultValue=""
//                   name="radio-buttons-group"
//                 >
//                   <FormControlLabel
//                     value="sde"
//                     control={<Radio />}
//                     label="Software Development"
//                   />
//                   <FormControlLabel
//                     value="da"
//                     control={<Radio />}
//                     label="Data Analytics / Data Science & ML"
//                   />
//                 </RadioGroup>
//               </FormControl>
//             </div>
//             <div>
//               <FormControl>
//                 <FormLabel id="demo-radio-buttons-group-label">
//                   Are you a College Student, Working Professional or Graduated
//                   but not working? *
//                 </FormLabel>
//                 <RadioGroup
//                   aria-labelledby="demo-radio-buttons-group-label"
//                   defaultValue=""
//                   name="radio-buttons-group"
//                 >
//                   <FormControlLabel
//                     value="college"
//                     control={<Radio />}
//                     label="College Student"
//                   />
//                   <FormControlLabel
//                     value="working"
//                     control={<Radio />}
//                     label="Working Professional"
//                   />
//                   <FormControlLabel
//                     value="graduated"
//                     control={<Radio />}
//                     label="Graduated but not working"
//                   />
//                 </RadioGroup>
//               </FormControl>
//             </div>
//             <div className="grid grid-cols-2 gap-6 mt-4 sm:grid-cols-1">
//               {formData.map((item) => (
//                 <TextField
//                   label={item.label}
//                   variant="outlined"
//                   required={true}
//                 />
//               ))}
//             </div>
//           </div>
//           <button className="mt-6 bg-primary text-white w-[80%] mx-auto block py-2 rounded">
//             Submit
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
const ApplyNowForm = ({ show, setShow }) => {
  const [interest, setInterest] = useState("");
  const [designation, setDesignation] = useState("");
  const phoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        phone: "",
        clgName: "",
        exp: "",
        companyName: "",
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phone: Yup.string()
          .matches(phoneRegex, "Invalid phone number")
          .required("Phone is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        clgName: Yup.string().required("College / Company Name is required"),
        exp: Yup.string().required("Year of graduation/Experience is required"),
        companyName: Yup.string().required("Dream Company name is required"),
      }),
      onSubmit: async (values) => {
        //submitHandler(values);
        const payload = {
          ...values,
          interest: interest,
          designation: designation,
        };
        try {
          await axios.post(
            "https://maangler-backend-production.up.railway.app/applyNow",
            payload
          );
          toast.success("Form submitted successfully");
        } catch (err) {
          toast.error(err);
        }
        resetForm();
      },
    });
  return (
    <Modal open={show} sx={{ zIndex: 9999999999 }} style={{ overflow: "auto" }}>
      <div className="p-10 mb:p-2 border-none outline-none  overflow-y-auto">
        <div className="border p-6 shadow-2xl rounded w-full bg-white max-w-[1000px] mx-auto overflow-auto">
          <div onClick={() => setShow(false)}>
            <Cancel
              sx={{ marginLeft: "auto", display: "block", cursor: "pointer" }}
            />
          </div>
          <p className="text-3xl text-primary font-semibold text-center mb-6">
            Apply Now
          </p>
          <div className="space-y-10">
            <div>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Your topic of interest?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  onChange={(e) => {
                    setInterest(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Software Development"
                    control={<Radio />}
                    label="Software Development"
                  />
                  <FormControlLabel
                    value="Data Analytics / Dat a Science & ML"
                    control={<Radio />}
                    label="Data Analytics / Dat a Science & ML"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Are you a College Student, Working Professional or Graduated
                  but not working? *
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  onChange={(e) => {
                    setDesignation(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="College Student"
                    control={<Radio />}
                    label="College Student"
                  />
                  <FormControlLabel
                    value="Working Professional"
                    control={<Radio />}
                    label="Working Professional"
                  />
                  <FormControlLabel
                    value="Graduated but not working"
                    control={<Radio />}
                    label="Graduated but not working"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="grid grid-cols-2 gap-6 mt-4 sm:grid-cols-1">
              {formData.map((item) => (
                <TextField
                  label={item.label}
                  variant="outlined"
                  required={true}
                  error={Boolean(errors[item.name]) && touched[item.name]}
                  helperText={
                    Boolean(errors[item.name]) && touched[item.name]
                      ? errors[item.name]
                      : ""
                  }
                  onChange={handleChange}
                  name={item.name}
                  value={values[item.name]}
                />
              ))}
            </div>
          </div>
          <button
            onClick={handleSubmit}
            className="mt-6 bg-primary text-white w-[80%] mx-auto block py-2 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

const formData = [
  { id: 1, name: "name", label: "Candidate Full Name" },
  { id: 2, name: "email", label: "Email id " },
  { id: 3, name: "phone", label: "Phone Number" },
  { id: 4, name: "clgName", label: "College / Company Name" },
  { id: 5, name: "exp", label: "Year of graduation/Experience" },
  { id: 6, name: "companyName", label: "Dream Company" },
];

const data = [
  { name: "Roshan Kumar", rating: "4.3", icon: Roshan },
  { name: "Alka kashyap", rating: "4.2", icon: Alka },
  { name: "Priti Singh", rating: "4.5", icon: Priti },
  { name: "Vishal Reddy", rating: "4.4", icon: Vishal },
  { name: "Soumya BU", rating: "4.1", icon: Soumya },
];

// {
//   "interest": "string",
//   "designation": "string",
//   "name": "string",
//   "email": "user@example.com",
//   "phone": 0,
//   "organization": "string",
//   "graduation": "string",
//   "experience": "string",
//   "company": "string"
// }

export default HeroSection;
