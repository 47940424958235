import React from "react";
import AllProgram from "../../static/Images/AllProgram.png";
import { East } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Zoom } from "@mui/material";
import store1 from "../../static/CampusAmbassador/store1.png";
import store2 from "../../static/CampusAmbassador/store2.png";
import store3 from "../../static/CampusAmbassador/store3.png";
import leftCoin from "../../static/Icons/leftCoin.png";
import rightCoin from "../../static/Icons/rightCoin.png";

const StoreStrip = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${AllProgram})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="py-10  relative"
    >
      <img
        src={leftCoin}
        alt=""
        className="h-[250px] absolute left-0 md:-z-10 mb:hidden"
      />
      <img
        src={rightCoin}
        alt=""
        className="h-[250px] absolute right-0 md:-z-10 mb:hidden"
      />
      <p className="text-center text-3xl font-bold mb-7">
        What is in store for you?
      </p>
      <div className="flex items-center space-x-10 xl:space-x-6 lg:space-x-2 md:grid md:grid-cols-2 mx-auto justify-center md:gap-6">
        {boxData.map((item, index) => (
          <Box
            key={index}
            leftText={item.leftText}
            rightText={item.rightText}
            icon={item.icon}
          />
        ))}
        <Zoom in={true} timeout={1000}>
          <div
            className="cursor-pointer z-10 text-white bg-black h-[200px] w-[150px] rounded-tr-3xl rounded-br-[120px] flex flex-col items-center  justify-center  mb:w-[130px] hover:bg-gray-950 hover:shadow-2xl "
            style={{ margin: "auto" }}
          >
            <div
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <p>Become an</p>
              <p className="mb-2">Ambassador</p>
              <East />
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

const Box = ({ icon, leftText }) => (
  <Zoom in={true} timeout={1000}>
    <div
      className="bg-white cursor-pointer z-10 border rounded-3xl h-[200px] w-[200px] flex flex-col items-center py-3 xl:w-[180px] lg:w-[160px] mb:w-[130px] animate-hover-scale hover:shadow-xl"
      style={{ margin: "auto" }}
    >
      <div className="grow flex items-center">
        <img src={icon} alt="" className="w-20" />
      </div>
      <div className="flex space-x-2 items-center">
        <p className=" font-semibold text-center">{leftText}</p>
      </div>
    </div>
  </Zoom>
);

const boxData = [
  {
    leftText: (
      <p>
        Certificate of <br /> Completion
      </p>
    ),
    icon: store1,
  },
  {
    leftText: (
      <p>
        Wildcard entry to <br /> Fellowships
      </p>
    ),
    icon: store2,
  },
  {
    leftText: (
      <p>
        Internships at Top <br /> Companies
      </p>
    ),
    icon: store3,
  },
];

export default StoreStrip;
